

export const ENDPOINTS_CHARACTER = {
    GET_ALL: '/characters',
    POST: '/characters',
    PUT: (id: number) => `/characters/${id}`,
    DELETE: (id: number) => `/characters/${id}`,
    UPLOAD_IMAGE: (id: number, idstate: number) => `/characters/${id}/state/${idstate}/upload-image`,
};

export const ENDPOINTS_OBSTACLE = {
    GET_ALL: '/obstacles',
    POST: '/obstacles',
    PUT: (id: number) => `/obstacles/${id}`,
    DELETE: (id: number) => `/obstacles/${id}`,
    UPLOAD_IMAGE: (id: number) => `/obstacles/${id}/upload-image`,
};

export const ENDPOINTS_SCENE = {
    GET_ALL: '/scenes',
    POST: '/scenes',
    PUT: (id: number) => `/scenes/${id}`,
    DELETE: (id: number) => `/scenes/${id}`,
    UPLOAD_IMAGE: (id: number) => `/scenes/${id}/upload-image`,
};

export const ENDPOINTS_TARGET = {
    GET_ALL: '/targets',
    POST: '/targets',
    PUT: (id: number) => `/targets/${id}`,
    DELETE: (id: number) => `/targets/${id}`,
    UPLOAD_IMAGE: (id: number, stateId: number) => `/targets/${id}/state/${stateId}/upload-image`,
};