import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import MovableImage from '../../../../components/imageManipulation/MovableImage';
import dog from '../../../../assets/images/puzzle/dog.jpg';
import { useTranslation } from 'react-i18next';

interface DnDLevelTwoProps {
    checkResult: (isValid: boolean) => void; // Prop pour informer le parent que l'exercice est complété
}

const DnDLevelTwo: React.FC<DnDLevelTwoProps> = ({ checkResult }) => {
    const { t } = useTranslation();
    const [isOnTarget, setIsOnTarget] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null); // Référence à la zone cible 

    const handleMove = () => {
    };

    const onTarget = (itIs: boolean) => {
        setIsOnTarget(itIs);
        if (itIs) {
            checkResult(itIs); // Exercice validé
        } else {
            checkResult(itIs); // Désactiver la validation
        }
    }

    useEffect(() => {
        if (isOnTarget) {
            message.success(t("message.bravo"));
        }
        // eslint-disable-next-line
    }, [isOnTarget]);

    return (

        <Row justify="center">
            <Col span={12}>
                <div
                    ref={targetRef}
                    style={{
                        position: 'absolute',
                        top: '150px',
                        right: '50px',
                        width: '180px',
                        height: '180px',
                        border: '2px dashed green',
                        backgroundImage: `url("${dog}")`,
                        opacity: 0.4,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                >
                    Zone Cible
                </div>

                <MovableImage
                    onMove={handleMove}
                    targetRef={targetRef}
                    onTarget={onTarget}
                    src={dog}
                    initialX={50}
                    initialY={100}
                />

            </Col>
        </Row>
    );
};

export default DnDLevelTwo;
