import { TTarget } from "../../blocks/core/type/target";
import { CODIFICATION_TARGET_STATE, CODIFICATION_TYPE } from "../../constant/codification";
import { TLessonTarget } from "../../models/courses/lesson";
import { getImagePath } from "../format/valueFormat";

export const TLessonTargetTOTTarget = (
    lessonTarget: TLessonTarget | undefined,
    getCodificationIdByCodes: (codeType: string, code: string) => number
): TTarget => {
    if (!lessonTarget) {
        return {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            state: 'idle',
            order: 0,
            quantity: 0,
            showQuantity: false,
            images: {
                idle: '',
                reached: '',
            },
            behavior: {
                reached: {
                    disapear: true,
                },
            },
            visible: true,
            rotate: 0,
        };
    }

    return {
        x: lessonTarget.x,
        y: lessonTarget.y,
        width: lessonTarget.width,
        height: lessonTarget.height,
        state: 'idle',
        order: lessonTarget.target_order,
        quantity: lessonTarget.quantity,
        showQuantity: lessonTarget.quantity > 0,
        visible: lessonTarget.initial_visible,
        images: {
            idle: getImagePath(lessonTarget.target?.images?.find(
                (e) => e.state_id === getCodificationIdByCodes(CODIFICATION_TYPE.TARGET_STATE, CODIFICATION_TARGET_STATE.idle)
            )?.img || ""),
            reached: getImagePath(lessonTarget.target?.images?.find(
                (e) => e.state_id === getCodificationIdByCodes(CODIFICATION_TYPE.TARGET_STATE, CODIFICATION_TARGET_STATE.reached)
            )?.img || ""),
        },
        behavior: {
            reached: {
                disapear: true,
            },
        },
        rotate: lessonTarget.rotate,
    };
};

export const TLessonTargetsTOTTargets = (
    lessonTargets: TLessonTarget[] | undefined,
    getCodificationIdByCodes: (codeType: string, code: string) => number
): TTarget[] => {
    if (!lessonTargets) {
        return []
    }
    return lessonTargets.map((lessonTarget) =>
        TLessonTargetTOTTarget(lessonTarget, getCodificationIdByCodes)
    );
}
