// src/context/GameContext.tsx

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { GameState } from './GameState';
import { GameAction } from './GameAction';
import { gameReducer } from './gameReducer';
import { TCharacter } from '../../type/character';
import { TScene } from '../../type/scene';
import { TTarget } from '../../type/target';

interface GameContextProps {
    state: GameState;
    dispatch: React.Dispatch<GameAction>;
}

const GameContext = createContext<GameContextProps | undefined>(undefined);

export const useGameContext = () => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useGameContext must be used within a GameProvider');
    }
    return context;
};

interface GameProviderProps {
    initialCharacter: TCharacter;
    initialScene: TScene;
    initialTargets: TTarget[];
    children: React.ReactNode;
}

export const GameProvider: React.FC<GameProviderProps> = ({
    initialCharacter, initialScene, initialTargets, children
}) => {
    const [state, dispatch] = useReducer(gameReducer, {
        character: initialCharacter,
        scene: initialScene,
        remainingTargets: initialTargets,
        movementHistory: [{ x: initialCharacter.x, y: initialCharacter.y }],
        executionCancelled: false,

        initialCharacter: initialCharacter,
        initialScene: initialScene,
        initialTargets: initialTargets,
    });

    // Mise à jour du personnage
    useEffect(() => {
        dispatch({ type: 'UPDATE_CHARACTER', payload: initialCharacter });
        dispatch({ type: 'RESET_MOVEMENT_HISTORY', payload: initialCharacter });
    }, [initialCharacter]);

    // Mise à jour de la scène
    useEffect(() => {
        dispatch({ type: 'UPDATE_SCENE', payload: initialScene });
    }, [initialScene]);

    // Mise à jour des cibles
    useEffect(() => {
        dispatch({ type: 'UPDATE_TARGETS', payload: initialTargets });
        dispatch({ type: 'UPDATE_INITIAL_TARGETS', payload: initialTargets });
    }, [initialTargets]);

    return <GameContext.Provider value={{ state, dispatch }}>{children}</GameContext.Provider>;
};
