import React from 'react';
import { Button, Steps } from 'antd';
import AppLayout from './AppLayout';
import { TLesson } from '../../models/courses/lesson';
import { TLessonProgress } from '../../models/user/UserProgression';
import { LoadingOutlined } from '@ant-design/icons';

interface CourseLayoutProps {
    children: React.ReactNode;
    title: string;
    lessons: TLesson[];
    progression: TLessonProgress[];
    currentStep: number;
    previous?: () => void;
    next?: () => void;
    goToStep?: (stepIndex: number) => void;
    finishCourse?: () => void;
    isLoading?: boolean;
    isSaving?: boolean;
}

const { Step } = Steps;

const CourseLayout: React.FC<CourseLayoutProps> = ({
    title,
    children,
    lessons,
    progression,
    currentStep,
    previous,
    next,
    goToStep,
    finishCourse,
    isLoading = false,
    isSaving = false,
}) => {
    const footerContent = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {previous ? (
                <Button onClick={previous} type="default">
                    Précédent
                </Button>
            ) : (
                <div />
            )}

            <Steps
                current={currentStep}
                size="small"
                style={{ width: '60%' }}
                onChange={goToStep}
            >

                {(lessons || []).map((lesson, index) => {
                    // Vérifie si cette leçon a déjà été effectuée
                    const isCompleted = progression.some((prog) => prog.lesson_id === lesson.id);
                    // Trouve l'index de la prochaine leçon non effectuée
                    const nextLessonIndex = (lessons || []).findIndex((l) => !progression.some((prog) => prog.lesson_id === l.id));
                    // Détermine si le bouton de la leçon doit être grisé
                    const isDisabled = index > nextLessonIndex && !isCompleted;

                    return (
                        <Step
                            key={lesson.id}
                            title={lesson.name}
                            status={isCompleted ? 'finish' : 'wait'}
                            disabled={isDisabled}
                        />
                    );
                })}
            </Steps>

            {currentStep === lessons.length - 1 && finishCourse ? (
                <Button
                    type="primary"
                    onClick={finishCourse}

                    icon={isSaving ? <LoadingOutlined spin /> : undefined}
                >
                    Terminer
                </Button>
            ) : (
                <Button
                    type="primary"
                    onClick={next}
                    disabled={!next}
                    icon={isSaving ? <LoadingOutlined spin /> : undefined}
                >
                    Suivant
                </Button>
            )}
        </div>
    );

    return (
        <AppLayout title={title} footer={footerContent} loading={isLoading}>
            {children}
        </AppLayout>
    );
};

export default CourseLayout;
