import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEpic } from '../../../models/courses/course';
import { Button, Input, message, Select, Space, Table } from 'antd';
import { SaveOutlined, EyeOutlined, EditOutlined, CopyOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCourseApi } from '../../../api/courses/courses';
import { TLesson } from '../../../models/courses/lesson';
import type { ColumnsType } from 'antd/es/table';
import { TCodification } from '../../../models/referential/referential';
import { useReferential } from '../../../context/ReferentialContext';
import { CODIFICATION_LESSON_TYPE, CODIFICATION_ROLES, CODIFICATION_TYPE } from '../../../constant/codification';
import FloatButtons from '../../../components/button/FloatButtons';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';
import { useAuth } from '../../../context/AuthContext';

const LessonList: React.FC = () => {
    const { t } = useTranslation();
    const { isRoleExist } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { getLessonsByEpicId, updateLesson, createLesson, duplicateLesson, deleteLesson } = useCourseApi();
    const [lessons, setLessons] = useState<TLesson[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const { getCodificationsByCodeType } = useReferential();
    const lessonTypes: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.LESSON_TYPE);

    const epic: TEpic = location.state?.epic;

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingLesson, setEditingLesson] = useState<TLesson | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!epic) {
            message.error('No epic selected');
            navigate('/epic');
            return;
        }

        setIsLoading(true);
        getLessonsByEpicId(epic.id)
            .then((resp) => {
                setLessons(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [epic]);

    const edit = (lesson: TLesson) => {
        setEditingKey(lesson.id);
        setEditingLesson({ ...lesson });
    };

    const handleAddLesson = () => {
        const idRow = addingId - 1;
        const newLesson: TLesson = {
            id: idRow,
            name: '',
            statement: '',
            lesson_order: 0,
            exercise_component: '',
            lesson_type_id: 0,
            epic_id: epic.id,
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingLesson(newLesson);
        setLessons((prev) => [newLesson, ...(prev || [])]);
    };

    const save = (id: number) => {
        if (editingLesson) {
            (editingLesson.id > 0 ? updateLesson(editingLesson.id, editingLesson) : createLesson(editingLesson))
                .then((resp) => {
                    const lessonResult: TLesson = resp.data;
                    const updatedLessons = lessons.map((lesson) =>
                        lesson.id === id ? lessonResult : lesson
                    );
                    setLessons(updatedLessons);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setEditingKey(null);
    };

    const duplicate = (id: number) => {
        duplicateLesson(id)
            .then((resp) => {
                setLessons([...lessons, resp.data]);
                message.success(t('message.duplicatedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteLesson(deleteId)
            .then(() => {
                setLessons(lessons.filter((lesson) => lesson.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };

    const cancel = () => {
        setEditingKey(null);
        setEditingLesson(null);
    };

    const showDetails = (lesson: TLesson) => {
        const codification = lessonTypes.find((type) => type.id === lesson.lesson_type_id);
        switch (codification?.code) {
            case CODIFICATION_LESSON_TYPE.BLOCKLY_GAME:
                navigate('/setup/lesson-blockly-game', { state: { lesson, epic } });
                break;
            case CODIFICATION_LESSON_TYPE.BLOCKLY_DRAW:
                navigate('/setup/lesson-blockly-draw', { state: { lesson, epic } });
                break;
            case CODIFICATION_LESSON_TYPE.BLOCKLY_ARCADE:
                navigate('/setup/lesson-blockly-arcade', { state: { lesson, epic } });
                break;
            default:
        }
    };

    const columns: ColumnsType<TLesson> = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TLesson) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLesson?.name}
                        onChange={(e) => {
                            if (editingLesson) {
                                setEditingLesson({ ...editingLesson, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Enoncé',
            dataIndex: 'statement',
            key: 'statement',
            render: (text: string, record: TLesson) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLesson?.statement}
                        onChange={(e) => {
                            if (editingLesson) {
                                setEditingLesson({ ...editingLesson, statement: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Ordre',
            dataIndex: 'lesson_order',
            key: 'lesson_order',
            render: (text: number, record: TLesson) =>
                editingKey === record.id ? (
                    <Input
                        type="number"
                        value={editingLesson?.lesson_order}
                        onChange={(e) => {
                            if (editingLesson) {
                                setEditingLesson({ ...editingLesson, lesson_order: parseInt(e.target.value) });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Type',
            dataIndex: 'lesson_type_id',
            key: 'lesson_type_id',
            render: (lessontypeid: number, record: TLesson) =>
                editingKey === record.id ? (
                    <Select
                        value={editingLesson?.lesson_type_id}
                        onChange={(value: number) => {
                            if (editingLesson) {
                                setEditingLesson({ ...editingLesson, lesson_type_id: value });
                            }
                        }}
                    >
                        {lessonTypes.map((type: TCodification) => (
                            <Select.Option key={type.id} value={type.id}>
                                {type.label}
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    lessonTypes.find((type) => type.id === lessontypeid)?.label || 'N/A'
                ),
        },
        {
            title: 'Action',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 220,
            render: (_: any, record: TLesson) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />
                            <Button icon={<CopyOutlined />} onClick={() => duplicate(record.id)} />

                            {
                                isRoleExist(CODIFICATION_ROLES.DELETE_COURSE) && (
                                    <Button
                                        icon={<DeleteOutlined />}
                                        danger
                                        onClick={() => confirmDelete(record.id)}
                                        loading={deleteId === record.id && deleteLoading}
                                    />
                                )
                            }
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={`Epic : ${epic?.name}`} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddLesson}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TLesson>
                columns={columns}
                dataSource={lessons}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 800 }}
            />

            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this lesson? This action is irreversible."
            />

            <FloatButtons />
        </AppLayout>
    );
};

export default LessonList;
