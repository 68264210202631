// src/blocks/events/whenUpArrowPressed.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import i18next from 'i18next';

export function initWhenUpArrowPressedBlock() {

    Blockly.Blocks[BlocklyFields.WHEN_UP_ARROW_PRESSED] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.WHEN_UP_ARROW_PRESSED,
                "message0": '%1 ' + i18next.t('blockly.events.when_up_arrow_pressed.message', 'When Up Arrow Pressed') + ' %2',
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/button_up.png",
                        "width": 24,
                        "height": 24,
                    },
                    {
                        "type": "input_statement",
                        "name": "DO"
                    }
                ],
                "colour": '#a87332',
                "tooltip": i18next.t('blockly.events.when_up_arrow_pressed.tooltip', 'Triggered when the up arrow button is pressed.'),
                "helpUrl": "",
                "inputsInline": true,
            });
        }
    };

    javascriptGenerator.forBlock[BlocklyFields.WHEN_UP_ARROW_PRESSED] = function (block) {
        const statements_do = javascriptGenerator.statementToCode(block, 'DO');
        const code = `
        onUpArrowPressedFunctions.push(async function() {
            ${statements_do}
        });
        `;
        return code;
    };
}
