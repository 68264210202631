// src/blocks/actions/sayPhraseBlock.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import { TCharacter } from '../../type/character';
import i18next from 'i18next';

export function initSayPhraseBlock(characters: TCharacter[]) {
    // Créer des options pour le dropdown des personnages, avec leurs images 'idle'
    const characterOptions = characters.map((character) => [
        {
            src: character.images.idle,
            width: 36,
            height: 36,
            alt: character.name,
        },
        character.id.toString(),
    ]);

    Blockly.Blocks[BlocklyFields.SAY_PHRASE] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.SAY_PHRASE,
                "message0": '%1 dit %2',
                "args0": [
                    {
                        type: 'field_dropdown',
                        name: 'CHARACTER',
                        options: characterOptions,
                    },
                    {
                        type: 'field_input',
                        name: 'PHRASE',
                        text: 'Bonjour !',
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#4C97FF',
                "tooltip": i18next.t('blockly.say_phrase.tooltip', 'Fait dire une phrase au personnage.'),
                "helpUrl": '',
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.SAY_PHRASE] = function (block) {
        const characterId = block.getFieldValue('CHARACTER');
        const phrase = block.getFieldValue('PHRASE');
        // Échapper la phrase pour l'utiliser dans une chaîne JavaScript
        const escapedPhrase = phrase.replace(/\\/g, '\\\\').replace(/'/g, '\\\'').replace(/"/g, '\\"');
        return `await sayPhrase(${characterId}, "${escapedPhrase}");\n`;
    };
}
