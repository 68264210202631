import React from 'react';
import { message } from 'antd';
import BlocklyMouv from '../../../../blocks/v1/BlocklyMouv';
import { useTranslation } from 'react-i18next';
import { BlocklyFields } from '../../../../blocks/v1/config/fields';
import { FAIZAN } from '../../../../blocks/v1/constant/character';
import { TARGET_BATTERY } from '../../../../blocks/v1/constant/target';
import { ZELIJA } from '../../../../blocks/v1/constant/scene';

interface LoopsLaurelLevelSevenProps {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}

const LoopsLaurelLevelSeven: React.FC<LoopsLaurelLevelSevenProps> = ({ checkResult }) => {
    const { t } = useTranslation();

    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        }
    }
    return (
        <>
            <BlocklyMouv
                gridSize={{ side: 6 }}
                initialObjectPosition={{ row: 1, col: 4, scale: 1, rotate: 0, object: FAIZAN }}
                targetPositions={[
                    { row: 1, col: 3, object: TARGET_BATTERY },
                    { row: 1, col: 2, object: TARGET_BATTERY },
                    { row: 1, col: 1, object: TARGET_BATTERY },
                    { row: 1, col: 0, object: TARGET_BATTERY },
                    { row: 2, col: 0, object: TARGET_BATTERY },
                    { row: 3, col: 0, object: TARGET_BATTERY },
                    { row: 4, col: 0, object: TARGET_BATTERY },
                    { row: 4, col: 1, object: TARGET_BATTERY },
                    { row: 4, col: 2, object: TARGET_BATTERY },
                    { row: 4, col: 3, object: TARGET_BATTERY },
                    { row: 4, col: 4, object: TARGET_BATTERY },
                    { row: 4, col: 5, object: TARGET_BATTERY },
                ]}
                mustReachAllTargets={true}
                mustRespectOrder={false}
                toolbox={{
                    "contents": [
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_RIGHT,
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_DOWN,
                            "maxUsage": 0
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_LEFT,
                            "maxUsage": 0
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_UP
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.GET_TARGET,
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.REPEAT,
                        }
                    ]
                }}
                initialBlocksJson={
                    [

                        {
                            type: BlocklyFields.STARTBOX,
                            x: 10, y: 10,
                            deletable: false,
                            next: {
                                block: {
                                    type: BlocklyFields.REPEAT,
                                    x: 20, y: 10,
                                    fields: {
                                        TIMES: 4
                                    },
                                    inputs: {
                                        DO: {
                                            block: {
                                                type: BlocklyFields.MOVE_LEFT,
                                                next: {
                                                    block: {
                                                        type: BlocklyFields.GET_TARGET,

                                                    }
                                                }
                                            }

                                        }
                                    },
                                    next: {
                                        block: {
                                            type: BlocklyFields.REPEAT,
                                            x: 20, y: 10,
                                            fields: {
                                                TIMES: 3
                                            },
                                            inputs: {
                                                DO: {
                                                    block: {
                                                        type: BlocklyFields.MOVE_DOWN,
                                                        next: {
                                                            block: {
                                                                type: BlocklyFields.GET_TARGET,

                                                            }
                                                        }
                                                    }

                                                }
                                            },

                                        }
                                    }

                                }
                            }
                        }
                    ]
                }
                onTargetReached={isValid}

                autoTargetReaching={false}
                bg={ZELIJA}

            />
        </>
    );
};

export default LoopsLaurelLevelSeven;
