// src/components/BlocklyArcade.tsx

import React, { useState, useRef, useCallback } from 'react';
import { Button, Col, Row, Space } from 'antd';
import BlocklyEditor from '../../workspace/BlocklyEditor';
import { useTranslation } from 'react-i18next';
import IGame from './IGame';
import { useGameLogic } from './GameLogic';
import { TCharacter } from '../../type/character';
import { TScene } from '../../type/scene';
import { GameProvider, useGameContext } from './GameContext';

import * as Blockly from 'blockly';
import { initBlocklyArcadeBlocks } from '../../config/BlocklyBlocks';
import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { runBlockEvents } from '../BlocklyExecutor';
import CommandControls from './CommandControls';
import { TTarget } from '../../type/target';

interface BlocklyArcadeProps {
  checkResult: (isValid: boolean) => void;
  toolbox: any;
  initialBlocksJsonOrXML: any;
  characters: TCharacter[];
  target: TTarget;
  scene?: TScene;
  xmlRef?: React.MutableRefObject<string>;
  cansave?: boolean;
  editionMode: boolean;
}

const BlocklyArcade: React.FC<BlocklyArcadeProps> = (props) => {

  return (
    <GameProvider
      initialCharacters={props.characters}
      initialScene={props.scene || { width: 500, height: 500, gridRowWidth: 50, gridColumnWidth: 50 }}
      initialTarget={props.target}
    >
      <BlocklyArcadeContent {...props} />
    </GameProvider>
  );
};

const BlocklyArcadeContent: React.FC<BlocklyArcadeProps> = ({
  checkResult,
  toolbox,
  initialBlocksJsonOrXML,
  xmlRef,
  target,
  cansave = false,
  editionMode = false,
}) => {


  const { t } = useTranslation();
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg | null>(null);

  const { state } = useGameContext();
  const { scene, characters } = state;
  const animationPromiseResolverRef = useRef<(() => void) | null>(null);

  const [executeMode, setExecuteMode] = useState<boolean>(false);

  initBlocklyArcadeBlocks(characters);

  const gameLogic = useGameLogic({
    animationPromiseResolverRef,
    checkResult,
    target
  });

  const [onRightArrowPressedFunc, setOnRightArrowPressedFunc] = useState<(() => void) | null>(null);
  const [onLeftArrowPressedFunc, setOnLeftArrowPressedFunc] = useState<(() => void) | null>(null);
  const [onUpArrowPressedFunc, setOnUpArrowPressedFunc] = useState<(() => void) | null>(null);
  const [onDownArrowPressedFunc, setOnDownArrowPressedFunc] = useState<(() => void) | null>(null);

  // Nouvelle référence pour le bouton
  const rightArrowButtonRef = useRef<HTMLButtonElement | null>(null);
  const leftArrowButtonRef = useRef<HTMLButtonElement | null>(null);
  const upArrowButtonRef = useRef<HTMLButtonElement | null>(null);
  const downArrowButtonRef = useRef<HTMLButtonElement | null>(null);

  // Fonction pour exécuter le code Blockly
  const handleRun = useCallback(async () => {
    if (workspace) {
      const context = {
        walkCharacter: gameLogic.walkCharacter,
        jumpCharacter: gameLogic.jumpCharacter,
        setImage: gameLogic.setSceneBackground,
        resetGame: gameLogic.resetGame,
        sayPhrase: gameLogic.sayPhrase,
        revealSecret: gameLogic.revealSecret,
      };

      const {
        onRightArrowPressedFunctions,
        onLeftArrowPressedFunctions,
        onUpArrowPressedFunctions,
        onDownArrowPressedFunctions,
        onCharactersCollideFunctions
      } = await runBlockEvents(workspace, context);

      if (onRightArrowPressedFunctions && onRightArrowPressedFunctions.length > 0) {
        setOnRightArrowPressedFunc(() => async () => {
          for (const func of onRightArrowPressedFunctions) {
            await func();
          }
        });
      } else {
        setOnRightArrowPressedFunc(null);
      }

      if (onLeftArrowPressedFunctions && onLeftArrowPressedFunctions.length > 0) {
        setOnLeftArrowPressedFunc(() => async () => {
          for (const func of onLeftArrowPressedFunctions) {
            await func();
          }
        });
      } else {
        setOnLeftArrowPressedFunc(null);
      }

      if (onUpArrowPressedFunctions && onUpArrowPressedFunctions.length > 0) {
        setOnUpArrowPressedFunc(() => async () => {
          for (const func of onUpArrowPressedFunctions) {
            await func();
          }
        });
      } else {
        setOnUpArrowPressedFunc(null);
      }

      if (onDownArrowPressedFunctions && onDownArrowPressedFunctions.length > 0) {
        setOnDownArrowPressedFunc(() => async () => {
          for (const func of onDownArrowPressedFunctions) {
            await func();
          }
        });
      } else {
        setOnDownArrowPressedFunc(null);
      }

      if (onCharactersCollideFunctions && onCharactersCollideFunctions.length > 0) {
        gameLogic.setCollisionFunctions(onCharactersCollideFunctions);
      } else {
        gameLogic.setCollisionFunctions([]);
      }
    }
    // eslint-disable-next-line
  }, [workspace]);

  return (
    <div>
      <Row gutter={[16, 16]} style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Col flex="auto">
          <BlocklyEditor
            toolbox={toolbox}
            initialBlocksJsonOrXML={initialBlocksJsonOrXML}
            onWorkspaceChange={(ws) => setWorkspace(ws)}
            xmlRef={xmlRef}
            cansave={cansave}
          />
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Space>

              <Button
                type="primary"
                size="large"
                onClick={() => {
                  if (executeMode) {
                    gameLogic.resetGame();          // Arrête l'exécution
                    setExecuteMode(false);          // Repasser en mode "exécuter"
                  } else {
                    handleRun();                    // Lance l'exécution
                    setExecuteMode(true);           // Passe en mode "arrêt"
                  }
                }}
                icon={executeMode ? <StopOutlined /> : <PlayCircleOutlined />}
                danger={executeMode}
              >
                {executeMode ? t('button.stop') : t('button.run')}
              </Button>
            </Space>
          </div>
        </Col>
        <Col style={{ width: `${scene.width}px` }}>
          <IGame
            editionMode={editionMode}
            animationPromiseResolverRef={animationPromiseResolverRef}
          />
          {/* Les boutons de direction */}
          <Row justify="center" style={{ marginTop: '16px' }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <CommandControls
                onUpArrowPressedFunc={onUpArrowPressedFunc}
                onLeftArrowPressedFunc={onLeftArrowPressedFunc}
                onDownArrowPressedFunc={onDownArrowPressedFunc}
                onRightArrowPressedFunc={onRightArrowPressedFunc}
                upArrowButtonRef={upArrowButtonRef}
                leftArrowButtonRef={leftArrowButtonRef}
                downArrowButtonRef={downArrowButtonRef}
                rightArrowButtonRef={rightArrowButtonRef}
                executeMode={executeMode}
              />
            </Col>
          </Row>


        </Col>
      </Row>
    </div>
  );
};

export default BlocklyArcade;
