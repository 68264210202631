export const ENDPOINTS_SUBJECTS = {
    GET_ALL: '/subjects',
    GET_ALL_ACTIF: '/subjects/admin',
    GET_BY_ID: (subjectId: number) => `/subjects/${subjectId}`,
    GET_BY_IDCOURSE: (courseId: number) => `/subjects/course/${courseId}`,
    POST_SUBJECT: '/subjects',
    PUT_SUBJECT: (subjectId: number) => `/subjects/${subjectId}`,
    DELETE_SUBJECT: (subjectId: number) => `/subjects/${subjectId}`,
    UPLOAD_IMAGE: (subjectId: number) => `/subjects/${subjectId}/upload-image`,
    GET_SUBJECTS_AND_PROGRESS: `/subjects/progress`,

};

export const ENDPOINTS_COURSES = {
    GET_ALL: '/courses',
    GET_ALL_ADMIN: '/courses/admin',
    GET_DETAILS_BY_ID: (courseId: number) => `/courses/${courseId}/details`,
    GET_DETAILS_BY_IDLESSON: (lessonId: number) => `/courses/lesson/${lessonId}/details`,
    POST_COURSE: `/courses`,
    PUT_COURSE: (courseId: number) => `/courses/${courseId}`,
    DELETE_COURSE: (courseId: number) => `/subjects/${courseId}`,
    UPLOAD_IMAGE: (courseId: number) => `/courses/${courseId}/upload-image`,
    GET_COURSES_BY_SUBJECT_ID: (subjectId: number) => `/courses/subject/${subjectId}/courses`,
    GET_COURSES_BY_SUBJECT_ID_ADMIN: (subjectId: number) => `/courses/subject/${subjectId}/courses/admin`,
    GET_COURSES_BY_SUBJECT_ID_AND_PROGRESS: (subjectId: number) => `/courses/subject/${subjectId}/progress`,


};

export const ENDPOINTS_CHAPTERS = {
    GET_ALL: '/chapters',
    GET_BY_ID: (epicId: number) => `/chapters/${epicId}`,
    POST_CHAPTER: '/chapters',
    PUT_CHAPTER: (epicId: number) => `/chapters/${epicId}`,
    DELETE_CHAPTER: (epicId: number) => `/chapters/${epicId}`,
    GET_CHAPTERS_BY_COURSE_ID: (id: number) => `/courses/${id}/chapters`,
};

export const ENDPOINTS_EPICS = {
    GET_ALL: '/epics',
    GET_BY_ID: (epicId: number) => `/epics/${epicId}`,
    POST_EPIC: '/epics',
    PUT_EPIC: (epicId: number) => `/epics/${epicId}`,
    DELETE_EPIC: (epicId: number) => `/epics/${epicId}`,
    GET_EPICS_BY_CHAPTER_ID: (chapterId: number) => `/chapters/${chapterId}/epics`,
};

export const ENDPOINTS_LESSONS = {
    GET_ALL: '/lessons',
    GET_BY_ID: (lessonId: number) => `/lessons/${lessonId}`,
    GET_DETAILS_BY_ID: (lessonId: number) => `/lessons/${lessonId}/details`,
    POST_SAVE_LESSON_DETAILS: `/lessons/details`,
    POST_LESSON: '/lessons',
    PUT_LESSON: (lessonId: number) => `/lessons/${lessonId}`,
    POST_DUPLICATE: (lessonId: number) => `/lessons/${lessonId}/duplicate`,
    DELETE_LESSON: (lessonId: number) => `/lessons/${lessonId}`,
    GET_LESSONS_BY_EPIC_ID: (epicId: number) => `/epics/${epicId}/lessons`,
};
