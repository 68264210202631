// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import i18next from 'i18next';

export function initGetTargetBlock() {

    Blockly.Blocks[BlocklyFields.GET_TARGET] = {
        init: function () {
            this.jsonInit({
                "type": "get_target",
                "message0": i18next.t('blockly.get.tooltip', 'get'),
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#FF6680',
                "tooltip": i18next.t('blockly.get.tooltip', 'get'),
                "helpUrl": ""
            });
        }
    };

    javascriptGenerator.forBlock[BlocklyFields.GET_TARGET] = function (block) {
        return `await reachTarget();\n`;
    };
}

