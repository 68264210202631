
import { TLogin } from '../../models/login/login';
import { useApiClient } from '../apiClient';
import { ENDPOINTS_AUTH } from './endpoints';

export const useAuthApi = () => {
    const apiClient = useApiClient();

    const basicAuth = (login: string, password: string) => {
        return apiClient.post<TLogin>(ENDPOINTS_AUTH.POST_AUTH, {}, {
            headers: {
                'Authorization': `Basic ${btoa(login + ":" + password)}`,
            }
        });
    };

    const apiLogOut = () => apiClient.post(ENDPOINTS_AUTH.POST_LOGOUT);


    return {

        basicAuth,
        apiLogOut,

    };
};
