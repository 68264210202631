// src/blockly/BlocklyExecutor.ts

import { javascriptGenerator } from 'blockly/javascript';
import * as Blockly from 'blockly';

export const executeAsyncCode = async (
  workspace: Blockly.WorkspaceSvg,
  context: any,
): Promise<void> => {
  try {
    let code = javascriptGenerator.workspaceToCode(workspace);
    code += `
        await isDone();
      `;
    const asyncCode = `
        (async () => {
          ${code}
        })();
      `;

    // eslint-disable-next-line
    const func = new Function(...Object.keys(context), `${asyncCode}`);
    const result = func(...Object.values(context));

    if (result instanceof Promise) {
      await result;
    }


  } catch (error) {
    console.error('Error executing Blockly code:', error);
  }
};

// src/components/BlocklyExecutor.ts


export const runBlockEvents = async (
  workspace: Blockly.WorkspaceSvg,
  context: any
) => {

  let code = javascriptGenerator.workspaceToCode(workspace);

  try {
    // eslint-disable-next-line
    const func = new Function(...Object.keys(context), `
      var onRightArrowPressedFunctions = [];
      var onLeftArrowPressedFunctions = [];
      var onUpArrowPressedFunctions = [];
      var onDownArrowPressedFunctions = [];
      var onCharactersCollideFunctions = [];
      ${code}
      return {
        onRightArrowPressedFunctions,
        onLeftArrowPressedFunctions,
        onUpArrowPressedFunctions,
        onDownArrowPressedFunctions,
        onCharactersCollideFunctions,
      };
    `);

    const result = func(...Object.values(context));

    return result;

  } catch (error) {
    console.error('Erreur lors de l\'exécution du code Blockly :', error);
    return {};
  }
};

