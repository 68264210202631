// src/blocks/index.ts

// Move Blocks
import { initMoveRightBlock } from './move/walk/moveRight';
import { initMoveLeftBlock } from './move/walk/moveLeft';
import { initMoveUpBlock } from './move/walk/moveUp';
import { initMoveDownBlock } from './move/walk/moveDown';

// Jump Blocks
import { initJumpRightBlock } from './move/jump/jumpRight';
import { initJumpLeftBlock } from './move/jump/jumpLeft';
import { initJumpUpBlock } from './move/jump/jumpUp';
import { initJumpDownBlock } from './move/jump/jumpDown';

// Other Blocks
import { initStartBoxBlock } from './static/startBox';
import { initRepeatCustomBlock } from './statement/repeat';
import { initGetTargetBlock } from './action/getTarget';
import { initMoveUpRightBlock } from './move/walkAngle/moveUpRight';
import { initMoveUpLeftBlock } from './move/walkAngle/moveUpLeft';
import { initMoveDownRightBlock } from './move/walkAngle/moveDownRight';
import { initMoveDownLeftBlock } from './move/walkAngle/moveDownLeft';
import { initChangeLineTypeBlock } from './draw/linePalette';
import { initChangeLineColorBlock } from './draw/colorPalette';
import { initSelectImageBlock } from './action/ImageBackground';
import { initMoveChosenCharacterBlock } from './arcade/move_chosen_character';
import { TCharacter } from '../type/character';
import { initWhenRightArrowPressedBlock } from './arcade/when_right_button';
import { initWhenUpArrowPressedBlock } from './arcade/when_up_button';
import { initWhenLeftArrowPressedBlock } from './arcade/when_left_button';
import { initWhenDownArrowPressedBlock } from './arcade/when_down_button';
import { initWhenCharactersCollide } from './arcade/when_characters_collide';
import { initSayPhraseBlock } from './arcade/say_phrase';
import { initSaySecretBlock } from './arcade/say_password';


export function initBlocklyGameBlocks() {
  //common blocks 
  initCommonBlocks()


  initChangeLineTypeBlock();
  initChangeLineColorBlock();
  initSelectImageBlock();
}

export function initBlocklyArcadeBlocks(characters: TCharacter[]) {
  initCommonBlocks();

  initMoveChosenCharacterBlock(characters)
  initWhenRightArrowPressedBlock();
  initWhenUpArrowPressedBlock();
  initWhenLeftArrowPressedBlock();
  initWhenDownArrowPressedBlock();
  initWhenCharactersCollide(characters);
  initSayPhraseBlock(characters);
  initSaySecretBlock(characters);

  initChangeLineTypeBlock();
  initChangeLineColorBlock();
  initSelectImageBlock();
}

const initCommonBlocks = () => {
  // Move Blocks
  initMoveRightBlock();
  initMoveLeftBlock();
  initMoveUpBlock();
  initMoveDownBlock();

  // Jump Blocks
  initJumpRightBlock();
  initJumpLeftBlock();
  initJumpUpBlock();
  initJumpDownBlock();

  initMoveUpRightBlock();
  initMoveUpLeftBlock();
  initMoveDownRightBlock();
  initMoveDownLeftBlock();

  // Other Blocks
  initStartBoxBlock();
  initRepeatCustomBlock();
  initGetTargetBlock();
} 