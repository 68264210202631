import React from 'react'
import AppLayout from '../../components/layout/AppLayout'
import { TCharacter } from '../../blocks/core/type/character';
import { getSceneZelija, TScene } from '../../blocks/core/type/scene';
import { BlocklyFields } from '../../blocks/core/config/BlocklyFields';
import { createBlocklyBlocks } from '../../blocks/core/config/utils';
import { message } from 'antd';
import { getFaizan } from '../../blocks/character/faizan';
import { useTranslation } from 'react-i18next';
import { getMrPen } from '../../blocks/character/pen';
import BlocklyArcade from '../../blocks/core/component/BlocklyArcade/BlocklyArcade';
import { createTarget, TTarget } from '../../blocks/core/type/target';
import targetIdle from "../../assets/images/target/target_battery.png"

const TestDevelopement: React.FC = () => {

    const { t } = useTranslation();

    // character
    const characters: TCharacter[] = [
        getFaizan(1, 100, 100, true, 10),
        getMrPen(2, 150, 150, false)
    ];

    const target: TTarget = createTarget(50, 50, 50, 50, targetIdle, {}, false)
    // scene
    const scene: TScene = getSceneZelija(500, 500);

    // validation func
    const isValid = (isValid: boolean) => {
        if (isValid) {
            message.success(t("message.bravo"));
        } else {
            message.warning(t("message.bug"));
        }
    }
    const initialBlocksJson: any = []


    return (
        <AppLayout title='test'>
            <BlocklyArcade
                editionMode={false}
                checkResult={isValid}
                characters={characters}
                scene={scene}
                toolbox={{
                    "contents": createBlocklyBlocks([
                        { type: BlocklyFields.MOVE_CHOSEN_CARACTER },
                        { type: BlocklyFields.WHEN_RIGHT_ARROW_PRESSED },
                        { type: BlocklyFields.WHEN_UP_ARROW_PRESSED },
                        { type: BlocklyFields.WHEN_DOWN_ARROW_PRESSED },
                        { type: BlocklyFields.WHEN_LEFT_ARROW_PRESSED },
                        { type: BlocklyFields.WHEN_CHARACTERS_COLLIDE },
                        { type: BlocklyFields.SAY_PHRASE },
                        { type: BlocklyFields.SAY_SECRET },

                    ])
                }}
                initialBlocksJsonOrXML={JSON.stringify(initialBlocksJson)}
                target={target}

            />
        </AppLayout>
    )
}

export default TestDevelopement;
