// useApiFunctions.js
import { TCodification, TCountry } from '../../models/referential/referential';
import { useApiClient } from '../apiClient';
import { ENDPOINTS_REF } from './endpoints';

export const useReferentialApi = () => {
    const apiClient = useApiClient();

    // Codifications and Countries
    const getCodifications = () => apiClient.get<TCodification[]>(ENDPOINTS_REF.GET_CODIFICATIONS);
    const getCountries = () => apiClient.get<TCountry[]>(ENDPOINTS_REF.GET_COUNTRIES);


    return {
        getCodifications,
        getCountries,
    };
};
