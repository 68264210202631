import React, { useState } from 'react';
import { Row, Col, Button, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TCourse } from '../../models/courses/course';
import { useTranslation } from 'react-i18next';
import { getImagePath } from '../../utils/format/valueFormat';
import { PlayCircleOutlined } from '@ant-design/icons';

interface CourseCardProps {
    course: TCourse;
}

const CourseCard: React.FC<CourseCardProps> = ({ course }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const goToDetails = () => {
        navigate(`/course-details/${course.id}`);
    };

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                width: '100%',
                padding: '16px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                backgroundColor: isHovered ? 'white' : 'transparent',
                transition: 'background-color 0.3s ease-in-out',
            }}
        >
            <Row gutter={16} align="middle">
                {/* Course Image */}
                <Col xs={24} sm={4} style={{ textAlign: 'center' }}>
                    <img
                        alt={course.title}
                        src={getImagePath(course.image)}
                        style={{
                            width: '100%',
                            maxWidth: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                        }}
                    />
                </Col>
                {/* Course Information */}
                <Col xs={24} sm={16}>
                    <h3 style={{ margin: 0 }}>{course.title}</h3>
                    <p style={{ margin: '4px 0' }}>{course.description}</p>
                    <p style={{ margin: '4px 0', color: '#888' }}>
                        {t('text.agefrom', { age_min: course.min_age ?? '-' })}{' '}
                        {t('text.ageto', { age_max: course.max_age ?? '-' })}
                    </p>
                    <Progress
                        percent={Math.round(course.progression_state ?? 0)}
                        size="small"
                        status="active"
                        style={{ width: '200px' }}
                    />
                </Col>
                {/* Icon-only Start Button */}
                <Col xs={24} sm={4} style={{ textAlign: 'center' }}>
                    <Button
                        icon={<PlayCircleOutlined />}
                        size="large"
                        onClick={goToDetails}
                        style={{
                            backgroundColor: '#52c41a',
                            borderColor: '#52c41a',
                            color: 'white',
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default CourseCard;
