// src/components/user/UserForm.tsx

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    message,
    Form,
    Input,
    Card,
    Spin,
    Row,
    Col,
    Space,
    Button,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useUserApi } from '../../../../api/user/userApp';
import { TContact, TPassword, TUserApp, TUserAppRelation } from '../../../../models/user/User';
import AppLayout from '../../../../components/layout/AppLayout';
import FloatButtons from '../../../../components/button/FloatButtons';
import PersonalInfoForm from './PersonalInfoForm';
import BusinessInfoForm from './BusinessInfoForm';
import ContactsManager from './ContactsManager';
import { useReferential } from '../../../../context/ReferentialContext';
import { CODIFICATION_TYPE, CODIFICATION_TYPE_RELATION, CODIFICATION_TYPE_USERS } from '../../../../constant/codification';
import { TCodification } from '../../../../models/referential/referential';
import RelationManager from './RelationManager';


const UserForm: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { getCodificationByCodes, getCodificationById } = useReferential();


    const { getUserDetails, saveUserWithDetails, generateLoginUserApp } = useUserApi();
    const [userApp, setUserApp] = useState<TUserApp | null>(null);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [generatingLogin, setGeneratingLogin] = useState<boolean>(false);
    const [form] = Form.useForm();

    const userAppId: number | null = location.state?.userAppId || null;
    const isEditMode = !!userAppId;
    const typeUser: TCodification = location.state?.typeUser;

    // get type of relations :
    const codif_teacher_student = getCodificationByCodes(CODIFICATION_TYPE.TYPE_RELATION, CODIFICATION_TYPE_RELATION.TEACHER_STUDENT)
    const codif_parent_student = getCodificationByCodes(CODIFICATION_TYPE.TYPE_RELATION, CODIFICATION_TYPE_RELATION.PARENT_STUDENT)

    const [typeUserLoaded, setTypeUserLoaded] = useState<TCodification | null>(typeUser ?? null);
    useEffect(() => {
        setTypeUserLoaded(typeUser);
    }, [typeUser]);

    useEffect(() => {
        if (isEditMode) {
            setIsLoading(true);
            getUserDetails(userAppId!)
                .then((resp) => {
                    const result: TUserApp = resp.data;

                    const typeUserApp = getCodificationById(result.usertype_id);
                    if (typeUserApp) {
                        setTypeUserLoaded(typeUserApp);
                    }

                    setUserApp(result);
                    form.setFieldsValue({
                        ...resp.data,
                        personal_info: {
                            ...resp.data.personal_info,
                            birthdate: resp.data.personal_info?.birthdate
                                ? dayjs(resp.data.personal_info.birthdate)
                                : null,
                        },
                    });
                })
                .catch((err) => {
                    message.error(err.response?.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [userAppId]);

    const generateLogin = async () => {
        try {
            // Récupérer les valeurs actuelles du formulaire
            const values = await form.validateFields(["personal_info", "business_info"]);
            const firstname = values.personal_info?.firstname;
            const lastname = values.personal_info?.lastname;
            const businessname = values.business_info?.name;

            if (!businessname && (!firstname || !lastname)) {
                message.error(t('message.names_required', { min: 2 }));
                return;
            }
            if (firstname && lastname && (firstname.length < 2 || lastname.length < 2)) {
                message.error(t('message.min_len', { len: 2 }));
                return;
            } else if (businessname && businessname.length < 2) {
                message.error(t('message.min_len', { len: 2 }));
                return;
            }
            setGeneratingLogin(true);

            const payload = {
                firstname: firstname || "school",
                lastname: lastname || businessname
            }
            // Appel à l'API pour générer un login unique
            const response: any = await generateLoginUserApp(payload);
            const generatedLogin = response.data.login;

            // Mettre à jour le champ "login" dans le formulaire
            form.setFieldsValue({ login: generatedLogin });

        } catch (err: any) {
            console.error("Error generating login:", err);
            message.error(err.response?.data ?? err.message);
        } finally {
            setGeneratingLogin(false);
        }
    }

    const onFinish = async (values: any): Promise<void> => {
        // Filtrer les contacts valides
        const validContacts = (values.contacts || []).filter((contact: TContact) => {
            return contact.type_id && contact.contact_value;
        });



        // retrive all relations from form
        const allValues = [
            ...(values.teachers || []).filter((item: any) => item != null),
            ...(values.parents || []).filter((item: any) => item != null),
            ...(values.students || []).filter((item: any) => item != null),
        ];

        const relationUserApp = (allValues || []).map((item: any) => {
            if (typeUserLoaded &&
                typeUserLoaded.code === CODIFICATION_TYPE_USERS.MINOR) {
                return {
                    typerelation_id: item.typeRelationCodification.id,
                    parent_id: item.id,
                    children_id: userApp?.id || 0,
                } as TUserAppRelation
            } else {
                return {
                    typerelation_id: item.typeRelationCodification.id,
                    parent_id: userApp?.id || 0,
                    children_id: item.id,
                } as TUserAppRelation
            }

        });


        // password
        const password: TPassword = {
            id: 0,
            userapp_id: 0,
            password: values.password,
        }

        // format date
        if (values && values.personal_info && values.personal_info.birthdate) {
            values.personal_info.birthdate = values.personal_info.birthdate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        }
        if (typeUserLoaded) {
            // Préparer les données pour l'API
            const payload = {
                id: userApp?.id || 0,
                login: values.login,
                usertype_id: typeUserLoaded.id,
                max_session: 1,
                passwords: [password],
                userapp_relations: relationUserApp,
                personal_info: values.personal_info,
                business_info: values.business_info,
                contacts: validContacts,
            } as TUserApp;

            try {
                await saveUserWithDetails(payload);
                navigate(-1);
            } catch (err: any) {
                message.error(err.response?.data ?? err.message);
                throw err; // Relancer l'erreur pour qu'elle soit capturée dans handleSave
            }
        } else {
            message.error(t('message.bugBackend'));
            throw new Error(t('message.bugBackend'));
        }


    };
    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            await onFinish(values);
        } catch (error) {
            // Gérer les erreurs de validation ou de sauvegarde
            console.error("An error occurred while saving:", error);
            throw error; // Relancer l'erreur pour que `FloatButtons` puisse la capturer
        }
    };

    return (
        <AppLayout
            title={(userApp && userApp.id > 0 ? t('text.update') : t('text.add')) + " : " + (typeUserLoaded && typeUserLoaded.label)}
            loading={loading}
        >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={(errorInfo) => {
                        console.error('Failed:', errorInfo);
                    }}
                    initialValues={userApp ? undefined : {
                        max_session: 1,
                        children: [],
                        personal_info: {
                            firstname: '',
                            lastname: '',
                        },
                    }}
                >
                    {/* Première ligne avec trois colonnes */}
                    <Row gutter={16}>


                        {
                            typeUserLoaded && typeUserLoaded.code !== CODIFICATION_TYPE_USERS.BUSINESS && (
                                <Col xs={24} md={8}>
                                    <PersonalInfoForm />
                                </Col>
                            )
                        }

                        {
                            typeUserLoaded && typeUserLoaded.code === CODIFICATION_TYPE_USERS.BUSINESS && (
                                <Col xs={24} md={8}>
                                    <BusinessInfoForm />
                                </Col>
                            )
                        }
                        <Col xs={24} md={8}>
                            <Card title={t('text.user_info')} style={{ marginBottom: 16 }}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item
                                        name="login"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: t('message.value_required'),
                                            },
                                        ]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={generateLogin}
                                        loading={generatingLogin}
                                    >
                                        Générer
                                    </Button>
                                </Space.Compact>


                                <Form.Item
                                    label={t('text.password')}
                                    name="password"
                                >
                                    <Input.Password />
                                </Form.Item>

                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <ContactsManager />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        {
                            typeUserLoaded &&
                            typeUserLoaded.code === CODIFICATION_TYPE_USERS.MINOR &&
                            (
                                <>
                                    <Col xs={24} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
                                        <RelationManager
                                            title='Parent'
                                            typeUser={typeUserLoaded}
                                            codifTypeRelation={codif_parent_student}
                                            userApp={userApp}
                                            fieldName="parents"
                                        />
                                    </Col>
                                    <Col xs={24} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
                                        <RelationManager
                                            title='Teacher'
                                            typeUser={typeUserLoaded}
                                            codifTypeRelation={codif_teacher_student}
                                            userApp={userApp}
                                            fieldName="teachers"
                                        />
                                    </Col>
                                </>
                            )
                        }
                        {
                            typeUserLoaded &&
                            typeUserLoaded.code === CODIFICATION_TYPE_USERS.ADULT &&
                            (
                                <>
                                    <Col xs={24} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
                                        <RelationManager
                                            title='Enfant'
                                            typeUser={typeUserLoaded}
                                            codifTypeRelation={codif_parent_student}
                                            userApp={userApp}
                                            fieldName="students"
                                        />
                                    </Col>
                                </>
                            )
                        }
                        {
                            typeUserLoaded &&
                            typeUserLoaded.code === CODIFICATION_TYPE_USERS.TEACHER &&
                            (
                                <>
                                    <Col xs={24} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
                                        <RelationManager
                                            title='Élève'
                                            typeUser={typeUserLoaded}
                                            codifTypeRelation={codif_teacher_student}
                                            userApp={userApp}
                                            fieldName="students"
                                        />
                                    </Col>
                                </>
                            )
                        }
                    </Row>

                </Form>
            </Spin>
            <FloatButtons save={handleSave} />
        </AppLayout >
    );
};

export default UserForm;
