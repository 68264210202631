import React from 'react';
import { Select, Input, Space, Switch } from 'antd';
import { TScene } from '../../../../models/objects/objects';
import { TLesson } from '../../../../models/courses/lesson';
import { getImagePath } from '../../../../utils/format/valueFormat';

const { Option } = Select;

interface SceneProps {
    scenes: TScene[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const Scene: React.FC<SceneProps> = ({ scenes, editingLesson, setEditingLesson }) => {
    const handleChange = (Id: number) => {
        const selected = scenes.find(e => e.id === Id);
        if (selected && editingLesson) {
            setEditingLesson({
                ...editingLesson,
                scene: {
                    ...(editingLesson.scene || { id: 0, lesson_id: 0, width: 500, height: 500, repeat: false, size: '' }),
                    scene_id: Id,
                    scene: selected,
                }
            });
        }

    };

    const handleInputChange = (name: string, value: string | number | boolean) => {
        if (editingLesson) {
            setEditingLesson({
                ...editingLesson,
                scene: {
                    ...(editingLesson.scene || { id: 0, scene_id: 0, lesson_id: 0, width: 500, height: 500, repeat: false, size: '', scene: {} as TScene }),  // Default for 'scene'
                    [name]: value
                }
            });
        }
    }

    return (
        <Space direction="vertical">
            <Select
                placeholder={'Scene'}
                style={{ width: '100%' }}
                onChange={handleChange}
                value={editingLesson?.scene?.scene.id} // Use the id to track the selection
            >
                {scenes.map(scene => (
                    <Option key={scene.id} value={scene.id}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img

                                src={getImagePath(scene.img)}  // Assumes the character object has an 'image' field
                                alt={String(scene.id)}
                                style={{ width: '30px', height: '30px', marginRight: '10px' }}
                            />
                            <span>{scene.name}</span>
                        </div>
                    </Option>
                ))}
            </Select>
            <Input
                name='height'
                addonBefore="height (px)"
                value={editingLesson?.scene?.height}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='width'
                addonBefore="width (px)"
                value={editingLesson?.scene?.width}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='size'
                addonBefore="size"
                placeholder='500px 500px'
                value={editingLesson?.scene?.size}
                onChange={(e) => {
                    if (editingLesson) {
                        handleInputChange(e.target.name, e.target.value)
                    }
                }}
            />
            <Switch
                checked={editingLesson?.scene?.repeat}
                checkedChildren="repeat" unCheckedChildren="no-repeat"
                onChange={checked => {
                    if (editingLesson) {
                        handleInputChange('repeat', checked)
                    }
                }}
            />
        </Space>
    );
};

export default Scene;
