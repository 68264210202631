import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import BlocklyMouv from '../../../../blocks/v1/BlocklyMouv';
import { useTranslation } from 'react-i18next';
import { BlocklyFields } from '../../../../blocks/v1/config/fields';
import { FAIZAN } from '../../../../blocks/v1/constant/character';
import { TARGET } from '../../../../blocks/v1/constant/target';
import { ZELIJA } from '../../../../blocks/v1/constant/scene';
import { POUF_50 } from '../../../../blocks/v1/constant/obstacles/vase';
import { Obstacle } from '../../../../blocks/v1/type/scene';

interface LoopsScartLevelTenProps {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}

const LoopsScartLevelTen: React.FC<LoopsScartLevelTenProps> = ({ checkResult }) => {
    const { t } = useTranslation();
    const [obstacles, setObstacles] = useState<Obstacle[]>([]);
    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        }
    }
    useEffect(() => {
        let obst = [];
        for (let i = 0; i < 7; i++) {
            for (let j = 0; j < 7; j++) {
                if (!(
                    (i === 1 && [1, 2, 3, 4, 5, 6].includes(j)) || // Exclure (1,1), (1,2), (1,3)
                    (i === 2 && [1, 2, 3, 4, 5, 6].includes(j)) ||   // Exclure (4,0), (4,1)
                    (i === 3 && [1, 2, 3, 4, 5, 6].includes(j)) ||
                    (i === 4 && [1, 2, 3, 4, 5, 6].includes(j)) ||
                    (i === 5 && [1, 2, 3, 4, 5, 6].includes(j)) ||
                    (i === 6 && [1, 2, 3, 4, 5, 6].includes(j))
                )) {
                    obst.push(POUF_50(i, j));
                }
            }
        }
        setObstacles(obst);
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <BlocklyMouv
                gridSize={{ side: 7 }}
                initialObjectPosition={{ row: 6, col: 1, scale: 1, rotate: 0, object: FAIZAN }}
                targetPositions={[
                    { row: 1, col: 6, object: TARGET },
                ]}
                toolbox={{
                    "contents": [
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_RIGHT
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_DOWN
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_LEFT
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_UP
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.REPEAT,
                        }
                    ]
                }}
                initialBlocksJson={
                    [

                        {
                            type: BlocklyFields.STARTBOX,
                            x: 10, y: 10,
                            deletable: false,
                        }


                    ]
                }
                onTargetReached={isValid}
                obstacles={obstacles}
                bg={ZELIJA}

            />
        </>
    );
};

export default LoopsScartLevelTen;
