import React, { useState } from 'react';
import { Select, Input, Space, Button, Switch, Table } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { TLessonBlocklyContent, TLesson } from '../../../../models/courses/lesson';
import { TCodification } from '../../../../models/referential/referential';

const { Option } = Select;

interface BlocklyContentProps {
    blocklyContentFields: TCodification[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const BlocklyContent: React.FC<BlocklyContentProps> = ({ blocklyContentFields, editingLesson, setEditingLesson }) => {
    const [addingId, setAddingId] = useState<number>(0);
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingBlockly, setEditingBlockly] = useState<TLessonBlocklyContent | null>(null);

    const edit = (blockly: TLessonBlocklyContent) => {
        setEditingKey(blockly.id);
        setEditingBlockly({ ...blockly });
    };


    const handleAddBlockly = () => {
        const idRow = addingId - 1;
        const newBlockly: TLessonBlocklyContent = {
            id: idRow,
            blocklyfield_id: 0,
            lesson_id: 0,
            max_usage: 0,
            deletable: true,
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingBlockly(newBlockly);

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                blocklycontent: [...(prevLesson.blocklycontent || []), newBlockly],
            };
        });
    };

    const save = (id: number) => {
        if (!editingBlockly) {
            return;
        }

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;
            const newBlocklyContents = prevLesson.blocklycontent?.map((blockly) => {
                if (blockly.id === id) {
                    return { ...editingBlockly, id };
                }
                return blockly;
            });

            return {
                ...prevLesson,
                blocklycontent: newBlocklyContents,
            };
        });

        setEditingKey(null);
        setEditingBlockly(null);
    };

    const handleDelete = (id: number) => {
        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                blocklycontent: prevLesson.blocklycontent?.filter((blockly) => blockly.id !== id),
            };
        });
    };

    const handleChange = (value: number) => {
        setEditingBlockly((prev) => {
            if (!prev) return prev;
            return { ...prev, blocklyfield_id: value };
        });
    };

    const handleInputChange = (field: string, value: string) => {
        setEditingBlockly((prev) => {
            if (!prev) return prev;
            return { ...prev, [field]: Number(value) };
        });
    };
    const columns = [
        {
            title: 'Blockly Field',
            dataIndex: 'blocklyfield_id',
            key: 'blocklyfield_id',
            render: (text: number, record: TLessonBlocklyContent) =>
                editingKey === record.id ? (
                    <Select
                        placeholder={'Field'}
                        style={{ width: '100%' }}
                        onChange={(value) => handleChange(value)}
                        value={editingBlockly?.blocklyfield_id}
                    >
                        {blocklyContentFields
                            .filter(e => !(editingLesson?.blocklycontent || [])
                                .some(itemB => itemB.blocklyfield_id === e.id)).map((blck) => (
                                    <Option key={blck.id} value={blck.id}>
                                        {blck.label}
                                    </Option>
                                ))}
                    </Select>
                ) : (
                    blocklyContentFields?.find((blck) => blck.id === record.blocklyfield_id)?.label || 'N/A'
                ),
        },
        {
            title: 'Max Usage',
            dataIndex: 'max_usage',
            key: 'max_usage',
            render: (text: number, record: TLessonBlocklyContent) =>
                editingKey === record.id ? (
                    <Input
                        value={editingBlockly?.max_usage}
                        onChange={(e) => handleInputChange('max_usage', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Deletable',
            dataIndex: 'deletable',
            key: 'deletable',
            render: (deletable: boolean, record: TLessonBlocklyContent) =>
                editingKey === record.id ? (
                    <Switch
                        checked={editingBlockly?.deletable}
                        onChange={(checked) => {
                            if (editingBlockly) {
                                setEditingBlockly({ ...editingBlockly, deletable: checked });
                            }
                        }}
                    />
                ) : (
                    <Switch checked={deletable} disabled />
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: TLessonBlocklyContent) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)} />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Button type="primary" onClick={handleAddBlockly} disabled={editingKey !== null}>
                Add
            </Button>
            <Table
                dataSource={editingLesson?.blocklycontent}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </Space>
    );
};

export default BlocklyContent;