import { Badge } from 'antd';
import React from 'react';
import { CharacterPosition } from './type/character';
import { TargetPosition } from './type/target';
import { BackgroundScene, Obstacle } from './type/scene';

interface GameGridProps {
    gridSize: { side: number };
    objectPosition: CharacterPosition;
    targetPositions: TargetPosition[];
    mustRespectOrder?: boolean;
    bg: BackgroundScene;
    obstacles?: Obstacle[];
}

const GameGrid: React.FC<GameGridProps> = ({
    gridSize,
    objectPosition,
    targetPositions,
    mustRespectOrder = false,
    bg,
    obstacles = []
}) => {

    const getImgObstacle = (row: number, col: number) => {
        for (const obstacle of obstacles) {
            const element = obstacle.elements.find(el => el.x === row && el.y === col);
            if (element) {
                return element.img;
            }
        }
        return "transparent";
    }
    const cellSize = 400 / gridSize.side;
    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${gridSize.side}, ${cellSize}px)`,
            gridTemplateRows: `repeat(${gridSize.side}, ${cellSize}px)`,
            border: '1px solid black',
            width: gridSize.side * cellSize + 'px',
            height: gridSize.side * cellSize + 'px',
            position: 'relative',
            backgroundImage: bg.img !== "" ? `url(${bg.img})` : `
                linear-gradient(to right, black 1px, transparent 1px),
                linear-gradient(to bottom, black 1px, transparent 1px)
            `,
            backgroundSize: `${cellSize}px ${cellSize}px`,
            backgroundRepeat: bg.repeat ? 'repeat' : '',
        }}>
            {/* Parcours de la grille pour afficher chaque case */}
            {Array.from({ length: gridSize.side }).map((_, row) =>
                Array.from({ length: gridSize.side }).map((_, col) => (
                    <div
                        key={`${row}-${col}`}
                        id={`wall-${row}-${col}`}
                        style={{
                            width: cellSize + 'px',
                            height: cellSize + 'px',
                            backgroundImage: `url(${getImgObstacle(row, col)})`,
                            position: 'relative',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                ))
            )}

            {/* Objet à déplacer */}
            <div
                id="myObject"
                style={{
                    width: cellSize + 'px',
                    height: cellSize + 'px',
                    backgroundImage: `url(${objectPosition.img || 'defaultObjectImage.png'})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    left: objectPosition.col * cellSize + 'px',
                    top: objectPosition.row * cellSize + 'px',
                    transform: `rotate(${objectPosition.rotate}deg) scaleX(${objectPosition.scale})`,  // Flip the image horizontally
                    transition: 'left 0.5s ease, top 0.5s ease',
                }}
            />

            {/* Cibles (multiple) avec badge si mustRespectOrder est à true */}
            {targetPositions.map((targetPosition, index) => (
                <div
                    key={`target-${index}`}
                    style={{
                        width: cellSize + 'px',
                        height: cellSize + 'px',
                        position: 'absolute',
                        left: targetPosition.col * cellSize + 'px',
                        top: targetPosition.row * cellSize + 'px',
                    }}
                >
                    {/* Afficher le badge avec le numéro d'ordre si mustRespectOrder est true */}
                    {mustRespectOrder ? (
                        <Badge count={targetPosition.order ?? index} offset={[-4, 4]} style={{ backgroundColor: '#52c41a' }}>
                            <div
                                style={{
                                    width: cellSize + 'px',
                                    height: cellSize + 'px',
                                    backgroundImage: `url(${targetPosition.img || 'defaultTargetImage.png'})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            />
                        </Badge>
                    ) : (
                        <div
                            style={{
                                width: cellSize + 'px',
                                height: cellSize + 'px',
                                backgroundImage: `url(${targetPosition.img || 'defaultTargetImage.png'})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default GameGrid;