import React from 'react';
import { Space, Switch } from 'antd';
import { TLesson, TLessonMetadata } from '../../../../models/courses/lesson';
import { TCodification } from '../../../../models/referential/referential';
import { stringToBool } from '../../../../utils/format/valueFormat';


interface SceneProps {
    params: TCodification[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const MetaData: React.FC<SceneProps> = ({ params, editingLesson, setEditingLesson }) => {


    const handleInputChange = (keyId: number, value: boolean) => {
        if (editingLesson) {
            // Création du nouvel objet metadata, avec conversion booléen -> string
            const newMetadata: TLessonMetadata = {
                id: 0, // Peut-être un nouvel élément à insérer en base
                lesson_id: editingLesson.id, // Récupère l'ID de la leçon en cours d'édition
                key_id: keyId, // Le key_id correspondant au champ en cours de modification
                value: String(value) // Conversion booléen -> string ("true" ou "false")
            };

            // Mise à jour de l'état 'editingLesson' en ajoutant ou remplaçant le metadata
            setEditingLesson({
                ...editingLesson, // Conserve les autres propriétés de la leçon en cours
                metadata: [
                    // On filtre les métadonnées pour supprimer celles ayant le même key_id
                    ...(editingLesson.metadata?.filter(item => item.key_id !== keyId) || []),
                    newMetadata // On ajoute ou remplace avec le nouveau metadata
                ]
            });
        }
    };


    return (
        <Space direction="vertical">
            {params.map((obj) => (
                <Switch
                    key={obj.id}
                    checked={stringToBool(editingLesson?.metadata?.find(e => e.key_id === obj.id)?.value)}
                    checkedChildren={obj.label} unCheckedChildren={obj.label}
                    onChange={checked => {
                        if (editingLesson) {
                            handleInputChange(obj.id, checked)
                        }
                    }}
                />
            ))}

        </Space>
    );
};

export default MetaData;
