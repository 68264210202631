import React from 'react';
import { message } from 'antd';
import BlocklyMouv from '../../../../blocks/v1/BlocklyMouv';
import { useTranslation } from 'react-i18next';
import { BlocklyFields } from '../../../../blocks/v1/config/fields';
import { FAIZAN } from '../../../../blocks/v1/constant/character';
import { TARGET, TARGET_BATTERY } from '../../../../blocks/v1/constant/target';
import { ZELIJA } from '../../../../blocks/v1/constant/scene';
import { VASE_2X2, VASE_4X2_HORIZONTAL } from '../../../../blocks/v1/constant/obstacles/vase';

interface LoopsOceanLevelTwoProps {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}

const LoopsOceanLevelTwo: React.FC<LoopsOceanLevelTwoProps> = ({ checkResult }) => {
    const { t } = useTranslation();

    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        }
    }
    return (
        <>
            <BlocklyMouv
                gridSize={{ side: 8 }}
                initialObjectPosition={{ row: 3, col: 1, scale: 1, rotate: 0, object: FAIZAN }}
                targetPositions={[
                    { row: 3, col: 2, order: 1, object: TARGET_BATTERY },
                    { row: 3, col: 3, order: 2, object: TARGET_BATTERY },
                    { row: 3, col: 4, order: 3, object: TARGET_BATTERY },
                    { row: 4, col: 4, order: 4, object: TARGET_BATTERY },
                    { row: 5, col: 4, order: 5, object: TARGET_BATTERY },
                    { row: 6, col: 4, order: 6, object: TARGET },
                ]}
                mustReachAllTargets={true}
                mustRespectOrder={true}
                toolbox={{
                    "contents": [
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_RIGHT,

                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_DOWN,
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_LEFT,
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_UP,
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.REPEAT,
                        }
                    ]
                }}
                initialBlocksJson={
                    [

                        {
                            type: BlocklyFields.STARTBOX,
                            x: 10, y: 10,
                            deletable: false,
                        }
                    ]
                }
                onTargetReached={isValid}
                autoTargetReaching={true}
                bg={ZELIJA}
                obstacles={[VASE_4X2_HORIZONTAL(2, 3), VASE_2X2(4, 2), VASE_2X2(1, 1), VASE_2X2(3, 5)]}

            />
        </>
    );
};

export default LoopsOceanLevelTwo;
