import React, { useState, useEffect, createContext, useContext } from 'react';
import { useReferentialApi } from '../api/referential/referential';
import { TCodification, TCountry } from '../models/referential/referential';

const ReferentialContext = createContext<{
    isReferentialLoaded: boolean;
    codifications: TCodification[];
    countries: TCountry[];
    getCodificationIdByCodes: (codeType: string, code: string) => number;
    getCodificationByCodes: (codeType: string, code: string) => TCodification | undefined;
    getCodificationById: (id: number) => TCodification | undefined;
    getCodificationsByCodes: (codeType: string, codes: string[]) => TCodification[];
    getCodificationCodeById: (id: number) => string;
    getCodificationsByCodeType: (codeType: string) => TCodification[];
}>({
    isReferentialLoaded: false,
    codifications: [],
    countries: [],
    getCodificationIdByCodes: () => -1,
    getCodificationByCodes: () => undefined,
    getCodificationById: () => undefined,
    getCodificationsByCodes: () => [],
    getCodificationCodeById: () => '',
    getCodificationsByCodeType: () => [],
});

export const useReferential = () => {
    return useContext(ReferentialContext);
};

export const ReferentialProvider = ({ children }: { children: React.ReactNode }) => {
    const { getCodifications, getCountries } = useReferentialApi();
    const [codifications, setCodifications] = useState<TCodification[]>([]);
    const [countries, setCountries] = useState<TCountry[]>([]);
    const [isReferentialLoaded, setIsReferentialLoaded] = useState<boolean>(false);

    useEffect(() => {
        const loadFromLocalStorage = () => {
            const storedCodifications = localStorage.getItem('codifications');
            const storedCountries = localStorage.getItem('countries');
            const storedDate = localStorage.getItem('referentialDate');
            const today = new Date().toISOString().split('T')[0];

            if (storedCodifications && storedCountries && storedDate === today) {
                setCodifications(JSON.parse(storedCodifications));
                setCountries(JSON.parse(storedCountries));
                setIsReferentialLoaded(true);
                return true;
            }
            return false;
        };

        const fetchData = async () => {
            try {
                const [codificationsResponse, countriesResponse] = await Promise.all([
                    getCodifications(),
                    getCountries(),
                ]);
                const codificationsData = codificationsResponse?.data || [];
                const countriesData = countriesResponse?.data || [];

                setCodifications(codificationsData);
                setCountries(countriesData);

                localStorage.setItem('codifications', JSON.stringify(codificationsData));
                localStorage.setItem('countries', JSON.stringify(countriesData));
                localStorage.setItem('referentialDate', new Date().toISOString().split('T')[0]);
                setIsReferentialLoaded(true);
            } catch (error) {
                console.error('Failed to fetch referential data:', error);
            }
        };

        if (!loadFromLocalStorage() || !localStorage.getItem('codifications')) {
            fetchData();
        }
        // eslint-disable-next-line
    }, []);

    const getCodificationIdByCodes = (codeType: string, code: string) => {
        const codification = codifications.find(
            (c) => c.codetype === codeType && c.code === code
        );
        return codification ? codification.id : -1;
    };
    const getCodificationByCodes = (codeType: string, code: string): TCodification | undefined => {
        const codification = codifications.find(
            (c) => c.codetype === codeType && c.code === code
        );
        return codification;
    };
    const getCodificationById = (id: number): TCodification | undefined => {
        const codification = codifications.find(
            (c) => c.id === id
        );
        return codification;
    };
    const getCodificationsByCodes = (codeType: string, codes: string[]): TCodification[] => {
        return codifications.filter((c) => c.codetype === codeType && codes.includes(c.code));
    };
    const getCodificationCodeById = (id: number) => {
        const codification = codifications.find(
            (c) => c.id === id
        );
        return codification ? codification.code : 'starting_box';
    };

    const getCodificationsByCodeType = (codeType: string): TCodification[] => {
        const codificationsfiltered = codifications.filter(
            (c) => c.codetype === codeType
        );
        return codificationsfiltered;
    };

    return (
        <ReferentialContext.Provider value={
            {
                isReferentialLoaded,
                codifications,
                countries,
                getCodificationIdByCodes,
                getCodificationById,
                getCodificationByCodes,
                getCodificationsByCodes,
                getCodificationsByCodeType,
                getCodificationCodeById
            }
        }
        >
            {children}
        </ReferentialContext.Provider>
    );
};