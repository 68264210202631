import React from 'react';
import { Modal, Button } from 'antd';

interface ConfirmationDialogProps {
    visible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    loading?: boolean;
    title?: string;
    description?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    visible,
    onConfirm,
    onCancel,
    loading = false,
    title = 'Are you sure?',
    description = 'Do you really want to delete this item? This action cannot be undone.',
}) => (
    <Modal
        title={title}
        open={visible}
        onOk={onConfirm}
        onCancel={onCancel}
        footer={[
            <Button key="cancel" onClick={onCancel}>
                Cancel
            </Button>,
            <Button key="confirm" type="primary" danger onClick={onConfirm} loading={loading}>
                Delete
            </Button>,
        ]}
    >
        <p>{description}</p>
    </Modal>
);

export default ConfirmationDialog;
