import { TLessonScene } from "../../models/courses/lesson";
import { TScene } from "../../blocks/core/type/scene";
import { getImagePath } from "../format/valueFormat";

export const TLessonSceneTOScene = (
    lessonScene: TLessonScene | undefined
): TScene => {
    if (!lessonScene) {
        return {
            width: 0,
            height: 0,
            background: {
                img: '',
                repeat: false,
                size: 'auto',
            },
            gridRowWidth: 0,
            gridColumnWidth: 0,
        };
    }

    return {
        width: lessonScene.width,
        height: lessonScene.height,
        background: {
            img: getImagePath(lessonScene.scene?.img || ''),
            repeat: lessonScene.repeat,
            size: lessonScene.size,
        },
        gridRowWidth: 50,
        gridColumnWidth: 50,
    };
};