import React, { useRef } from 'react';
import BlocklyGame from '../../../../blocks/core/component/BlocklyGame/BlocklyGame';
import BlocklyDraw from '../../../../blocks/core/component/BlocklyDraw/BlocklyDraw';
import { TLesson } from '../../../../models/courses/lesson';
import { TLessonCharactersTOTCharacters, TLessonCharacterTOTCharacter } from '../../../../utils/blockly/character';
import { TLessonObstaclesTOObstacles } from '../../../../utils/blockly/obstacle';
import { TLessonTargetsTOTTargets, TLessonTargetTOTTarget } from '../../../../utils/blockly/target';
import { TLessonSceneTOScene } from '../../../../utils/blockly/scene';
import { stringToBool } from '../../../../utils/format/valueFormat';
import { useReferential } from '../../../../context/ReferentialContext';
import { TLessonBlocklyContentTOBlocklyBlocks } from '../../../../utils/blockly/blocklyfields';
import { CODIFICATION_LESSON_METADATA, CODIFICATION_LESSON_TYPE, CODIFICATION_TYPE } from '../../../../constant/codification';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { TCodification } from '../../../../models/referential/referential';
import { TLessonLinesTOTLines } from '../../../../utils/blockly/line';
import BlocklyArcade from '../../../../blocks/core/component/BlocklyArcade/BlocklyArcade';

interface LessonBlocklyProps {
    lesson: TLesson | null;
    checkResult: (isValid: boolean) => void;
}

const LessonBlockly: React.FC<LessonBlocklyProps> = ({ lesson, checkResult }) => {
    const { getCodificationIdByCodes, getCodificationCodeById, getCodificationsByCodeType } = useReferential();
    const lessonTypes: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.LESSON_TYPE);
    const codificationType = lessonTypes.find(e => e.id === lesson?.lesson_type_id);

    const { t } = useTranslation();
    const xmlRef = useRef<string>(lesson?.metadata?.find(
        (e) => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX)
    )?.value || '');

    const isValid = (isValid: boolean) => {
        checkResult(isValid);
        if (isValid) {
            message.success(t("message.bravo"));
        }
    };

    return (
        <>
            {lesson ? (
                codificationType?.code === CODIFICATION_LESSON_TYPE.BLOCKLY_GAME ? (
                    <BlocklyGame
                        toolbox={{
                            contents: TLessonBlocklyContentTOBlocklyBlocks(lesson?.blocklycontent, getCodificationCodeById)
                        }}
                        initialBlocksJsonOrXML={lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX))?.value}
                        character={TLessonCharacterTOTCharacter(lesson?.characters?.[0], getCodificationIdByCodes)}
                        obstacles={TLessonObstaclesTOObstacles(lesson?.obstacles)}
                        targets={TLessonTargetsTOTTargets(lesson?.targets, getCodificationIdByCodes)}
                        scene={TLessonSceneTOScene(lesson?.scene)}
                        xmlRef={xmlRef}
                        showGrid={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.SHOW_GRID))?.value) || false}
                        drawResult={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.DRAW_RESULT))?.value) || false}
                        autoReset={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.AUTO_RESET))?.value) || false}
                        autoReach={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.AUTO_REACH))?.value) || false}
                        respectOrder={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.RESPECT_ORDER))?.value) || false}
                        mustReachAllTargets={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.REACH_ALL_TARGET))?.value) || false}
                        checkResult={isValid}
                    />
                ) : codificationType?.code === CODIFICATION_LESSON_TYPE.BLOCKLY_DRAW ? (
                    <BlocklyDraw
                        toolbox={{
                            "contents": TLessonBlocklyContentTOBlocklyBlocks(lesson?.blocklycontent, getCodificationCodeById)
                        }}
                        initialBlocksJsonOrXML={lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX))?.value}
                        character={TLessonCharacterTOTCharacter(lesson?.characters?.[0], getCodificationIdByCodes)}
                        targets={TLessonLinesTOTLines(lesson?.lines)}
                        scene={TLessonSceneTOScene(lesson?.scene)}
                        xmlRef={xmlRef}
                        autoReset={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.AUTO_RESET))?.value) || false}
                        canChangeXY={stringToBool(lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.CAN_CHANGE_XY))?.value) || false}
                        checkResult={isValid}
                    />
                ) : codificationType?.code === CODIFICATION_LESSON_TYPE.BLOCKLY_ARCADE ? (
                    <BlocklyArcade
                        toolbox={{
                            "contents": TLessonBlocklyContentTOBlocklyBlocks(lesson?.blocklycontent, getCodificationCodeById)
                        }}
                        initialBlocksJsonOrXML={lesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX))?.value}
                        characters={TLessonCharactersTOTCharacters(lesson?.characters, getCodificationIdByCodes)}
                        target={TLessonTargetTOTTarget(lesson?.targets?.[0], getCodificationIdByCodes)}
                        scene={TLessonSceneTOScene(lesson?.scene)}
                        xmlRef={xmlRef}
                        checkResult={isValid}
                        editionMode={false}
                    />
                ) : (
                    <p>{t('text.exercideUnavailable')}</p>
                )
            ) : (
                <p>{t('text.exercideUnavailable')}</p>
            )}
        </>
    );
};

export default React.memo(LessonBlockly);
