import React from 'react';
// import Editor from '@react-page/editor';
// import { Value } from '@react-page/editor';

// // Importation des plugins par défaut
// import slate from '@react-page/plugins-slate'; // Pour le texte riche
// import image from '@react-page/plugins-image'; // Pour les images
// import spacer from '@react-page/plugins-spacer'; // Pour les espaces
// import divider from '@react-page/plugins-divider'; // Pour les séparateurs
// import video from '@react-page/plugins-video'; // Pour les vidéos

// Importation des styles par défaut
// import '@react-page/editor/lib/index.css';
// import '@react-page/plugins-slate/lib/index.css';
// import '@react-page/plugins-image/lib/index.css';
// import '@react-page/plugins-spacer/lib/index.css';
// import '@react-page/plugins-divider/lib/index.css';
// import '@react-page/plugins-video/lib/index.css';

const WISIWYG = () => {
    // État pour stocker le contenu de l'éditeur

    // const [contenuEditeur, setContenuEditeur] = useState<Value | null>(null);

    // // Configuration des plugins
    // const pluginsCellule = [slate(), image, spacer, divider, video];

    // // Fonction pour gérer le clic sur le bouton "Enregistrer"
    // const gererEnregistrement = () => {
    //     // Ici, vous pouvez envoyer 'contenuEditeur' à votre backend
    //     console.log('Contenu à enregistrer:', contenuEditeur);
    //     // Plus tard, vous pourrez remplacer ce log par un appel API
    // };

    return (
        <div style={{ padding: '20px' }}>
            <h1>Créez votre page personnalisée</h1>
            {/* <Editor
                cellPlugins={pluginsCellule}
                value={contenuEditeur}
                onChange={setContenuEditeur}

            />
            <button onClick={gererEnregistrement} style={{ marginTop: '20px' }}>
                Enregistrer
            </button> */}
        </div>
    );
};

export default WISIWYG;
