// src/game/GameLogic.ts

import { useGameContext } from './GameContext';
import { TCharacter } from '../../type/character';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { roundToNearest } from '../../../../utils/gen/calcul';
import { TLine } from '../../type/line';
interface GameLogicProps {
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;
    autoReset: boolean;
    checkResult: (isValid: boolean) => void;
}

export const useGameLogic = ({
    animationPromiseResolverRef,
    autoReset,
    checkResult,
}: GameLogicProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useGameContext();
    const stateRef = useRef(state);
    const lineTypeRef = useRef<string>('solid');
    const lineColorRef = useRef<string>('#000000');

    let collisionMessageDisplayed = false;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);


    const walkCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        return moveCharacter(angle, character.displacementUnit, false);
    };

    const jumpCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        return moveCharacter(angle, character.displacementUnit, true);
    };


    // Fonction de vérification des collisions
    const checkCollision = (
        nextX: number,
        nextY: number,
        prevCharacterState: TCharacter
    ) => {
        const { scene } = stateRef.current;
        let isColliding = false;
        let adjustedX = nextX;
        let adjustedY = nextY;

        // Vérifier les collisions avec les limites de la scène
        if (nextX < 0) {
            adjustedX = 0;
            isColliding = true;
        } else if (nextX + prevCharacterState.width > scene.width) {
            adjustedX = scene.width - prevCharacterState.width;
            isColliding = true;
        }

        if (nextY < 0) {
            adjustedY = 0;
            isColliding = true;
        } else if (nextY + prevCharacterState.height > scene.height) {
            adjustedY = scene.height - prevCharacterState.height;
            isColliding = true;
        }

        adjustedX = roundToNearest(adjustedX);
        adjustedY = roundToNearest(adjustedY);
        return { isColliding, adjustedX, adjustedY };
    };

    const adjustDistanceForAngle = (angle: number, baseDistance: number): number => {
        // Normaliser l'angle entre 0 et 360 degrés
        const normalizedAngle = ((angle % 360) + 360) % 360;

        // Angles diagonaux (45°, 135°, 225°, 315°)
        const diagonalAngles = [45, 135, 225, 315];

        if (diagonalAngles.includes(normalizedAngle)) {
            // Multiplier la distance par √2 pour les déplacements diagonaux
            return baseDistance * Math.SQRT2;
        } else {
            // Garder la distance de base pour les autres angles
            return baseDistance;
        }
    };

    const moveCharacter = async (
        angle: number,
        distance: number,
        isJumping: boolean
    ): Promise<void> => {
        const { character, executionCancelled } = stateRef.current;

        if (executionCancelled) {
            return Promise.resolve();
        }

        const adjustedDistance = adjustDistanceForAngle(angle, distance);

        return new Promise<void>((resolve) => {

            const radians = (angle * Math.PI) / 180;
            const deltaX = adjustedDistance * Math.cos(radians);
            const deltaY = adjustedDistance * Math.sin(radians);

            // Calcul des positions suivantes
            const nextX = roundToNearest(character.x + deltaX);
            const nextY = roundToNearest(character.y + deltaY);

            const { isColliding, adjustedX, adjustedY } = checkCollision(
                nextX,
                nextY,
                character
            );

            const finalX = isColliding ? adjustedX : nextX;
            const finalY = isColliding ? adjustedY : nextY;



            if (isColliding) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'colliding' });
                dispatch({ type: 'SET_EXECUTION_CANCELLED', payload: true });

                if (!collisionMessageDisplayed) {
                    message.error(t('message.collisionOrOutOfBounds'));
                    collisionMessageDisplayed = true;
                }

                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                        collisionMessageDisplayed = false; // Réinitialiser le drapeau après le reset
                    }, 1000);
                }
                resolve();
            } else {
                const movementLine: TLine = {
                    x1: character.x,
                    y1: character.y,
                    x2: roundToNearest(finalX),
                    y2: roundToNearest(finalY),
                    type: lineTypeRef.current,
                    color: lineColorRef.current,
                };

                // animationPromiseResolverRef est géré dans IGame
                animationPromiseResolverRef.current = resolve;
                dispatch({
                    type: 'MOVE_CHARACTER',
                    payload: {
                        x: roundToNearest(finalX),
                        y: roundToNearest(finalY),
                        state: 'moving',
                        scale: deltaX >= 0 ? 1 : -1,
                        movementLine: !isJumping ? movementLine : undefined
                    },
                });
                // le resolve est dans l'animation IGame
            }
        });
    };

    const resetGame = () => {

        lineTypeRef.current = 'solid';
        lineColorRef.current = '#000000';
        dispatch({ type: 'RESET_GAME' });
    };

    const setLineType = async (type: string): Promise<void> => {
        lineTypeRef.current = type;
        return Promise.resolve();
    };

    const setLineColor = async (color: string): Promise<void> => {
        lineColorRef.current = color;
        return Promise.resolve();
    };

    const setSceneBackground = async (img: string): Promise<void> => {
        dispatch({ type: 'SET_SCENE_BACKGROUND', payload: { img, repeat: false, size: 'cover' } });
    };


    // Normaliser une ligne pour que x1 <= x2 ou, si x1 == x2, y1 <= y2
    const normalizeLine = (line: TLine): TLine => {
        if (
            line.x1 > line.x2 ||
            (line.x1 === line.x2 && line.y1 > line.y2)
        ) {
            return {
                ...line,
                x1: line.x2,
                y1: line.y2,
                x2: line.x1,
                y2: line.y1,
            };
        }
        return line;
    };

    // Vérifier si deux lignes sont colinéaires (même direction et même droite)
    const areLinesColinear = (line1: TLine, line2: TLine): boolean => {
        const dx1 = line1.x2 - line1.x1;
        const dy1 = line1.y2 - line1.y1;
        const dx2 = line2.x2 - line2.x1;
        const dy2 = line2.y2 - line2.y1;

        // Vérifier si les vecteurs directeurs sont proportionnels (parallélisme)
        if (dx1 * dy2 !== dx2 * dy1) {
            return false; // Pas parallèles, donc pas colinéaires
        }

        // Vérifier si les lignes sont sur la même droite
        const dx = line2.x1 - line1.x1;
        const dy = line2.y1 - line1.y1;

        if (dx1 * dy !== dy1 * dx) {
            return false; // Parallèles mais pas sur la même droite
        }

        return true; // Lignes colinéaires sur la même droite
    };

    // Vérifier si deux lignes se chevauchent ou sont connectées
    const doLinesOverlapOrConnect = (line1: TLine, line2: TLine): boolean => {
        // const points = [
        //     { x: line1.x1, y: line1.y1 },
        //     { x: line1.x2, y: line1.y2 },
        //     { x: line2.x1, y: line2.y1 },
        //     { x: line2.x2, y: line2.y2 },
        // ];

        // Projeter les points sur une dimension en fonction de la direction de la ligne
        // const projection = points.map(p => {
        //     return line1.x1 !== line1.x2
        //         ? p.x
        //         : p.y; // Si ligne verticale, utiliser y
        // });

        // const min = Math.min(...projection);
        // const max = Math.max(...projection);

        const line1Min = Math.min(
            line1.x1 !== line1.x2 ? line1.x1 : line1.y1,
            line1.x1 !== line1.x2 ? line1.x2 : line1.y2
        );
        const line1Max = Math.max(
            line1.x1 !== line1.x2 ? line1.x1 : line1.y1,
            line1.x1 !== line1.x2 ? line1.x2 : line1.y2
        );

        const line2Min = Math.min(
            line2.x1 !== line2.x2 ? line2.x1 : line2.y1,
            line2.x1 !== line2.x2 ? line2.x2 : line2.y2
        );
        const line2Max = Math.max(
            line2.x1 !== line2.x2 ? line2.x1 : line2.y1,
            line2.x1 !== line2.x2 ? line2.x2 : line2.y2
        );

        return Math.max(line1Min, line2Min) <= Math.min(line1Max, line2Max);
    };

    // Fusionner les lignes continues
    const mergeLines = (lines: TLine[]): TLine[] => {
        let normalizedLines = lines.map(normalizeLine);
        let didMerge = true;

        while (didMerge) {
            didMerge = false;
            const newLines: TLine[] = [];

            while (normalizedLines.length > 0) {
                const line = normalizedLines.shift()!;
                let mergedLine = { ...line };

                let i = 0;
                while (i < normalizedLines.length) {
                    const otherLine = normalizedLines[i];
                    if (
                        areLinesColinear(mergedLine, otherLine) &&
                        doLinesOverlapOrConnect(mergedLine, otherLine)
                    ) {
                        // Fusionner les lignes
                        mergedLine.x1 = Math.min(mergedLine.x1, otherLine.x1);
                        mergedLine.y1 = Math.min(mergedLine.y1, otherLine.y1);
                        mergedLine.x2 = Math.max(mergedLine.x2, otherLine.x2);
                        mergedLine.y2 = Math.max(mergedLine.y2, otherLine.y2);
                        // Renormaliser la ligne fusionnée
                        mergedLine = normalizeLine(mergedLine);
                        // Retirer otherLine de normalizedLines
                        normalizedLines.splice(i, 1);
                        didMerge = true;
                    } else {
                        i++;
                    }
                }

                newLines.push(mergedLine);
            }

            normalizedLines = newLines;
        }

        return normalizedLines;
    };

    // Convertir une ligne en chaîne pour l'utiliser dans un ensemble
    const lineToString = (line: TLine): string => {
        const points = [
            { x: line.x1, y: line.y1 },
            { x: line.x2, y: line.y2 },
        ].sort((a, b) => {
            if (a.x !== b.x) {
                return a.x - b.x;
            } else {
                return a.y - b.y;
            }
        });
        return `${points[0].x},${points[0].y},${points[1].x},${points[1].y}`;
    };

    // Comparer deux collections de lignes
    const areCollectionsEquivalent = (collectionA: TLine[], collectionB: TLine[]): boolean => {
        const mergedA = mergeLines(collectionA);
        const mergedB = mergeLines(collectionB);

        // Vérifier si les deux collections fusionnées ont le même nombre de lignes
        if (mergedA.length !== mergedB.length) {
            // 'Nombre de lignes différent après fusion.'
            return false;
        }

        // Créer des ensembles pour stocker les lignes sous forme de chaînes pour une comparaison facile
        const setA = new Set(mergedA.map(lineToString));
        const setB = new Set(mergedB.map(lineToString));

        // Vérifier si les ensembles sont identiques
        if (setA.size !== setB.size) {
            // Les ensembles de lignes ont des tailles différentes.
            return false;
        }

        for (const item of setA) {
            if (!setB.has(item)) {
                // `La ligne ${item} n'est pas présente dans les deux collections.`
                return false;
            }
        }

        return true;
    };


    const isDone = async () => {
        // Attendre que l'état soit mis à jour
        await new Promise<void>((resolve) => setTimeout(resolve, 0));


        const { targetLines } = stateRef.current;
        if (state.initialTargetLines.length > 0) {
            let success = areCollectionsEquivalent(targetLines, state.initialTargetLines);

            checkResult(success);
            if (success) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'wining' });
            } else {
                message.warning(t('message.bug'));
                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                    }, 500);
                }
            }
        } else {
            checkResult(true);
        }
    };

    return {
        walkCharacter,
        jumpCharacter,
        moveCharacter,
        resetGame,
        setSceneBackground,
        isDone,
        setLineType,
        setLineColor,
    };
};
