import React, { useState } from 'react';
import { Layout } from 'antd';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import LoadingBox from '../loading/LoadingBox';

interface AppLayoutProps {
    children: React.ReactNode;
    title: string;
    loading?: boolean;
    footer?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ title, children, footer, loading = false }) => {
    // Lire l'état initial du localStorage ou définir comme non rétracté (false)
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        const savedState = localStorage.getItem('isSidebarCollapsed');
        return savedState === 'true';
    });

    // Fonction pour basculer la largeur du Sidebar
    const toggleSidebar = () => {
        const newCollapsedState = !isSidebarCollapsed;
        setIsSidebarCollapsed(newCollapsedState);
        localStorage.setItem('isSidebarCollapsed', String(newCollapsedState));
    };

    return (
        <Layout style={{ minHeight: '100vh', display: 'flex' }}>
            {/* Passer l'état et la fonction de bascule au Sidebar */}
            <Sidebar isCollapsed={isSidebarCollapsed} />

            <Layout
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    marginLeft: isSidebarCollapsed ? '80px' : '250px', // Ajuster la marge selon l'état du sidebar
                    transition: 'margin-left 0.3s ease',  // Transition douce lors du changement de largeur
                }}
            >
                {/* Passer la fonction de bascule au Navbar */}
                <Navbar title={title} onToggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed} />

                <Layout.Content style={{ flexGrow: 1, padding: '24px', background: '#f0f2f5' }}>
                    {loading ? (
                        < LoadingBox />
                    ) : (
                        children
                    )}
                </Layout.Content>

                {footer && (
                    <Layout.Footer style={{ background: '#fff', padding: '16px 24px' }}>
                        {footer}
                    </Layout.Footer>
                )}
            </Layout>
        </Layout>
    );
};

export default AppLayout;
