// pages/Program.tsx
import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/layout/AppLayout';
import { Row, Col, message } from 'antd';
import SubjectCard from '../../components/cards/SubjectCard';
import { useTranslation } from 'react-i18next';
import { TSubject } from '../../models/courses/course';
import { useCourseApi } from '../../api/courses/courses';



const Program: React.FC = () => {
    const { t } = useTranslation();
    const { getSubjectsAndProgress } = useCourseApi();
    const [subjects, setSubjects] = useState<TSubject[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        getSubjectsAndProgress()
            .then((resp) => {
                setSubjects(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message)
            })
            .finally(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line
    }, [])

    return (
        <AppLayout title={t('text.availableSubject')} loading={loading}>
            <Row gutter={[16, 16]}>
                {(subjects || []).map((subject, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6}>
                        <SubjectCard subject={subject} />
                    </Col>
                ))}
            </Row>
        </AppLayout>
    );
};

export default Program;
