// useBlocklyWorkspace.ts
import { useState, useEffect } from 'react';
import { CheckNextCondition } from './config/blocklyUtils';
import * as Blockly from 'blockly';

export const useBlocklyWorkspace = (workspace: Blockly.WorkspaceSvg | null) => {
    const [isRunButtonEnabled, setIsRunButtonEnabled] = useState(false);

    const allBlocksUsed = (): boolean => {
        if (!workspace) return false;

        const allBlocks = workspace.getAllBlocks(true);
        if (allBlocks.length === 1) return false;

        return CheckNextCondition(allBlocks, []); // Appeler CheckNextCondition sur les blocs de niveau supérieur uniquement
    };

    const workspaceChanged = () => {
        setIsRunButtonEnabled(allBlocksUsed());
    };

    useEffect(() => {
        if (workspace) {
            setIsRunButtonEnabled(allBlocksUsed());
            workspace.addChangeListener(workspaceChanged);
        }
        return () => {
            if (workspace) workspace.removeChangeListener(workspaceChanged);
        };

        // eslint-disable-next-line 
    }, [workspace]);

    return { isRunButtonEnabled };
};
