import React, { useEffect, useRef, useState } from 'react'
import AppLayout from '../../../../components/layout/AppLayout'
import { useLocation, useNavigate } from 'react-router-dom';
import { useObjectsApi } from '../../../../api/objects/objects';
import { TLesson, TLessonMetadata } from '../../../../models/courses/lesson';
import { Card, Col, Collapse, CollapseProps, message, Row } from 'antd';
import { TCharacter, TObstacle, TScene, TTarget } from '../../../../models/objects/objects';
import { useCourseApi } from '../../../../api/courses/courses';
import Scene from '../lessonBlockly/Scene';
import Target from '../lessonBlockly/Target';
import Obstacle from '../lessonBlockly/Obstacle';
import { TLessonCharactersTOTCharacters } from '../../../../utils/blockly/character';
import { useReferential } from '../../../../context/ReferentialContext';
import { TLessonTargetTOTTarget } from '../../../../utils/blockly/target';
import { TLessonSceneTOScene } from '../../../../utils/blockly/scene';
import BlocklyContent from '../lessonBlockly/BlocklyContent';
import { CODIFICATION_LESSON_METADATA, CODIFICATION_TYPE } from '../../../../constant/codification';
import { TLessonBlocklyContentTOBlocklyBlocks } from '../../../../utils/blockly/blocklyfields';
import MetaData from '../lessonBlockly/MetaData';
import FloatButtons from '../../../../components/button/FloatButtons';
import BlocklyArcade from '../../../../blocks/core/component/BlocklyArcade/BlocklyArcade';
import MultiCharacter from '../lessonBlockly/MultiCharacter';



const LessonBlocklyArcade: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getCharacters, getObstacles, getScenes, getTargets } = useObjectsApi();
    const { getLessonDetailsById, saveLessonDetails } = useCourseApi();
    const { getCodificationIdByCodes, getCodificationsByCodeType, getCodificationCodeById } = useReferential();

    const [loading, setIsLoading] = useState<boolean>(false);

    const [characters, setCharacters] = useState<TCharacter[]>([]);

    const [obstacles, setObstacles] = useState<TObstacle[]>([]);

    const [scenes, setScenes] = useState<TScene[]>([]);

    const [targets, setTargets] = useState<TTarget[]>([]);

    const id_key_XMLToolBoxParam: number = getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX)


    // We assume that the epic object is passed via location.state from another component
    const lesson: TLesson = location.state?.lesson;
    const [editingLesson, setEditingLesson] = useState<TLesson | undefined>(lesson);

    useEffect(() => {
        setIsLoading(true);

        if (lesson) {
            Promise.all([
                getCharacters(),
                getObstacles(),
                getScenes(),
                getTargets(),
                getLessonDetailsById(lesson.id)
            ])
                .then((results) => {
                    const [
                        charactersData,
                        obstaclesData,
                        scenesData,
                        targetsData,
                        lessonData
                    ] = results;

                    setCharacters(charactersData.data || []);
                    setObstacles(obstaclesData.data || []);
                    setScenes(scenesData.data || []);
                    setTargets(targetsData.data || []);
                    setEditingLesson(lessonData.data);
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [lesson]);

    const xmlRef = useRef<string>('');

    const save = async (): Promise<void> => {
        if (editingLesson) {
            const codificationXML: TLessonMetadata = {
                id: 0,
                lesson_id: editingLesson.id,
                key_id: id_key_XMLToolBoxParam,
                value: xmlRef.current
            };

            // Mise à jour ou ajout du metadata
            let data: TLesson = {
                ...editingLesson,
                metadata: [
                    ...(editingLesson?.metadata?.filter(item => item.key_id !== id_key_XMLToolBoxParam) || []),
                    codificationXML
                ]
            };

            try {
                await saveLessonDetails(data);  // Attendre la fin de la promesse
                message.success("Lesson saved successfully");
                navigate(-1);  // Redirection après succès
            } catch (err: any) {
                message.error(err.response?.data ?? err.message);  // Gestion des erreurs
            }
        }
    };





    // Préparer les items pour Collapse 
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Scene',
            children:
                <Scene
                    scenes={scenes}
                    editingLesson={editingLesson}
                    setEditingLesson={setEditingLesson}
                />,
        },
        {
            key: '2',
            label: 'Characters',
            children:
                <MultiCharacter
                    characters={characters}
                    editingLesson={editingLesson}
                    setEditingLesson={setEditingLesson}
                />,
        },
        {
            key: '3',
            label: 'Target',
            children:
                <Target
                    targets={targets}
                    editingLesson={editingLesson}
                    setEditingLesson={setEditingLesson}

                />,
        },
        {
            key: '4',
            label: 'Blocly fields',
            children:
                <BlocklyContent
                    blocklyContentFields={getCodificationsByCodeType(CODIFICATION_TYPE.BLOCKLY_CONTENT)}
                    editingLesson={editingLesson}
                    setEditingLesson={setEditingLesson}

                />,
        },
    ];

    return (
        <AppLayout title={lesson.name} loading={loading}>
            <Row style={{ padding: '20px' }} gutter={[16, 16]}>
                <Col span={24}>
                    <Card >
                        <Collapse items={items} defaultActiveKey={['1']} />;
                    </Card>
                </Col>

                <Col span={24}>
                    <Card>
                        <BlocklyArcade
                            toolbox={{
                                "contents": TLessonBlocklyContentTOBlocklyBlocks(editingLesson?.blocklycontent, getCodificationCodeById)
                            }}
                            initialBlocksJsonOrXML={editingLesson?.metadata?.find(e => e.key_id === getCodificationIdByCodes(CODIFICATION_TYPE.LESSON_METADATA, CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX))?.value}
                            characters={TLessonCharactersTOTCharacters(editingLesson?.characters, getCodificationIdByCodes)}
                            // obstacles={TLessonObstaclesTOObstacles(editingLesson?.obstacles)}
                            target={TLessonTargetTOTTarget(editingLesson?.targets?.[0], getCodificationIdByCodes)}
                            scene={TLessonSceneTOScene(editingLesson?.scene)}
                            xmlRef={xmlRef}
                            checkResult={(isValid: boolean) => { isValid && message.info('good') }}
                            cansave={true}
                            editionMode={true}
                        />
                    </Card>
                </Col>
            </Row>
            <FloatButtons save={save} />

        </AppLayout>
    )
}

export default LessonBlocklyArcade;
