import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import i18next from 'i18next';
import { TCharacter } from '../../type/character';


export function initMoveChosenCharacterBlock(characters: TCharacter[]) {
    // Créer des options pour le dropdown des personnages, avec leurs images 'idle'
    const characterOptions = characters
        .filter((character) => character.canbeplayedwith === true)
        .map((character) => [
            {
                src: character.images.idle,
                width: 36,
                height: 36,
                alt: character.name,
            },
            character.id.toString(),
        ]);
    // Tableau des directions possibles avec des mots pour chaque direction
    const directions = [
        [i18next.t('blockly.move.right.message'), 'RIGHT'],
        [i18next.t('blockly.move.left.message'), 'LEFT'],
        [i18next.t('blockly.move.up.message'), 'UP'],
        [i18next.t('blockly.move.down.message'), 'DOWN'],
        [i18next.t('blockly.move.random.message'), 'RANDOM'],
    ];

    Blockly.Blocks[BlocklyFields.MOVE_CHOSEN_CARACTER] = {
        init: function () {
            this.jsonInit({
                type: BlocklyFields.MOVE_CHOSEN_CARACTER,
                message0: '%1 va vers %2', // Affiche le choix du personnage et la direction
                args0: [
                    {
                        type: 'field_dropdown',
                        name: 'CHARACTER',
                        options: characterOptions, // Affiche les images pour les personnages
                    },
                    {
                        type: 'field_dropdown',
                        name: 'DIRECTION',
                        options: directions, // Affiche les mots pour les directions
                    },
                ],
                previousStatement: null,
                nextStatement: null,
                colour: '#4C97FF',
                tooltip: i18next.t('blockly.move.choosecharacter', ''),
                helpUrl: '',
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.MOVE_CHOSEN_CARACTER] = function (block) {
        const characterId = block.getFieldValue('CHARACTER');
        const direction = block.getFieldValue('DIRECTION');
        let angle;

        switch (direction) {
            case 'RIGHT':
                angle = '0';
                break;
            case 'LEFT':
                angle = '180';
                break;
            case 'UP':
                angle = '270';
                break;
            case 'DOWN':
                angle = '90';
                break;
            case 'RANDOM':
                angle = '-1';
                break;
            default:
                angle = '0';
                break;
        }

        return `await walkCharacter(${characterId}, ${angle});\n`;
    };
}
