import { BlocklyBlock } from "../../blocks/core/type/blocks";
import { TLessonBlocklyContent } from "../../models/courses/lesson";

export const TLessonBlocklyContentTOBlocklyBlocks = (content: TLessonBlocklyContent[] | undefined, getCodificationCodeById: (id: number) => string): BlocklyBlock[] => {
    if (!content) return [];
    // content.map(block => ({
    //     kind: "block",  // Tous les blocs ont "kind" égal à "block"
    //     deletable: block.deletable,
    //     type: getCodificationCodeById(block.blocklyfield_id),
    //     ...(block.max_usage > 0 && { maxUsage: block.max_usage }),
    // }))

    return content.map(block => ({
        kind: "block",  // Tous les blocs ont "kind" égal à "block"
        deletable: block.deletable,
        type: getCodificationCodeById(block.blocklyfield_id),
        ...(block.max_usage > 0 && { maxUsage: block.max_usage }),
    }));
};
