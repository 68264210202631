import React, { useState } from 'react';
import { Input, Space, Button, Table } from 'antd';
import { TLesson, TLessonLine } from '../../../../models/courses/lesson';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

interface LinesProps {
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const Lines: React.FC<LinesProps> = ({ editingLesson, setEditingLesson }) => {

    const [addingId, setAddingId] = useState<number>(0);
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingLine, setEditingLine] = useState<TLessonLine | null>(null);

    // Fonction pour passer en mode édition
    const edit = (line: TLessonLine) => {
        setEditingKey(line.id);
        setEditingLine({ ...line });
    };

    // Fonction pour gérer l'ajout d'une nouvelle ligne
    const handleAddLine = () => {
        const idRow = addingId - 1;
        const newLine: TLessonLine = {
            id: idRow,
            lesson_id: editingLesson?.id || 0,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingLine(newLine);

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                lines: [
                    ...(prevLesson.lines || []),
                    newLine,
                ]
            };
        });
    };

    // Sauvegarde les modifications
    const save = (id: number) => {
        if (!editingLine) {
            return;
        }

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;
            const newLines = prevLesson.lines?.map((line) => {
                if (line.id === id) {
                    return { ...editingLine, id };
                }
                return line;
            });

            return {
                ...prevLesson,
                lines: newLines,
            };
        });

        setEditingKey(null);
        setEditingLine(null);
    };

    const handleDelete = (id: number) => {
        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                lines: prevLesson.lines?.filter((line) => line.id !== id),
            };
        });
    };

    const handleInputChange = (field: string, value: string) => {
        setEditingLine((prev) => {
            if (!prev) return prev;
            return { ...prev, [field]: Number(value) };
        });
    };

    const columns = [
        {
            title: 'X1',
            dataIndex: 'x1',
            key: 'x1',
            render: (text: number, record: TLessonLine) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLine?.x1}
                        onChange={(e) => handleInputChange('x1', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Y1',
            dataIndex: 'y1',
            key: 'y1',
            render: (text: number, record: TLessonLine) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLine?.y1}
                        onChange={(e) => handleInputChange('y1', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'X2',
            dataIndex: 'x2',
            key: 'x2',
            render: (text: number, record: TLessonLine) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLine?.x2}
                        onChange={(e) => handleInputChange('x2', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Y2',
            dataIndex: 'y2',
            key: 'y2',
            render: (text: number, record: TLessonLine) =>
                editingKey === record.id ? (
                    <Input
                        value={editingLine?.y2}
                        onChange={(e) => handleInputChange('y2', e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: TLessonLine) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <Button
                            icon={<SaveOutlined />}
                            onClick={() => save(record.id)}
                        />
                    ) : (
                        <>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => edit(record)}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(record.id)}
                            />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Button type="primary" onClick={handleAddLine} disabled={editingKey !== null}>
                Add Line
            </Button>
            <Table
                dataSource={editingLesson?.lines}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </Space>
    );
};

export default Lines;
