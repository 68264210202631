import React from 'react';
import { Card, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TSubject } from '../../models/courses/course';
import { getImagePath } from '../../utils/format/valueFormat';
import ProgressCircle from '../chart/ProgressCircle';

const { Meta } = Card;

interface SubjectCardProps {
    subject: TSubject;
}

const SubjectCard: React.FC<SubjectCardProps> = ({ subject }) => {
    const navigate = useNavigate();

    const goToDetails = () => {
        navigate(`/subject-courses/${subject.id}`);
    };

    return (
        <Card
            hoverable
            style={{
                width: '100%',
                maxWidth: '90%',
                border: 'none',
                overflow: 'hidden',
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
            }}
            cover={
                <img
                    alt={subject.title}
                    src={getImagePath(subject.image)}
                    style={{ height: '200px', objectFit: 'cover' }}
                />
            }
            onClick={goToDetails}
        >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Meta
                    avatar={
                        <ProgressCircle
                            percent={Math.round(subject.progression_state ?? 0)}
                            size={50}
                            animate={false}
                            strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                        />
                    }
                    title={
                        <Tooltip title={subject.title}>
                            <div
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {subject.title}
                            </div>
                        </Tooltip>
                    }
                    description={
                        <Tooltip title={subject.description}>
                            <div
                                style={{
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 5, // Limiter à 3 lignes
                                    WebkitBoxOrient: 'vertical',
                                    textOverflow: 'ellipsis',
                                    flex: 1,
                                }}
                            >
                                {subject.description}
                            </div>
                        </Tooltip>
                    }
                />

            </div>
        </Card>
    );
};

export default SubjectCard;
