// src/blocks/style/changeLineType.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import i18next from 'i18next';
import { BlocklyFields } from '../BlocklyFields';

export function initChangeLineTypeBlock() {

    Blockly.Blocks[BlocklyFields.CHANGE_LINE_TYPE] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.CHANGE_LINE_TYPE,
                "message0": '%1 ' + i18next.t('blockly.style.changeLineType.message', 'Change line type'),
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "LINE_TYPE",
                        "options": [
                            [
                                {
                                    "src": "/images/lines/solid.png", // Image de la ligne solide
                                    "width": 32,
                                    "height": 10,
                                    "alt": "Solid Line"
                                },
                                "solid"
                            ],
                            [
                                {
                                    "src": "/images/lines/dashed.png", // Image de la ligne en pointillés
                                    "width": 32,
                                    "height": 10,
                                    "alt": "Dashed Line"
                                },
                                "dashed"
                            ],
                            [
                                {
                                    "src": "/images/lines/dotted.png", // Image de la ligne en petits points
                                    "width": 32,
                                    "height": 10,
                                    "alt": "Dotted Line"
                                },
                                "dotted"
                            ],
                            [
                                {
                                    "src": "/images/lines/dashdot.png", // Image de la ligne en motif dash-dot
                                    "width": 32,
                                    "height": 10,
                                    "alt": "Dash Dot Line"
                                },
                                "dashdot"
                            ],
                        ],
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#4A90E2',
                "tooltip": i18next.t('blockly.style.changeLineType.tooltip', 'Select a line type for customization'),
                "helpUrl": ""
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.CHANGE_LINE_TYPE] = function (block, generator) {
        // Obtenir la valeur sélectionnée dans le champ dropdown
        const lineType = block.getFieldValue('LINE_TYPE');

        // Générer le code JavaScript correspondant
        switch (lineType) {
            case 'solid':
                return 'setLineType("");\n';
            case 'dashed':
                return 'setLineType("15, 10");\n';
            case 'dotted':
                return 'setLineType("2, 8");\n';
            case 'dashdot':
                return 'setLineType("12, 5, 2, 5");\n';
            default:
                return 'setLineType("");\n'; // Par défaut, on retourne "solid"
        }
    }
}
