

export function downloadCSV(data: string, filename: string) {
    // Ajouter un BOM pour forcer l'encodage UTF-8
    const bom = '\uFEFF';
    const blob = new Blob([bom + data], { type: 'text/csv;charset=utf-8;' });

    // Créer le lien pour le téléchargement
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    }
}