import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { TChapter, TEpic } from '../../../models/courses/course';
import { Button, Input, message, Space, Table } from 'antd';
import { SaveOutlined, EyeOutlined, EditOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCourseApi } from '../../../api/courses/courses';
import type { ColumnsType } from 'antd/es/table';
import FloatButtons from '../../../components/button/FloatButtons';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';
import { CODIFICATION_ROLES } from '../../../constant/codification';
import { useAuth } from '../../../context/AuthContext';

const EpicList: React.FC = () => {
    const { t } = useTranslation();
    const { isRoleExist } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { getEpicsByChapterId, updateEpic, createEpic, deleteEpic } = useCourseApi();
    const [epics, setEpics] = useState<TEpic[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingEpic, setEditingEpic] = useState<TEpic | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

    const chapter: TChapter = location.state?.chapter;

    useEffect(() => {
        if (!chapter) {
            message.error('No chapter selected');
            return;
        }

        setIsLoading(true);
        getEpicsByChapterId(chapter.id)
            .then((resp) => {
                setEpics(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [chapter]);

    const edit = (epic: TEpic) => {
        setEditingKey(epic.id);
        setEditingEpic({ ...epic });
    };

    const handleAddEpic = () => {
        const idRow = addingId - 1;
        const newEpic: TEpic = {
            id: idRow,
            name: '',
            chapter_id: chapter.id,
            epic_order: 0,
            lessons: []
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingEpic(newEpic);
        setEpics((prev) => [newEpic, ...(prev || [])]);
    };

    const save = (id: number) => {
        if (editingEpic) {
            (editingEpic.id > 0 ? updateEpic(editingEpic.id, editingEpic) : createEpic(editingEpic))
                .then((resp) => {
                    const epicResult: TEpic = resp.data;
                    const updatedEpics = epics.map((epic) =>
                        epic.id === id ? epicResult : epic
                    );
                    setEpics(updatedEpics);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        setEditingKey(null);
    };

    const showDetails = (epic: TEpic) => {
        navigate('/setup/lesson', { state: { epic } });
    };

    const cancel = () => {
        setEditingKey(null);
        setEditingEpic(null);
    };

    // Delete functionality
    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteEpic(deleteId)
            .then(() => {
                setEpics(epics.filter((epic) => epic.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };

    const columns: ColumnsType<TEpic> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TEpic) =>
                editingKey === record.id ? (
                    <Input
                        value={editingEpic?.name}
                        onChange={(e) => {
                            if (editingEpic) {
                                setEditingEpic({ ...editingEpic, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Ordre',
            dataIndex: 'epic_order',
            key: 'epic_order',
            width: 90,
            render: (text: number, record: TEpic) =>
                editingKey === record.id ? (
                    <Input
                        type="number"
                        value={editingEpic?.epic_order}
                        onChange={(e) => {
                            if (editingEpic) {
                                setEditingEpic({ ...editingEpic, epic_order: parseInt(e.target.value) });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Action',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 220,
            render: (_: any, record: TEpic) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />

                            {
                                isRoleExist(CODIFICATION_ROLES.DELETE_COURSE) && (
                                    <Button
                                        icon={<DeleteOutlined />}
                                        danger
                                        onClick={() => confirmDelete(record.id)}
                                        loading={deleteId === record.id && deleteLoading}
                                    />
                                )
                            }
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={`Chapter : ${chapter?.name}`} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddEpic}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TEpic>
                columns={columns}
                dataSource={epics}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 500 }}
            />

            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this epic? This action is irreversible."
            />

            <FloatButtons />
        </AppLayout>
    );
};

export default EpicList;
