import React, { RefObject } from 'react';
import { Row, Col, Button } from 'antd';
import { UpOutlined, LeftOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';

interface CommandControlsProps {
    onUpArrowPressedFunc: (() => void) | null;
    onLeftArrowPressedFunc: (() => void) | null;
    onDownArrowPressedFunc: (() => void) | null;
    onRightArrowPressedFunc: (() => void) | null;
    upArrowButtonRef: RefObject<HTMLButtonElement>;
    leftArrowButtonRef: RefObject<HTMLButtonElement>;
    downArrowButtonRef: RefObject<HTMLButtonElement>;
    rightArrowButtonRef: RefObject<HTMLButtonElement>;
    executeMode: boolean;
}

const CommandControls: React.FC<CommandControlsProps> = ({
    onUpArrowPressedFunc,
    onLeftArrowPressedFunc,
    onDownArrowPressedFunc,
    onRightArrowPressedFunc,
    upArrowButtonRef,
    leftArrowButtonRef,
    downArrowButtonRef,
    rightArrowButtonRef,
    executeMode
}) => (
    <div
        style={{
            backgroundColor: '#f0f0f0',
            padding: '16px',
            borderRadius: '8px',
            display: 'inline-block',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
    >
        {upArrowButtonRef && (
            <Row justify="center">
                <Col>
                    <Button
                        ref={upArrowButtonRef}
                        icon={<UpOutlined />}
                        shape="circle"
                        style={{ width: '50px', height: '50px', margin: '4px' }}
                        onClick={() => (onUpArrowPressedFunc && onUpArrowPressedFunc())}
                        disabled={!executeMode}
                    />
                </Col>
            </Row>
        )}
        <Row justify="center">
            {
                leftArrowButtonRef && (
                    <Col>
                        <Button
                            ref={leftArrowButtonRef}
                            icon={<LeftOutlined />}
                            shape="circle"
                            style={{ width: '50px', height: '50px', margin: '4px' }}
                            onClick={() => (onLeftArrowPressedFunc && onLeftArrowPressedFunc())}
                            disabled={!executeMode}
                        />
                    </Col>
                )
            }
            {
                downArrowButtonRef && (
                    <Col>
                        <Button
                            ref={downArrowButtonRef}
                            icon={<DownOutlined />}
                            shape="circle"
                            style={{ width: '50px', height: '50px', margin: '4px' }}
                            onClick={() => (onDownArrowPressedFunc && onDownArrowPressedFunc())}
                            disabled={!executeMode}
                        />
                    </Col>
                )
            }
            {
                rightArrowButtonRef && (
                    <Col>
                        <Button
                            ref={rightArrowButtonRef}
                            icon={<RightOutlined />}
                            shape="circle"
                            style={{ width: '50px', height: '50px', margin: '4px' }}
                            onClick={() => (onRightArrowPressedFunc && onRightArrowPressedFunc())}
                            disabled={!executeMode}
                        />
                    </Col>
                )
            }
        </Row>
    </div>
);

export default CommandControls;
