import React from 'react'
import BlocklyGame from '../../../../blocks/core/component/BlocklyGame/BlocklyGame'
import { TCharacter } from '../../../../blocks/core/type/character';
import { getSceneZelija, TScene } from '../../../../blocks/core/type/scene';
import { BlocklyFields } from '../../../../blocks/core/config/BlocklyFields';
import { createBlocklyBlocks } from '../../../../blocks/core/config/utils';
import { TTarget, createTarget } from '../../../../blocks/core/type/target';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getFaizan } from '../../../../blocks/character/faizan';
import { createObstacle, TObstacle } from '../../../../blocks/core/type/obstacle';
import imgBattery from '../../../../assets/images/target/target_battery.png'
import vase from '../../../../assets/images/obstacle/vase/vase_round.png'
import vase_rec from '../../../../assets/images/obstacle/vase/vase_rectangle.png'

interface FCL3Props {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}


const FCL3: React.FC<FCL3Props> = ({ checkResult }) => {
    const { t } = useTranslation();

    // character
    const character: TCharacter = getFaizan(1, 50, 200);

    // scene
    const scene: TScene = getSceneZelija(400, 400);

    // targets
    let order = 1;
    const targets: TTarget[] = [
        createTarget(250, 200, 50, 50, imgBattery, { order: order++ }),
        createTarget(150, 200, 50, 50, imgBattery, { order: order++ }),
    ]

    // obstacles
    const obstacles: TObstacle[] = [
        createObstacle(250, 250, 100, 100, vase),
        createObstacle(0, 0, 100, 200, vase_rec),
        createObstacle(150, 100, 100, 100, vase),

    ]


    // validation func
    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        } else {
            message.warning(t("message.bug"));
        }
    }
    const initialBlocksJson = [

        {
            type: BlocklyFields.STARTBOX,
            x: 10, y: 10,
            deletable: false,
        }
    ]
    return (
        <>
            <BlocklyGame
                checkResult={isValid}
                character={character}
                scene={scene}
                respectOrder={true}
                targets={targets}
                obstacles={obstacles}
                toolbox={{
                    "contents": createBlocklyBlocks([
                        { type: BlocklyFields.REPEAT },
                        { type: BlocklyFields.MOVE_RIGHT },
                        { type: BlocklyFields.MOVE_LEFT },
                        { type: BlocklyFields.JUMP_RIGHT },
                    ])
                }}
                initialBlocksJsonOrXML={JSON.stringify(initialBlocksJson)}

            />
        </>
    )
}

export default React.memo(FCL3);

