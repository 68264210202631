import { Target } from '../type/target';
import targetOnStill from '../../../assets/images/target/target_charger.png'
import targetOnReached from '../../../assets/images/target/target.png'
import targetBattery from '../../../assets/images/target/target_battery.png'

export const TARGET: Target = {
    onStill: targetOnStill,
    onReached: targetOnReached,
}

export const TARGET_BATTERY: Target = {
    onStill: targetBattery,
    onReached: targetBattery,
}
