import { Obstacle } from "../../type/scene";
import pouf from "../../../../assets/images/obstacle/pouf/pouf.png"
import pouf_50 from "../../../../assets/images/obstacle/pouf/pouf_50.png"
import vase1 from "../../../../assets/images/obstacle/vase_2x2/1.png"
import vase2 from "../../../../assets/images/obstacle/vase_2x2/2.png"
import vase3 from "../../../../assets/images/obstacle/vase_2x2/3.png"
import vase4 from "../../../../assets/images/obstacle/vase_2x2/4.png"
import vase42V_1 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/1.png"
import vase42V_2 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/2.png"
import vase42V_3 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/3.png"
import vase42V_4 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/4.png"
import vase42V_5 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/5.png"
import vase42V_6 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/6.png"
import vase42V_7 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/7.png"
import vase42V_8 from "../../../../assets/images/obstacle/vase_4x2_VERTICAL/8.png"
import vase42H_1 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/1.png"
import vase42H_2 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/2.png"
import vase42H_3 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/3.png"
import vase42H_4 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/4.png"
import vase42H_5 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/5.png"
import vase42H_6 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/6.png"
import vase42H_7 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/7.png"
import vase42H_8 from "../../../../assets/images/obstacle/vase_4x2_HORIZONTAL/8.png"

export const POUF = (x: number, y: number): Obstacle => ({
    elements: [
        {
            img: pouf,
            x: x,
            y: y,
        }
    ]
})

export const POUF_50 = (x: number, y: number): Obstacle => ({
    elements: [
        {
            img: pouf_50,
            x: x,
            y: y,
        }
    ]
})

export const VASE_2X2 = (x: number, y: number): Obstacle => ({
    elements: [
        {
            img: vase1,
            x: x,
            y: y,
        },
        {
            img: vase2,
            x: x,
            y: y + 1,
        },
        {
            img: vase3,
            x: x + 1,
            y: y,
        },
        {
            img: vase4,
            x: x + 1,
            y: y + 1,
        }
    ]
})

export const VASE_4X2_VERTICAL = (x: number, y: number): Obstacle => ({
    elements: [
        {
            img: vase42V_1,
            x: x,
            y: y,
        },
        {
            img: vase42V_2,
            x: x,
            y: y + 1,
        },
        {
            img: vase42V_3,
            x: x + 1,
            y: y,
        },
        {
            img: vase42V_4,
            x: x + 1,
            y: y + 1,
        },
        {
            img: vase42V_5,
            x: x,
            y: y + 2,
        },
        {
            img: vase42V_6,
            x: x + 1,
            y: y + 2,
        },
        {
            img: vase42V_7,
            x: x,
            y: y + 3,
        },
        {
            img: vase42V_8,
            x: x + 1,
            y: y + 3,
        }
    ]
})

export const VASE_4X2_HORIZONTAL = (x: number, y: number): Obstacle => ({
    elements: [
        {
            img: vase42H_1,
            x: x,
            y: y,
        },
        {
            img: vase42H_2,
            x: x - 1,
            y: y,
        },
        {
            img: vase42H_3,
            x: x,
            y: y + 1,
        },
        {
            img: vase42H_4,
            x: x - 1,
            y: y + 1,
        },
        {
            img: vase42H_5,
            x: x,
            y: y + 2,
        },
        {
            img: vase42H_6,
            x: x - 1,
            y: y + 2,
        },
        {
            img: vase42H_7,
            x: x,
            y: y + 3,
        },
        {
            img: vase42H_8,
            x: x - 1,
            y: y + 3,
        }
    ]
})