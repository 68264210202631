// blocklyUtils.ts
import * as Blockly from 'blockly';
import { BlocklyFields } from './fields';

// blocklyUtils.ts

export const executeCodeWithDelay = (
    code: string,
    delay: number = 300,
    isDone: () => void,
    context: any
): void => {
    const instructions = groupCodeBlocks(code);

    const executeNext = (index: number) => {

        // Vérification avant d'exécuter chaque bloc
        if (context.executionStoppedRef?.current) {
            // Arrête immédiatement l'exécution si le flag est levé 
            return;
        }

        if (index < instructions.length) {
            setTimeout(() => {
                try {
                    // Utiliser `eval` avec le contexte des fonctions
                    const instruction = instructions[index];

                    // Vérification avant chaque instruction, y compris celles dans une boucle
                    if (context.executionStoppedRef?.current) {
                        return;
                    }

                    // Si l'instruction est une boucle, exécuter chaque itération séparément
                    if (instruction.startsWith('for')) {
                        const loopMatch = instruction.match(/for\s*\((.*?)\)\s*{(.*)}/s);

                        if (loopMatch) {  // Vérifie si loopMatch n'est pas null

                            // eslint-disable-next-line 
                            const [_, loopHeader, loopBody] = loopMatch;

                            const iterationMatch = loopHeader.match(/count < (\d+)/); // Vérifie la correspondance pour les itérations

                            if (iterationMatch) {  // Vérifie que iterationMatch n'est pas null
                                const loopIterations = parseInt(iterationMatch[1], 10); // Convertit en nombre d'itérations
                                let iteration = 0;

                                const executeLoopIteration = () => {
                                    // Vérifie à chaque itération si l'exécution est arrêtée
                                    if (context.executionStoppedRef?.current) {
                                        return; // Sort de la boucle si l'exécution est stoppée
                                    }

                                    if (iteration < loopIterations) {
                                        // eslint-disable-next-line 
                                        new Function(...Object.keys(context), loopBody)(...Object.values(context));
                                        iteration++;
                                        setTimeout(executeLoopIteration, delay); // Délai entre les itérations
                                    } else {
                                        executeNext(index + 1); // Passe à l'instruction suivante après la boucle
                                    }
                                };

                                executeLoopIteration(); // Commence la boucle
                                return; // Sortir pour laisser la boucle se gérer elle-même
                            }
                        }
                    }

                    // Si ce n'est pas une boucle, exécuter simplement l'instruction
                    // eslint-disable-next-line 
                    new Function(...Object.keys(context), instruction)(...Object.values(context));
                } catch (error) {
                    console.error("Erreur lors de l'exécution du bloc:", error, instructions[index]);
                }

                // Passe à l'instruction suivante
                executeNext(index + 1);
            }, delay);
        } else {
            // Appelle `isDone` après l'exécution de toutes les instructions
            isDone();
        }
    };

    // Démarre l'exécution
    executeNext(0);
};




export const groupCodeBlocks = (code: string): string[] => {
    const lines = code.split('\n');
    const groupedBlocks: string[] = [];
    let currentBlock = '';
    let openBraces = 0;

    lines.forEach(line => {
        line = line.trim();
        if (line === '') return;

        currentBlock += line;

        // Compte les accolades ouvrantes et fermantes
        openBraces += (line.match(/{/g) || []).length;
        openBraces -= (line.match(/}/g) || []).length;

        // Si openBraces revient à 0, on a un bloc complet
        if (openBraces === 0) {
            groupedBlocks.push(currentBlock);
            currentBlock = '';
        } else {
            // Ajoute une nouvelle ligne au bloc courant pour continuer
            currentBlock += ' ';
        }
    });

    if (currentBlock.trim() !== '') {
        groupedBlocks.push(currentBlock);
    }

    return groupedBlocks;
};

export const CheckNextCondition = (allBlocks: Blockly.BlockSvg[], processedBlockIds: string[]): boolean => {
    let nextIsNull = 0;

    for (const block of allBlocks) {
        // Ignorer les blocs déjà traités
        if (processedBlockIds.includes(block.id)) continue;

        processedBlockIds.push(block.id);

        if (block.type !== BlocklyFields.REPEAT) {
            if (block.getNextBlock() === null) {
                nextIsNull++;
                if (nextIsNull > 1) return false;
            }
        } else {
            let firstChildBlock = block.getInputTargetBlock('DO');
            let childrenBlocks: Blockly.BlockSvg[] = [];

            while (firstChildBlock) {
                childrenBlocks.push(firstChildBlock as Blockly.BlockSvg);
                firstChildBlock = firstChildBlock.getNextBlock();
            }
            if (!CheckNextCondition(childrenBlocks, processedBlockIds)) return false;
        }
    }

    return true;
};
