// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import i18next from 'i18next';

export function initStartBoxBlock() {

    Blockly.Blocks[BlocklyFields.STARTBOX] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.STARTBOX, // Identifiant unique du bloc
                "message0": i18next.t('blockly.starting_box.message', 'Case 1'), // Texte affiché sur le bloc
                "previousStatement": undefined, // Ne peut pas se connecter à un bloc précédent
                "nextStatement": null, // Peut se connecter à un bloc suivant
                "colour": '#FFCC00', // Couleur distinctive (vert par exemple)
                "tooltip": i18next.t('blockly.starting_box.tooltip', 'Point départ'), // Info-bulle
                "helpUrl": "" // Lien vers une aide (optionnel)
            });
        },
        generator: function (block: Blockly.Block) {
            return ''; // Ne génère aucun code JavaScript
        }
    };
    javascriptGenerator.forBlock[BlocklyFields.STARTBOX] = function (block, generator) {
        return '\n';
    }
}
