import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { TScene } from '../../type/scene';
import { TLine } from '../../type/line';
import { useGameContext } from './GameContext';

interface IGameProps {
    targets?: TLine[];
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;
    editionMode?: boolean;
}

const IGame: React.FC<IGameProps> = ({
    animationPromiseResolverRef,
    editionMode = false,
}) => {

    const { state } = useGameContext();
    const { character, scene, initialTargetLines, targetLines } = state;

    const controls = useAnimation();

    useEffect(() => {
        controls
            .start({
                scaleX: character.scale,
                x: character.x,
                y: character.y,
                transition: character.transition || { duration: 0.5 },
            })
            .then(() => {
                if (animationPromiseResolverRef.current) {
                    animationPromiseResolverRef.current();
                    animationPromiseResolverRef.current = null;
                }
            });

        // eslint-disable-next-line
    }, [character.x, character.y, character.scale]);

    // Précharger les images lors du montage du composant
    useEffect(() => {
        const imagesToPreload = [
            character.images.idle,
            character.images.moving,
            character.images.paused,
            character.images.colliding
        ];

        imagesToPreload.forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    }, [character.images]);

    const [styleScene, setStyleScene] = useState<React.CSSProperties>({});

    useEffect(() => {
        setStyleScene(getBackgroundStyle(scene));
    }, [scene]);


    const getBackgroundStyle = (scene: TScene): React.CSSProperties => {
        const backgroundImages: string[] = [];
        const backgroundSizes: string[] = [];
        const backgroundRepeats: string[] = [];

        // Ajouter l'image de fond si elle est définie
        if (scene.background?.img) {
            backgroundImages.push(`url(${scene.background.img})`);
            backgroundSizes.push(scene.background.size || 'cover');
            backgroundRepeats.push(scene.background.repeat ? 'repeat' : 'no-repeat');
        }

        // Vérifier s'il y a des images d'arrière-plan à appliquer
        if (backgroundImages.length === 0) {
            return {
                backgroundImage: 'none',
            };
        } else {
            return {
                backgroundImage: backgroundImages.join(', '),
                backgroundSize: backgroundSizes.join(', '),
                backgroundRepeat: backgroundRepeats.join(', '),
            };
        }
    };
    const getCharacterImage = () => {
        switch (character.state) {
            case 'idle':
                return character.images.idle;
            case 'moving':
                return character.images.moving;
            case 'paused':
                return character.images.paused;
            case 'colliding':
                return character.images.colliding;
            case 'wining':
                return character.images.win;
        }
    };


    return (
        <div
            id="sceneDraw"
            key="sceneDraw"
            style={{
                position: 'relative',
                width: `${scene.width}px`,
                height: `${scene.height}px`,
                backgroundColor: '#f9f9f9',
                border: '1px solid #ddd',
                ...styleScene,
            }}
        >
            {/* SVG pour tracer les lignes de mouvement */}
            <svg
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none', // Permet de ne pas interférer avec les interactions
                }}
            >
                {/* Tracer les lignes des targets */}
                {initialTargetLines.length > 0 &&
                    initialTargetLines.map((line, index) => {

                        return (
                            <line
                                key={index + 'target'}
                                x1={line.x1 + character.width / 2}  // Départ de la ligne
                                y1={line.y1 + character.height / 2} // Ajuster pour centrer
                                x2={line.x2 + character.width / 2}  // Fin de la ligne
                                y2={line.y2 + character.height / 2} // Ajuster pour centrer
                                stroke="lightgray"  // Couleur de la ligne
                                strokeWidth="5"  // Épaisseur de la ligne
                                strokeLinecap="round"  // Rendre les extrémités arrondies
                                strokeLinejoin="round"  // Rendre les jonctions arrondies
                                strokeDasharray={line.type}
                            />
                        );
                    })}
                {/* Tracer les lignes de l'objet */}
                {targetLines.length > 1 &&
                    targetLines.map((line, index) => {


                        return (
                            <line
                                key={index + 'result'}
                                x1={line.x1 + character.width / 2}  // Départ de la ligne
                                y1={line.y1 + character.height / 2} // Ajuster pour centrer
                                x2={line.x2 + character.width / 2}  // Fin de la ligne
                                y2={line.y2 + character.height / 2} // Ajuster pour centrer
                                stroke={line.color}  // Couleur de la ligne
                                strokeWidth="5"  // Épaisseur de la ligne
                                strokeLinecap="round"  // Rendre les extrémités arrondies
                                strokeLinejoin="round"  // Rendre les jonctions arrondies
                                strokeDasharray={line.type}
                            />
                        );
                    })}
            </svg>

            {/* Animation du personnage */}
            <motion.div
                id={"character"}
                style={{
                    width: `${character.width}px`,
                    height: `${character.height}px`,
                    backgroundImage: `url(${getCharacterImage()})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat', // Correction de la répétition d'image
                    position: 'absolute',
                    backgroundPosition: 'center',
                    border: editionMode ? '2px dashed blue' : 'none', // Bordure noire dashed si editionMode est vrai 
                }}
                animate={controls}
            />
        </div>
    );
};

export default IGame;
