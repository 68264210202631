// src/components/user/BusinessInfoForm.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, Input } from 'antd';

const BusinessInfoForm: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Card title={t('text.business_info')} style={{ marginBottom: 16 }}>
            <Form.Item
                label={t('text.tax_registry')}
                name={['business_info', 'taxeregistry']}
                rules={[{ required: true, message: t('message.value_required') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t('text.name')}
                name={['business_info', 'name']}
                rules={[{ required: true, message: t('message.value_required') }]}
            >
                <Input />
            </Form.Item>
        </Card>
    );
};

export default BusinessInfoForm;
