import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, Layout, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import bg from '../../../assets/images/background/background_login.jpg';
import { useAuthApi } from '../../../api/login/login';
import { TLogin } from '../../../models/login/login';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const { Content, Footer } = Layout;

interface LoginFormValues {
    username: string;
    password: string;
    remember: boolean;
}

const Login: React.FC = () => {
    const { basicAuth } = useAuthApi();
    const { login } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const onFinish = (values: LoginFormValues) => {
        setIsLoading(true);
        const { username, password } = values;

        basicAuth(username, password)
            .then((response) => {
                const token: TLogin = response.data
                login(token.token, token.roles, token.time_remaining, token.fullname);
                navigate('/');
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message)
            })
            .finally(() => {
                setIsLoading(false);
            })
    };


    return (
        <Layout
            style={{
                minHeight: '100vh',
                backgroundImage: `url("${bg}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}
        >
            <Content
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Card
                    bordered={false}
                    style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: '10px',
                        padding: '40px 24px', // Ajustement du padding pour mieux centrer l'image
                        margin: '20px',
                        width: '100%',
                        maxWidth: '400px',
                    }}
                >
                    {/* Ajout de l'image ici */}
                    <div style={{ textAlign: 'center', marginBottom: '24px' }}>
                        <img
                            src="/images/logo_120x64.png" // Remplacez par le chemin de votre image
                            alt="Logo"
                            style={{ height: '60px' }}
                        />
                    </div>
                    <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
                        Bienvenue
                    </Title>
                    <Form
                        name="login_form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        style={{ maxWidth: '100%' }}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Veuillez entrer votre nom d’utilisateur !' }]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Nom d’utilisateur"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Veuillez entrer votre mot de passe !' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="Mot de passe"
                                size="large"
                            />
                        </Form.Item>
                        {/* <Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Se souvenir de moi</Checkbox>
                                </Form.Item>
                                <a href="">Mot de passe oublié ?</a>
                            </div>
                        </Form.Item> */}

                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="large" block loading={isLoading}>
                                {isLoading ? 'Connexion...' : 'Se connecter'}
                            </Button>
                        </Form.Item>
                        <div style={{ textAlign: 'center' }}>
                            {/* Vous n'avez pas de compte ? <a href="">Inscrivez-vous maintenant !</a> */}
                        </div>
                    </Form>
                </Card>
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                © {new Date().getFullYear()} Tilmicode. Tous droits réservés.
            </Footer>
        </Layout>
    );
};

export default Login;
