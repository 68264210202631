import { Character } from '../type/character';
import faizanOnHold from '../../../assets/images/character/faizan.gif'
import faizanOnMove from '../../../assets/images/character/faizan_move.gif'
import faizanOnJump from '../../../assets/images/character/faizan_jump.gif'
import faizanOnWin from '../../../assets/images/character/faizan_win.gif'
import faizanOnLose from '../../../assets/images/character/faizan_lose.gif'


export const FAIZAN: Character = {
    onHold: faizanOnHold,
    onMove: faizanOnMove,
    onJump: faizanOnJump,
    onWin: faizanOnWin,
    onLose: faizanOnLose
}
