import React from 'react'
import AppLayout from '../../components/layout/AppLayout'
import WISIWYG from '../../components/editor/WISIWYG'

const ResearchandDevelopment = () => {
    return (
        <AppLayout title='r&d'>
            <WISIWYG />
        </AppLayout>
    )
}

export default ResearchandDevelopment