import React, { useEffect, useState } from 'react';
import { useObjectsApi } from '../../../api/objects/objects';
import { TTarget } from '../../../models/objects/objects';
import { Button, Input, message, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Table, { ColumnsType } from 'antd/es/table';
import { EditOutlined, SaveOutlined, StopOutlined, UploadOutlined } from '@ant-design/icons';
import { useReferential } from '../../../context/ReferentialContext';
import { TCodification } from '../../../models/referential/referential';
import { CODIFICATION_TYPE } from '../../../constant/codification';
import { getImagePath } from '../../../utils/format/valueFormat';

const TargetList: React.FC = () => {
    const { t } = useTranslation();
    const { getTargets, createTarget, updateTarget, uploadTargetImage } = useObjectsApi();
    const [targets, setTargets] = useState<TTarget[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const { getCodificationsByCodeType } = useReferential();
    const [loadingRow, setLoadingRow] = useState<number | null>(null);

    const states: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.TARGET_STATE)

    useEffect(() => {
        setIsLoading(true);
        getTargets()
            .then((resp) => {
                setTargets(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingObject, setEditingObject] = useState<TTarget | null>(null);

    const edit = (target: TTarget) => {
        setEditingKey(target.id);
        setEditingObject({ ...target });
    };

    const handleAdd = () => {
        const idRow = addingId - 1;
        const newObject: TTarget = {
            id: idRow,
            name: "",
            images: [],
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingObject(newObject);
        setTargets((prev) => [newObject, ...prev ?? []]);
    };
    const cancel = () => {
        const idRow = editingObject?.id || 0
        if (idRow <= 0) {
            setTargets((prev) => [...prev ?? []].filter(e => e.id !== idRow));
        }
        setEditingKey(null);
        setEditingObject(null);
    }
    const save = (id: number) => {
        if (editingObject) {
            setLoadingRow(id);
            (editingObject.id > 0 ? updateTarget(editingObject.id, editingObject) : createTarget(editingObject))
                .then((resp) => {
                    const result: TTarget = resp.data;
                    const updatedObjects = targets.map((e) =>
                        e.id === id ? result : e
                    );
                    setTargets(updatedObjects);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                    setLoadingRow(null);
                });
        }

        setEditingKey(null);
    };


    const uploadImage = (item: TTarget, state: TCodification) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                setLoadingRow(item.id);
                const formData = new FormData();
                formData.append('image', file);

                uploadTargetImage(item.id, state.id, formData)
                    .then((resp) => {
                        const result: TTarget = resp.data;
                        const updatedObjects = targets.map((e) =>
                            e.id === item.id ? result : e
                        );
                        setTargets(updatedObjects);
                        message.success(t('message.savedSuccessfully'));
                    })
                    .catch((err) => {
                        message.error(err.response.data ?? err.message);
                        console.error(err);
                    })
                    .finally(() => {
                        setLoadingRow(null);
                    });
            }
        };

        input.click();
    };

    const columns: ColumnsType<TTarget> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TTarget) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObject?.name}
                        onChange={(e) => {
                            if (editingObject) {
                                setEditingObject({ ...editingObject, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        // Colonnes dynamiques pour chaque état
        ...states.map((state) => ({
            title: state.label,
            dataIndex: `state_${state.id}`,
            key: `state_${state.id}`,
            render: (_: any, record: TTarget) => {
                const image = record.images?.find((img) => img.state_id === state.id);
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={image && image.img ? getImagePath(image.img, true) : 'https://via.placeholder.com/50'}
                            alt={`${state.label}`}
                            style={{ width: "50px", height: "50px", marginRight: 8 }}
                        />
                        {editingKey !== record.id && (
                            <Button icon={<UploadOutlined />} onClick={() => uploadImage(record, state)} />
                        )}
                    </div>
                );
            },
        })),
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 180,
            render: (_: any, record: TTarget) => (
                <Space size="middle">
                    {loadingRow === record.id ? ( // Affiche le spinner si la ligne est en chargement
                        <Spin />
                    ) : (
                        editingKey === record.id ? (
                            <>
                                <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                                <Button icon={<StopOutlined />} onClick={cancel} />
                            </>
                        ) : (
                            <>
                                <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            </>
                        )
                    )}
                </Space>
            ),
        },
    ];

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAdd}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TTarget>
                columns={columns}
                dataSource={targets ?? []}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />
        </>
    );
};

export default TargetList;
