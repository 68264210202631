// src/components/BlocklyDraw.tsx

import React, { useRef, useState } from 'react';
import { Button, Col, Row, Select, Space } from 'antd';
import BlocklyEditor from '../../workspace/BlocklyEditor';
import { useBlocklyWorkspace } from '../../config/useBlocklyWorkspace';
import { useTranslation } from 'react-i18next';
import IGame from './IGame';
import { useGameLogic } from './GameLogic';
import { executeAsyncCode } from '../BlocklyExecutor';
import { TCharacter } from '../../type/character';
import { TScene } from '../../type/scene';
import * as Blockly from 'blockly';
import { TLine } from '../../type/line';
import { GameProvider, useGameContext } from './GameContext';
import { initBlocklyGameBlocks } from '../../config/BlocklyBlocks';


interface BlocklyDrawProps {
  checkResult: (isValid: boolean) => void;
  toolbox: any;
  initialBlocksJsonOrXML: any;
  character: TCharacter;
  targets?: TLine[];
  scene?: TScene;
  autoReset?: boolean;
  xmlRef?: React.MutableRefObject<string>;
  cansave?: boolean;
  editionMode?: boolean;
  canChangeXY?: boolean;
}

const BlocklyDraw: React.FC<BlocklyDrawProps> = (props) => {

  return (
    <GameProvider
      initialCharacter={props.character}
      initialScene={props.scene || { width: 500, height: 500, gridRowWidth: 50, gridColumnWidth: 50 }}
      initialTargetLines={props.targets}
    >
      <BlocklyDrawContent {...props} />
    </GameProvider>
  );
};


const BlocklyDrawContent: React.FC<BlocklyDrawProps> = ({
  checkResult,
  toolbox,
  initialBlocksJsonOrXML,
  xmlRef,
  targets = [],
  autoReset = true,
  canChangeXY = false,
  cansave = false,
  editionMode = false,
}) => {

  initBlocklyGameBlocks();

  const { t } = useTranslation();
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg | null>(null);
  const { isRunButtonEnabled } = useBlocklyWorkspace(workspace);

  const { state, dispatch } = useGameContext();
  const { character, scene } = state;
  const animationPromiseResolverRef = useRef<(() => void) | null>(null);

  const gameLogic = useGameLogic({
    animationPromiseResolverRef,
    autoReset,
    checkResult,
  });




  // Exécution du code Blockly 
  const handleRun = (): void => {
    dispatch({ type: 'SET_EXECUTION_CANCELLED', payload: false });
    if (workspace) {
      const context = {
        walkCharacter: gameLogic.walkCharacter,
        jumpCharacter: gameLogic.jumpCharacter,
        setImage: gameLogic.setSceneBackground,
        resetGame: gameLogic.resetGame,
        isDone: gameLogic.isDone,
        setLineType: gameLogic.setLineType,
        setLineColor: gameLogic.setLineColor,
      };
      executeAsyncCode(workspace, context);
    }
  };

  // Fonction pour générer les options de positions basées sur la largeur/hauteur du personnage et de la scène 
  const generateOptionsForPositions = (max: number, step: number, prefix: string) => {
    const options = [];
    for (let i = 0; i <= max; i += step) {
      options.push({ value: i, label: prefix + ": " + +i.toString() });
    }
    return options;
  };
  // Implement changeStartPositionX/Y if canChangeXY is true
  const changeStartPositionX = (value: number | null) => {
    dispatch({
      type: 'MOVE_CHARACTER',
      payload: {
        x: value !== null ? value : 0,
        y: character.y,
        state: character.state,
        scale: character.scale,
      },
    });
  };

  const changeStartPositionY = (value: number | null) => {
    dispatch({
      type: 'MOVE_CHARACTER',
      payload: {
        x: character.x,
        y: value !== null ? value : 0,
        state: character.state,
        scale: character.scale,
      },
    });
  };

  return (
    <div>
      <Row gutter={[16, 16]} style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Col flex="auto">
          <BlocklyEditor
            toolbox={toolbox}
            initialBlocksJsonOrXML={initialBlocksJsonOrXML}
            onWorkspaceChange={(ws) => setWorkspace(ws)}
            xmlRef={xmlRef}
            cansave={cansave ?? false}
          />
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Space>
              <Button type="primary" size="large" onClick={handleRun} disabled={!isRunButtonEnabled}>
                {t('button.run')}
              </Button>
            </Space>
          </div>
        </Col>
        <Col style={{ width: `${scene.width}px` }}>
          <IGame
            targets={targets}
            editionMode={editionMode}
            animationPromiseResolverRef={animationPromiseResolverRef}
          />
          <Space direction="vertical" style={{ width: '100%', marginTop: '12px' }} size="large">
            {canChangeXY && (
              <Row gutter={16}>
                <Col span={12}>
                  <Select
                    style={{ width: '100%' }}
                    options={generateOptionsForPositions(scene.width - character.width, character.width, "X")}
                    value={character.x}
                    onChange={(value) => changeStartPositionX(value)}
                    placeholder="Position X"
                    size="large"
                  />
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: '100%' }}
                    options={generateOptionsForPositions(scene.height - character.height, character.height, "Y")}
                    value={character.y}
                    onChange={(value) => changeStartPositionY(value)}
                    placeholder="Position Y"
                    size="large"
                  />
                </Col>
              </Row>
            )}
            {!autoReset && (
              <Row gutter={16}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Button type="default" size="large" onClick={() => gameLogic.resetGame()}>
                    {t('button.reset')}
                  </Button>
                </Col>
              </Row>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default BlocklyDraw;
