import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import MovableImage from '../../../../components/imageManipulation/MovableImage';
import cat from '../../../../assets/images/puzzle/cat.jpg';
import { useTranslation } from 'react-i18next';

interface DnDLevelOneProps {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}

const DnDLevelOne: React.FC<DnDLevelOneProps> = ({ checkResult }) => {
    const { t } = useTranslation();
    const [isMoved, setIsMoved] = useState(false);

    const handleMove = () => {
        message.success(t("message.bravo"));
        setIsMoved(true);
        checkResult(true); // Valide l'exercice lorsque l'image est déplacée
    };

    useEffect(() => {
        if (!isMoved) {
            checkResult(false); // Désactive le bouton "Suivant" tant que l'exercice n'est pas validé
        }
    }, [isMoved, checkResult]);

    return (
        <Row justify="center">
            <Col span={12}>
                <MovableImage
                    onMove={handleMove}
                    src={cat}
                    initialX={100}
                    initialY={100}
                />
            </Col>
        </Row>
    );
};

export default DnDLevelOne;
