// AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import packageJson from '../../package.json';

interface AuthContextType {
    getFullName: () => string | null;
    getToken: () => string | null;
    getRoles: () => string[];
    getTimeRemaining: () => number;
    isRoleExist: (role: string) => boolean;
    login: (token: string, roles: string[], time_remaining: number, fullName: string) => void;
    logout: () => void;
}
// Récupérer la version depuis package.json 
const appVersion = packageJson.version; // Version de l'application

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Fournisseur d'authentification pour envelopper l'application
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [token, setToken] = useState<string | null>(() => localStorage.getItem('token'));
    const [fullname, setFullname] = useState<string | null>(() => localStorage.getItem('fullname'));
    const [roles, setRoles] = useState<string[]>(() => {
        const storedRoles = localStorage.getItem('roles');
        return storedRoles ? JSON.parse(storedRoles) : [];
    });
    const [timeRemaining, setTimeRemaining] = useState<number>(() => {
        const storedTime = localStorage.getItem('time_remaining');
        return storedTime ? Number(storedTime) : -1;
    });

    useEffect(() => {
        const storedVersion = localStorage.getItem('appVersion');

        if (!storedVersion || storedVersion !== appVersion) {
            // Si la version est différente, forcer le rechargement
            localStorage.clear();
            localStorage.setItem('appVersion', appVersion);
            logout();
        }

    }, [])
    const login = (newToken: string, userRoles: string[], time_remaining: number, fullName: string) => {
        setToken(newToken);
        setFullname(fullName);
        setRoles(userRoles);
        setTimeRemaining(time_remaining);

        // Enregistrer les informations dans le localStorage pour persistance
        localStorage.setItem('fullname', fullName);
        localStorage.setItem('token', newToken);
        localStorage.setItem('roles', JSON.stringify(userRoles));
        localStorage.setItem('time_remaining', time_remaining.toString());
    };

    const getToken = (): string | null => {
        return token;
    };

    const getFullName = (): string | null => {
        return fullname;
    };

    const getRoles = (): string[] => {
        return roles;
    };

    const getTimeRemaining = (): number => {
        return timeRemaining;
    };

    const isRoleExist = (role: string): boolean => {
        return roles.includes(role);
    };

    const logout = () => {


        setFullname(null);
        setToken(null);
        setRoles([]);
        setTimeRemaining(-1);

        // Supprimer les informations du localStorage
        localStorage.removeItem('fullname');
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        localStorage.removeItem('time_remaining');
    };

    return (
        <AuthContext.Provider value={{ getToken, getRoles, login, logout, isRoleExist, getTimeRemaining, getFullName }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
