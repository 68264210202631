// ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface ProtectedRouteProps {
    requiredRoles?: string[];
    children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredRoles, children }) => {
    const { getToken, isRoleExist } = useAuth();

    if (!getToken()) {
        return <Navigate to="/" />;
    }

    if (requiredRoles && !requiredRoles.some(role => isRoleExist(role))) {
        return <Navigate to="/access-denied" replace />;
    }

    return children;
};

export default ProtectedRoute;
