
import zelija from '../../../assets/images/background/zelija.png'

export type TScene = {
    width: number;
    height: number;
    background?: {
        img: string;
        repeat: boolean;
        size: string;
    };
    gridRowWidth: number;
    gridColumnWidth: number;
}

interface OptionalSceneParams {
    gridRowWidth?: number,
    gridColumnWidth?: number,
    img?: string;
    repeat?: boolean;  // Rotation optionnelle pour l'obstacle
    size?: string;
}

export const getScene = (
    width: number,
    height: number,
    options: OptionalSceneParams = {}  // Paramètres optionnels groupés dans un objet
): TScene => {
    return {
        width: width,
        height: height,
        background: {
            img: options.img ?? "",
            repeat: options.repeat ?? false,
            size: options.size ?? `${width}px ${height}px`
        },
        gridRowWidth: options.gridColumnWidth ?? 50,
        gridColumnWidth: options.gridColumnWidth ?? 50,
    };
};
export const getSceneZelija = (
    width: number,
    height: number,
    options: OptionalSceneParams = {}  // Paramètres optionnels groupés dans un objet
): TScene => {
    return getScene(width, height, { img: zelija, repeat: true, size: '50px 50px', ...options })
};
