// src/components/BlocklyEditor.tsx

import React, { useEffect, useRef, useState } from 'react';
import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { buildBlocksXmlFromJSON } from './config/BlockyConfig';

interface BlocklyEditorProps {
  toolbox: any;
  initialBlocksJson: any;
  onWorkspaceChange?: (workspace: Blockly.WorkspaceSvg) => void;
}

const BlocklyEditor: React.FC<BlocklyEditorProps> = ({ toolbox, initialBlocksJson, onWorkspaceChange }) => {
  const workspaceRef = useRef<HTMLDivElement | null>(null);
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg | null>(null);

  useEffect(() => {
    if (workspaceRef.current) {
      try {
        const newWorkspace = Blockly.inject(workspaceRef.current, {
          toolbox: toolbox,
          grid: {
            spacing: 20,
            length: 4,
            colour: '#ccc',
            snap: true

          },
          zoom: {
            controls: true,
            wheel: true,
            startScale: 0.5,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2
          },
          trashcan: true,
        });
        setWorkspace(newWorkspace);
        javascriptGenerator.init(newWorkspace);


        // Écouteur d'événement pour attendre que Blockly soit prêt
        newWorkspace.addChangeListener(function (event) {
          if (event.type === Blockly.Events.FINISHED_LOADING) {
            newWorkspace.zoom(0, 0, 3);
            newWorkspace.scroll(0, 0);
          }
        });

        const initialBlocksXml = buildBlocksXmlFromJSON(initialBlocksJson);
        Blockly.Xml.domToWorkspace(Blockly.utils.xml.textToDom(initialBlocksXml), newWorkspace);


        if (onWorkspaceChange) {
          onWorkspaceChange(newWorkspace);
        }
      } catch (error) {
        console.error("Erreur lors de l'injection de Blockly:", error);
      }
    }

    return () => {
      if (workspace) {
        workspace.dispose();
        setWorkspace(null);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={workspaceRef} id="blocklyDiv" style={{ height: '400px', width: '800px' }} />
  );
};

export default BlocklyEditor;