import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Table, Input, Button, Form, Space, Popconfirm, Select } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useReferential } from '../../../../context/ReferentialContext';
import { TCodification } from '../../../../models/referential/referential';
import { CODIFICATION_TYPE } from '../../../../constant/codification';

const ContactsManager: React.FC = () => {
  const { t } = useTranslation();
  const { getCodificationsByCodeType } = useReferential();
  const contactTypes: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.CONTACT_TYPE);

  return (
    <Card title={t('text.contacts')} style={{ marginBottom: 16 }}>
      <Form.List name="contacts">
        {(fields, { add, remove }) => (
          <>
            {fields.length > 0 && (
              <Table
                dataSource={fields}
                rowKey="key"
                pagination={false}
                columns={[
                  {
                    title: t('text.contact_type'),
                    dataIndex: 'type_id',
                    key: 'type_id',
                    render: (_: any, record: any, index: number) => (
                      <Form.Item
                        name={[index, 'type_id']}
                        rules={[{ required: true, message: t('message.value_required') }]}
                        style={{ marginBottom: 0 }}
                      >
                        <Select placeholder={t('text.contact_type')} style={{ width: '100%' }}>
                          {contactTypes.map((type: TCodification) => (
                            <Select.Option key={type.id} value={type.id}>
                              {type.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ),
                  },
                  {
                    title: t('text.contact_value'),
                    dataIndex: 'contact_value',
                    key: 'contact_value',
                    render: (_: any, record: any, index: number) => (
                      <Form.Item
                        name={[index, 'contact_value']}
                        rules={[{ required: true, message: t('message.value_required') }]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder={t('text.contact_value')} style={{ width: '100%' }} />
                      </Form.Item>
                    ),
                  },
                  {
                    title: t('text.actions'),
                    key: 'actions',
                    render: (_: any, record: any, index: number) => (
                      <Space size="middle" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Popconfirm
                          title={t('message.confirm_delete')}
                          onConfirm={() => remove(index)}
                          okText={t('button.yes')}
                          cancelText={t('button.no')}
                        >
                          <Button icon={<DeleteOutlined />} danger />
                        </Popconfirm>
                      </Space>
                    ),
                  },
                ]}
              />
            )}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '100%', marginTop: 16 }}
                icon={<PlusOutlined />}
              >
                {t('button.add')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default ContactsManager;
