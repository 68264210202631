// src/blocks/events/whenRightArrowPressed.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import { TCharacter } from '../../type/character';
import i18next from 'i18next';

export function initWhenCharactersCollide(characters: TCharacter[]) {
    // Créer des options pour le dropdown des personnages, avec leurs images 'idle'
    const characterOptions = characters.map((character) => [
        {
            src: character.images.idle,
            width: 36,
            height: 36,
            alt: character.name,
        },
        character.id.toString(),
    ]);

    Blockly.Blocks[BlocklyFields.WHEN_CHARACTERS_COLLIDE] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.WHEN_CHARACTERS_COLLIDE,
                "message0": '%1 %2 %3 %4' + i18next.t('blockly.events.when_characters_collide.message', 'When Characters collide'),
                "args0": [
                    {
                        type: 'field_dropdown',
                        name: 'CHARACTER1',
                        options: characterOptions,
                    },
                    {
                        "type": "field_image",
                        "src": "/images/blocks/collision.png",
                        "width": 36,
                        "height": 36,
                    },
                    {
                        type: 'field_dropdown',
                        name: 'CHARACTER2',
                        options: characterOptions, // Affiche les images pour les personnages
                    },
                    {
                        "type": "input_statement",
                        "name": "DO"
                    }
                ],
                "colour": '#7242f5',
                "tooltip": i18next.t('blockly.events.when_characters_collide.tooltip', 'Triggered when Characters collide.'),
                "helpUrl": "",
                "inputsInline": true,
            });
        }
    };

    javascriptGenerator.forBlock[BlocklyFields.WHEN_CHARACTERS_COLLIDE] = function (block) {
        const character1Id = block.getFieldValue('CHARACTER1');
        const character2Id = block.getFieldValue('CHARACTER2');
        const statements_do = javascriptGenerator.statementToCode(block, 'DO');

        const code = `
        onCharactersCollideFunctions.push({
            character1Id: ${character1Id},
            character2Id: ${character2Id},
            func: async function() {
                ${statements_do}
            }
        });
        `;
        return code;
    };
}
