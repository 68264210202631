
export const BlocklyFields = {
    STARTBOX: "starting_box_v1",
    REPEAT: "repeat_custom_v1",
    MOVE_UP: "move_up_v1",
    MOVE_DOWN: "move_down_v1",
    MOVE_LEFT: "move_left_v1",
    MOVE_RIGHT: "move_right_v1",
    GET_TARGET: "get_target_v1",

    JUMP_UP: "jump_up_v1",
    JUMP_DOWN: "jump_down_v1",
    JUMP_LEFT: "jump_left_v1",
    JUMP_RIGHT: "jump_right_v1",
}