// src/blocks/style/changeLineColor.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import i18next from 'i18next';
import { BlocklyFields } from '../BlocklyFields';

export function initChangeLineColorBlock() {

    Blockly.Blocks[BlocklyFields.CHANGE_LINE_COLOR] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.CHANGE_LINE_COLOR,
                "message0": '%1 ' + i18next.t('blockly.style.changeLineColor.message', 'Change line color'),
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "LINE_COLOR",
                        "options": [
                            [
                                {
                                    "src": "data:image/svg+xml;base64," + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16"><rect width="32" height="16" fill="#000000" /></svg>'),
                                    "width": 32,
                                    "height": 16,
                                    "alt": "Black"
                                },
                                "#000000"
                            ],
                            [
                                {
                                    "src": "data:image/svg+xml;base64," + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16"><rect width="32" height="16" fill="#fa0202" /></svg>'),
                                    "width": 32,
                                    "height": 16,
                                    "alt": "Red"
                                },
                                "#fa0202"
                            ],
                            [
                                {
                                    "src": "data:image/svg+xml;base64," + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16"><rect width="32" height="16" fill="#00cc00" /></svg>'),
                                    "width": 32,
                                    "height": 16,
                                    "alt": "Green"
                                },
                                "#00cc00"
                            ],
                            [
                                {
                                    "src": "data:image/svg+xml;base64," + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16"><rect width="32" height="16" fill="#0202d1" /></svg>'),
                                    "width": 32,
                                    "height": 16,
                                    "alt": "Blue"
                                },
                                "#0202d1"
                            ],
                            [
                                {
                                    "src": "data:image/svg+xml;base64," + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16"><rect width="32" height="16" fill="#d4d400" /></svg>'),
                                    "width": 32,
                                    "height": 16,
                                    "alt": "Yellow"
                                },
                                "#d4d400"
                            ],
                            [
                                {
                                    "src": "data:image/svg+xml;base64," + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16"><rect width="32" height="16" fill="#c800e3" /></svg>'),
                                    "width": 32,
                                    "height": 16,
                                    "alt": "Purple"
                                },
                                "#c800e3"
                            ],
                            [
                                {
                                    "src": "data:image/svg+xml;base64," + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16"><rect width="32" height="16" fill="#03eaff" /></svg>'),
                                    "width": 32,
                                    "height": 16,
                                    "alt": "Cyan"
                                },
                                "#03eaff"
                            ],
                        ],
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#FF5733', // Couleur du bloc lui-même
                "tooltip": i18next.t('blockly.style.changeLineColor.tooltip', 'Select a line color'),
                "helpUrl": ""
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.CHANGE_LINE_COLOR] = function (block, generator) {
        // Obtenir la couleur sélectionnée dans le champ dropdown
        const lineColor = block.getFieldValue('LINE_COLOR');
        return `setLineColor("${lineColor}");\n`;
    }
}
