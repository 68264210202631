// src/blocks/actions/saySecretBlock.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import { TCharacter } from '../../type/character';
import i18next from 'i18next';

export function initSaySecretBlock(characters: TCharacter[]) {
    // Créer des options pour le dropdown des personnages, avec leurs images 'idle'
    const characterOptions = characters.filter((character) => character.canbeplayedwith === false)
        .map((character) => [
            {
                src: character.images.idle,
                width: 36,
                height: 36,
                alt: character.name,
            },
            character.id.toString(),
        ]);

    Blockly.Blocks[BlocklyFields.SAY_SECRET] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.SAY_SECRET,
                "message0": '%1 ' + i18next.t('blockly.say.secret.message'),
                "args0": [
                    {
                        type: 'field_dropdown',
                        name: 'CHARACTER',
                        options: characterOptions,
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#4C97FF',
                "tooltip": i18next.t('blockly.say.secret.tooltip', 'Fait dire le secret au personnage.'),
                "helpUrl": '',
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.SAY_SECRET] = function (block) {
        const characterId = block.getFieldValue('CHARACTER');
        const phrase = "******";
        // Échapper la phrase pour l'utiliser dans une chaîne JavaScript
        const escapedSecret = phrase.replace(/\\/g, '\\\\').replace(/'/g, '\\\'').replace(/"/g, '\\"');
        return `await sayPhrase(${characterId}, "${escapedSecret}");\n await revealSecret();\n`;
    };
}
