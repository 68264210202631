// src/game/GameContext.tsx

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { GameState } from './GameState';
import { GameAction } from './GameAction';
import { gameReducer } from './gameReducer';
import { TCharacter } from '../../type/character';
import { TScene } from '../../type/scene';
import { TLine } from '../../type/line';

interface GameContextProps {
    state: GameState;
    dispatch: React.Dispatch<GameAction>;
}

const GameContext = createContext<GameContextProps | undefined>(undefined);

export const useGameContext = () => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useGameContext must be used within a GameProvider');
    }
    return context;
};

interface GameProviderProps {
    initialCharacter: TCharacter;
    initialScene: TScene;
    initialTargetLines?: TLine[];
    children: React.ReactNode;
}

export const GameProvider: React.FC<GameProviderProps> = ({
    initialCharacter,
    initialScene,
    initialTargetLines = [],
    children,
}) => {
    const [state, dispatch] = useReducer(gameReducer, {
        character: initialCharacter,
        scene: initialScene,
        executionCancelled: false,

        initialCharacter: initialCharacter,
        initialScene: initialScene,
        initialTargetLines: initialTargetLines,

        targetLines: [],
    });

    // Mise à jour du personnage
    useEffect(() => {
        dispatch({ type: 'UPDATE_CHARACTER', payload: initialCharacter });
        dispatch({ type: 'RESET_MOVEMENT_HISTORY' });

    }, [initialCharacter]);

    // Mise à jour de la scène
    useEffect(() => {
        dispatch({ type: 'UPDATE_SCENE', payload: initialScene });
    }, [initialScene]);

    // Mise à jour des cibles
    useEffect(() => {
        dispatch({ type: 'RESET_LINES', payload: initialTargetLines });
    }, [initialTargetLines]);

    return <GameContext.Provider value={{ state, dispatch }}>{children}</GameContext.Provider>;
};
