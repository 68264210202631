// src/game/GameLogic.ts

import { useGameContext } from './GameContext';
import { TTarget } from '../../type/target';
import { TCharacter } from '../../type/character';
import { useEffect, useRef } from 'react';
import { roundToNearest } from '../../../../utils/gen/calcul';

interface GameLogicProps {
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;
    checkResult: (isValid: boolean) => void;
    target: TTarget;
}

export const useGameLogic = ({
    animationPromiseResolverRef,
    checkResult,
    target,
}: GameLogicProps) => {
    const { state, dispatch } = useGameContext();
    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);


    const walkCharacter = async (id: number, angle: number): Promise<void> => {

        return moveCharacter(angle, id, false);
    };

    const jumpCharacter = async (id: number, angle: number): Promise<void> => {
        return moveCharacter(angle, id, true);
    };

    const adjustDistanceForAngle = (angle: number, baseDistance: number): number => {


        // Normaliser l'angle entre 0 et 360 degrés
        const normalizedAngle = ((angle % 360) + 360) % 360;

        // Angles diagonaux (45°, 135°, 225°, 315°)
        const diagonalAngles = [45, 135, 225, 315];

        if (diagonalAngles.includes(normalizedAngle)) {
            // Multiplier la distance par √2 pour les déplacements diagonaux
            return baseDistance * Math.SQRT2;
        } else {
            // Garder la distance de base pour les autres angles
            return baseDistance;
        }
    };


    // Fonction de vérification des collisions
    const checkCollision = (
        nextX: number,
        nextY: number,
        prevCharacterState: TCharacter
    ) => {
        const { scene } = stateRef.current;
        let isColliding = false;
        let adjustedX = nextX;
        let adjustedY = nextY;


        // Vérifier les collisions avec les limites de la scène
        if (nextX < 0) {
            adjustedX = 0;
            isColliding = true;
        } else if (nextX + prevCharacterState.width > scene.width) {
            adjustedX = scene.width - prevCharacterState.width;
            isColliding = true;
        }

        if (nextY < 0) {
            adjustedY = 0;
            isColliding = true;
        } else if (nextY + prevCharacterState.height > scene.height) {
            adjustedY = scene.height - prevCharacterState.height;
            isColliding = true;
        }

        adjustedX = roundToNearest(adjustedX);
        adjustedY = roundToNearest(adjustedY);
        return { isColliding, adjustedX, adjustedY };
    };

    const reachTargetByXY = async (character: TCharacter, x: number, y: number): Promise<void> => {


        // Trouver la cible atteinte
        if (x < target.x + target.width &&
            x + character.width > target.x &&
            y < target.y + target.height &&
            y + character.height > target.y) {
            dispatch({
                type: 'UPDATE_TARGET',
                payload: {
                    ...target,
                    state: 'reached',
                },
            });
            dispatch({
                type: 'UPDATE_CHARACTER',
                payload: {
                    id: character.id,
                    newState: 'wining',
                },
            });
            checkResult(true);
        }
    };

    const moveCharacter = async (angle: number, id: number, isJumping: boolean): Promise<void> => {
        // Récupérer le dernier état à chaque appel
        const { characters } = stateRef.current;
        const character = characters.find(char => char.id === id)

        if (!character) {
            return
        }
        if (angle === -1) {
            const angles = [0, 180, 270, 90];
            angle = angles[Math.floor(Math.random() * angles.length)]; // Choisit une valeur aléatoire parmi les angles

        }
        const adjustedDistance = adjustDistanceForAngle(angle, character.displacementUnit * (isJumping ? 2 : 1));

        return new Promise<void>((resolve) => {

            const radians = (angle * Math.PI) / 180;
            const deltaX = adjustedDistance * Math.cos(radians);
            const deltaY = adjustedDistance * Math.sin(radians);

            // Calcul des positions suivantes
            const nextX = roundToNearest(character.x + deltaX);
            const nextY = roundToNearest(character.y + deltaY);

            const { isColliding, adjustedX, adjustedY } = checkCollision(
                nextX,
                nextY,
                character
            );


            const finalX = isColliding ? adjustedX : nextX;
            const finalY = isColliding ? adjustedY : nextY;

            if (isColliding) {

                resolve();
            } else {
                // animationPromiseResolverRef est géré dans IGame
                animationPromiseResolverRef.current = async () => {
                    // Après le mouvement, vérifier les collisions entre les personnages
                    await checkCharacterCollisions();
                    resolve();
                };

                dispatch({
                    type: 'UPDATE_CHARACTER',
                    payload: {
                        id: id,
                        newX: roundToNearest(finalX),
                        newY: roundToNearest(finalY),
                        newState: 'moving',
                        newScale: deltaX >= 0 ? 1 : -1,
                    },
                });
                // le resolve est dans l'animation IGame
                reachTargetByXY(character, finalX, finalY)
            }
        });


    };

    // Nouvelle fonction pour vérifier les collisions entre les personnages
    const checkCharacterCollisions = async () => {
        const { characters } = stateRef.current;

        // Parcourir toutes les paires de personnages
        for (let i = 0; i < characters.length; i++) {
            for (let j = i + 1; j < characters.length; j++) {
                const charA = characters[i];
                const charB = characters[j];

                if (isRectangleColliding(charA, charB)) {
                    // Vérifier si une fonction est associée à cette collision
                    await executeCollisionFunctions(charA.id, charB.id);
                }
            }
        }
    };



    // Fonction pour déterminer si deux rectangles se chevauchent
    const isRectangleColliding = (rectA: TCharacter, rectB: TCharacter): boolean => {
        return (
            rectA.x < rectB.x + rectB.width &&
            rectA.x + rectA.width > rectB.x &&
            rectA.y < rectB.y + rectB.height &&
            rectA.y + rectA.height > rectB.y
        );
    };

    // Fonction pour exécuter les fonctions de collision appropriées
    const executeCollisionFunctions = async (id1: number, id2: number) => {
        if (onCharactersCollideFunctionsRef.current) {
            for (const collisionFunc of onCharactersCollideFunctionsRef.current) {
                const { character1Id, character2Id, func } = collisionFunc;
                if (
                    (character1Id === id1 && character2Id === id2) ||
                    (character1Id === id2 && character2Id === id1)
                ) {
                    await func();
                }
            }
        }
    };

    // Référence pour stocker les fonctions de collision
    const onCharactersCollideFunctionsRef = useRef<
        {
            character1Id: number;
            character2Id: number;
            func: () => Promise<void>;
        }[]
    >([]);

    // Fonction pour mettre à jour les fonctions de collision
    const setCollisionFunctions = (
        collisionFunctions: {
            character1Id: number;
            character2Id: number;
            func: () => Promise<void>;
        }[]
    ) => {
        onCharactersCollideFunctionsRef.current = collisionFunctions;
    };

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const sayPhrase = async (id: number, phrase: string): Promise<void> => {
        dispatch({
            type: 'SPEAK_CHARACTER',
            payload: {
                id: id,
                speech: phrase,
            },
        });
        // Optionnel : Effacer la bulle de dialogue après 2 secondes
        setTimeout(() => {
            dispatch({
                type: 'SPEAK_CHARACTER',
                payload: {
                    id: id,
                    speech: null,
                },
            });
        }, 2000);

        await delay(2000);
    };
    // Nouvelle fonction pour vérifier les collisions entre les personnages
    const revealSecret = async () => {

        dispatch({
            type: 'UPDATE_TARGET',
            payload: {
                ...target,
                visible: true,
            },
        });
    };

    const resetGame = () => {
        dispatch({ type: 'RESET_GAME' });
    };




    const setSceneBackground = (img: string) => {
        const { scene } = stateRef.current;
        dispatch({
            type: 'SET_SCENE_BACKGROUND',
            payload: {
                img,
                repeat: scene.background?.repeat || false,
                size: scene.background?.size || 'cover',
            },
        });
    };




    return {
        walkCharacter,
        jumpCharacter,
        moveCharacter,
        setCollisionFunctions,
        sayPhrase,
        resetGame,
        setSceneBackground,
        revealSecret,
    };
};
