// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'; // Optionnel : pour détecter la langue du navigateur

// Récupérer la version du package.json
const packageJson = require('../package.json');
const appVersion = packageJson.version;

i18n
    .use(HttpApi)
    .use(LanguageDetector) // Utilisation du détecteur de langue (optionnel)
    .use(initReactI18next) // Intégration avec react-i18next
    .init({
        lng: 'fr', // Langue par défaut
        fallbackLng: 'fr', // Langue de repli
        supportedLngs: ['fr', 'en', 'ar'], // Langues supportées
        interpolation: {
            escapeValue: false, // React échappe déjà les valeurs par défaut
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?v=${appVersion}`, // Chemin vers les fichiers de traduction
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'], // Détection automatique des langues
            caches: ['cookie'], // Où stocker la langue détectée
        },
    });

export default i18n;
