import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Table, Button, Form, Space, Popconfirm, AutoComplete, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useUserApi } from '../../../../api/user/userApp';
import { TUserApp } from '../../../../models/user/User';
import LoadingBox from '../../../../components/loading/LoadingBox';
import dayjs from 'dayjs'; // Import de dayjs pour le formatage des dates
import { TCodification } from '../../../../models/referential/referential';

interface RelationManagerProps {
    title: string;
    typeUser: TCodification;
    codifTypeRelation: TCodification | undefined;
    userApp: TUserApp | null;
    fieldName: string; // Nouvelle prop pour le nom de champ unique
}

const RelationManager: React.FC<RelationManagerProps> = ({ title, typeUser, codifTypeRelation, userApp, fieldName }) => {
    const { t } = useTranslation();
    const form = Form.useFormInstance();
    const { getAllUsersForSearch } = useUserApi();
    const [loading, setIsLoading] = useState<boolean>(false);
    const [userOptions, setUserOptions] = useState<TUserApp[]>([]);
    const [filteredUserOptions, setFilteredUserOptions] = useState<TUserApp[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    // Utiliser fieldName ici
    const childrenData = Form.useWatch(fieldName, form) || [];

    useEffect(() => {
        if (typeUser && typeUser.id > 0 && codifTypeRelation && codifTypeRelation.id > 0) {
            setIsLoading(true);
            getAllUsersForSearch(codifTypeRelation.id, typeUser.id)
                .then((resp) => {
                    setUserOptions(resp.data);

                    let relations: number[] = [];
                    if (userApp && userApp.userapp_relations && userApp.userapp_relations.length > 0) {
                        // Extraire les identifiants des utilisateurs liés
                        relations = userApp.userapp_relations
                            .filter(item => item.typerelation_id === codifTypeRelation.id)
                            .map((item) => {
                                if (item.parent_id === userApp.id) {
                                    return item.children_id;
                                } else {
                                    return item.parent_id;
                                }
                            });

                        // Filtrer les utilisateurs correspondants dans userOptions
                        const initialChildrenData = (resp.data || []).filter((user: TUserApp) => relations.includes(user.id)).map((user: TUserApp) => ({
                            id: user.id,
                            login: user.login,
                            firstname: user.personal_info?.firstname || '',
                            lastname: user.personal_info?.lastname || '',
                            birthdate: user.personal_info?.birthdate || '',
                            typeRelationCodification: codifTypeRelation,
                        }));

                        // Initialiser le champ avec fieldName
                        form.setFieldsValue({
                            [fieldName]: initialChildrenData,
                        });
                    }
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [typeUser.id]);

    const handleSearch = (searchText: string) => {
        setSearchValue(searchText); // Mettez à jour la valeur de recherche
        const filteredOptions = (userOptions || []).filter(user =>
            user.login.toLowerCase().includes(searchText.toLowerCase()) ||
            (user.personal_info?.firstname || '').toLowerCase().includes(searchText.toLowerCase()) ||
            (user.personal_info?.lastname || '').toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredUserOptions(filteredOptions);
    };

    return (
        <Card title={`${t('text.relation')}: ${title}`} style={{ marginBottom: 16 }}>
            {loading ? (
                <LoadingBox />
            ) : (
                <Form.List name={fieldName}>
                    {(fields, { add, remove }) => (
                        <>
                            <Form.Item>
                                <AutoComplete
                                    style={{ width: '100%' }}
                                    onSearch={handleSearch}
                                    onSelect={(value: string) => {
                                        const selectedUser = (filteredUserOptions || []).find(user => user.id.toString() === value);
                                        if (selectedUser) {
                                            const currentChildren = form.getFieldValue(fieldName) || [];
                                            // Vérifier si l'utilisateur est déjà ajouté
                                            const isDuplicate = currentChildren.some((child: TUserApp) => child.id === selectedUser.id);
                                            if (!isDuplicate) {
                                                // Ajouter uniquement les propriétés nécessaires
                                                const userToAdd = {
                                                    id: selectedUser.id,
                                                    login: selectedUser.login,
                                                    firstname: selectedUser.personal_info?.firstname || '',
                                                    lastname: selectedUser.personal_info?.lastname || '',
                                                    birthdate: selectedUser.personal_info?.birthdate || '',
                                                    typeRelationCodification: codifTypeRelation,
                                                };
                                                add(userToAdd);
                                                setSearchValue(''); // Vider la valeur de recherche
                                            } else {
                                                message.warning(t('message.already_exist'));
                                            }
                                        }
                                    }}
                                    value={searchValue} // Définir la valeur d'affichage
                                    onChange={(value) => setSearchValue(value)} // Mettez à jour la valeur de recherche
                                    placeholder={t('text.search')}
                                    options={(filteredUserOptions || []).map(user => ({
                                        value: user.id.toString(),
                                        label: `${user.login} (${user.personal_info?.firstname || ''} ${user.personal_info?.lastname || ''} ${user.personal_info?.birthdate ? `(${dayjs(user.personal_info.birthdate).format('DD/MM/YYYY')})` : ''})`,
                                    }))}
                                    filterOption={false}
                                />
                            </Form.Item>
                            <Table
                                dataSource={Array.isArray(childrenData) ? childrenData : []}
                                rowKey="id"
                                pagination={false}
                                columns={[
                                    {
                                        title: t('text.login'),
                                        dataIndex: 'login',
                                        key: 'login',
                                    },
                                    {
                                        title: t('text.name'),
                                        dataIndex: 'name',
                                        key: 'name',
                                        render: (_: any, record) => (
                                            <span>
                                                {record.lastname || ''} {record.firstname || ''}{' '}
                                                {record.birthdate ? `(${dayjs(record.birthdate).format('DD/MM/YYYY')})` : ''}
                                            </span>
                                        ),
                                    },
                                    {
                                        title: t('text.actions'),
                                        key: 'actions',
                                        render: (_: any, record, index: number) => (
                                            <Space size="middle" style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Popconfirm
                                                    title={t('message.confirm_delete')}
                                                    onConfirm={() => remove(index)}
                                                    okText={t('button.yes')}
                                                    cancelText={t('button.no')}
                                                >
                                                    <Button icon={<DeleteOutlined />} danger />
                                                </Popconfirm>
                                            </Space>
                                        ),
                                    },
                                ]}
                            />
                        </>
                    )}
                </Form.List>
            )}
        </Card>
    );
};

export default RelationManager;
