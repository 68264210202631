// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../BlocklyFields';
import i18next from 'i18next';

export function initMoveDownBlock() {

    Blockly.Blocks[BlocklyFields.MOVE_DOWN] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.MOVE_DOWN,
                "message0": '%1 ' + i18next.t('blockly.move.down.message', 'Move down'),
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/down.png", // Lien vers votre icône (chemin relatif ou absolu)
                        "width": 16,   // Largeur de l'image
                        "height": 16,  // Hauteur de l'image

                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#FFAB19', // Rose, par exemple
                "tooltip": i18next.t('blockly.move.down.tooltip', ''),
                "helpUrl": ""
            });
        }
    };

    javascriptGenerator.forBlock[BlocklyFields.MOVE_DOWN] = function (block, generator) {
        return 'await walkCharacter(90);\n';
    }
}
