import { TChapter, TCourse, TEpic } from "./course";
import { TLesson } from "./lesson";

// Fonction pour trouver une leçon dans le cours
export function findLessonInCourse(course: TCourse, lessonId: number): TLesson | undefined {
    for (const chapter of course.chapters) {
        for (const epic of chapter.epics) {
            for (const lesson of epic.lessons) {
                if (lesson.id === lessonId) {
                    return lesson;
                }
            }
        }
    }
    return undefined;
}

// Fonction pour trouver une épopée dans le cours
export function findEpicInCourse(course: TCourse, epicId: number): TEpic | null {
    if (course) {
        for (const chapter of course.chapters) {
            for (const epic of chapter.epics) {
                if (epic.id === epicId) {
                    return epic;
                }
            }
        }
    }
    return null;
}

export function getAllLessonsFromCourse(course: TCourse)  : TLesson[] {
    try {
        return course.chapters
        .filter((chapter: TChapter | null) => chapter !== null)
        .flatMap((chapter: TChapter) =>
            (chapter.epics ?? [])
                .filter((epic) => epic !== null)
                .flatMap((epic) => epic.lessons ?? [])
        );
    } catch{
        return []
    }
    
}