import { TLessonObstacle } from "../../models/courses/lesson";
import { TObstacle } from "../../blocks/core/type/obstacle";
import { getImagePath } from "../format/valueFormat";

export const TLessonObstacleTOObstacle = (
    lessonObstacle: TLessonObstacle | undefined
): TObstacle => {
    if (!lessonObstacle) {
        return {
            x: 0,
            y: 0,       // Position Y de l'obstacle
            width: 0,
            height: 0,
        };
    }

    return {
        x: lessonObstacle.x,
        y: lessonObstacle.y,
        width: lessonObstacle.width,
        height: lessonObstacle.height,
        image: getImagePath(lessonObstacle.obstacle.img ?? ''),
        rotate: lessonObstacle.rotate,
    };
};

export const TLessonObstaclesTOObstacles = (
    lessonObstacles: TLessonObstacle[] | undefined
): TObstacle[] => {
    if (!lessonObstacles) {
        return []
    }
    return lessonObstacles.map((obstacle) =>
        TLessonObstacleTOObstacle(obstacle)
    );
}