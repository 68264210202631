// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../BlocklyFields';
import i18next from 'i18next';

export function initMoveRightBlock() {
    Blockly.Blocks[BlocklyFields.MOVE_RIGHT] = {
        init: function () {
            this.jsonInit({
                type: BlocklyFields.MOVE_RIGHT,
                message0: '%1 ' + i18next.t('blockly.move.right.message', 'Move right'),
                args0: [
                    {
                        type: 'field_image',
                        src: '/images/blocks/right.png',
                        width: 16,
                        height: 16,
                    },
                ],
                previousStatement: null,
                nextStatement: null,
                colour: '#4C97FF',
                tooltip: i18next.t('blockly.move.right.tooltip', ''),
                helpUrl: '',
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.MOVE_RIGHT] = function (block) {
        return 'await walkCharacter(0);\n';
    };
}
