import React, { useEffect, useState } from 'react';
import { Layout, Typography, Button, Dropdown, Avatar, message, Divider, MenuProps, Space } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
// import { useTranslation } from 'react-i18next';  // Importer i18next
// import MAFlag from '../../assets/images/flag/morocco.png';
// import FRFlag from '../../assets/images/flag/france.png';
// import USFlag from '../../assets/images/flag/usa.png';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useAuthApi } from '../../api/login/login';

const { Header } = Layout;
const { Title } = Typography;
// const { Option } = Select;

const items: MenuProps['items'] = [];

const contentStyle: React.CSSProperties = {
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "3",
};
const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
};

interface NavbarProps {
    title: string;
    onToggleSidebar: () => void;
    isSidebarCollapsed: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ title, onToggleSidebar, isSidebarCollapsed }) => {
    const navigate = useNavigate();
    // const { i18n } = useTranslation();
    const { logout, getTimeRemaining, getFullName } = useAuth();
    const { apiLogOut } = useAuthApi();
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false); // État pour le chargement


    const timeRemaining: number = getTimeRemaining();
    const fullName: string | null = getFullName();
    const [timeLeft, setTimeLeft] = useState<number>(timeRemaining);

    // timer
    useEffect(() => {
        // Si timeRemaining est -1, on ne lance pas le minuteur
        if (timeRemaining === -1 || timeRemaining > 5400) {
            return;
        }

        // Fonction qui décrémente le temps restant toutes les secondes
        const timerId = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timerId); // Arrêter le minuteur quand il atteint 0
                    logout()
                    navigate('/'); // Rediriger vers la page de login
                    return 0; // Retourner 0 pour s'assurer que le temps est bien à 0
                }
                return prevTime - 1; // Décrémenter le temps restant
            });
        }, 1000); // Mise à jour toutes les 1000 ms (1 seconde)

        // Nettoyage du minuteur lorsque le composant est démonté
        return () => clearInterval(timerId);

        // eslint-disable-next-line
    }, [timeRemaining, navigate]);

    // Fonction pour changer la langue
    // const handleLanguageChange = (value: string) => {
    //     i18n.changeLanguage(value);
    // };

    const handleLogout = () => {
        setIsLoggingOut(true);
        apiLogOut().then((response) => {
            logout();
            navigate('/');
        })
            .catch((err) => {
                message.error(err.response.data ?? err.message)
            })
            .finally(() => {
                setIsLoggingOut(false);
            });

    }


    return (
        <Header
            style={{
                background: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 16px',
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
                zIndex: 2,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button type="text" onClick={onToggleSidebar} style={{ marginRight: '16px' }}>
                    {isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
                <Title level={4} style={{ margin: 0 }}>{title}</Title>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {timeLeft > 0 && timeLeft <= 5400 && (
                    <div style={{ marginRight: '16px', fontWeight: 'bold' }}>
                        Temps restant: {new Date(timeLeft * 1000).toISOString().slice(11, 19)}
                    </div>
                )}

                {/* Select pour changer la langue */}
                {/* <Select
                    defaultValue={i18n.language}  // Définit la langue par défaut
                    style={{ width: 120 }}
                    onChange={handleLanguageChange}
                >
                    <Option value="fr">
                        <img src={FRFlag} alt="Français" style={{ width: '16px', marginRight: '8px' }} />
                        Français
                    </Option>
                    <Option value="en">
                        <img src={USFlag} alt="English" style={{ width: '16px', marginRight: '8px' }} />
                        English
                    </Option>
                    <Option value="ar">
                        <img src={MAFlag} alt="Arabe" style={{ width: '16px', marginRight: '8px' }} />
                        العربية
                    </Option>
                </Select> */}

                {/* Dropdown pour le profil avec un avatar */}
                <Dropdown
                    menu={{ items }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                            <Divider style={{ margin: 0 }} />
                            <Space style={{ padding: 8 }}>
                                <Button
                                    type="text"
                                    onClick={handleLogout}
                                    icon={<LogoutOutlined />}
                                    loading={isLoggingOut}
                                >
                                    Déconnexion
                                </Button>
                            </Space>
                        </div>
                    )}
                >
                    <div style={{ cursor: 'pointer', userSelect: 'none' }}>
                        <Space>
                            {fullName ? fullName : "N/A"}
                            <Avatar style={{ marginLeft: 16 }} icon={<UserOutlined />} />
                        </Space>
                    </div>
                </Dropdown>
            </div>
        </Header>
    );
};

export default Navbar;
