// src/context/GameContext.tsx

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { GameState } from './GameState';
import { GameAction } from './GameAction';
import { gameReducer } from './gameReducer';
import { TCharacter } from '../../type/character';
import { TScene } from '../../type/scene';
import { TTarget } from '../../type/target';

interface GameContextProps {
    state: GameState;
    dispatch: React.Dispatch<GameAction>;
}

const GameContext = createContext<GameContextProps | undefined>(undefined);

export const useGameContext = () => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useGameContext must be used within a GameProvider');
    }
    return context;
};

interface GameProviderProps {
    initialCharacters: TCharacter[];
    initialScene: TScene;
    initialTarget: TTarget;
    children: React.ReactNode;
}

export const GameProvider: React.FC<GameProviderProps> = ({
    initialCharacters, initialScene, initialTarget, children
}) => {
    const [state, dispatch] = useReducer(gameReducer, {
        characters: initialCharacters,
        scene: initialScene,
        target: initialTarget,
        initialCharacters: initialCharacters,
        initialScene: initialScene,
        initialTarget: initialTarget,
    } as GameState);

    // Mise à jour du personnage
    useEffect(() => {
        dispatch({ type: 'INIT_ALL_CHARACTERS', payload: initialCharacters });
    }, [initialCharacters]);

    // Mise à jour de la scène
    useEffect(() => {
        dispatch({ type: 'UPDATE_SCENE', payload: initialScene });
    }, [initialScene]);
    // Mise à jour de target
    useEffect(() => {
        dispatch({ type: 'UPDATE_TARGET', payload: initialTarget });
    }, [initialTarget]);


    return <GameContext.Provider value={{ state, dispatch }}>{children}</GameContext.Provider>;
};
