import React from 'react';
import { Input, Select, Space } from 'antd';
import { TCharacter } from '../../../../models/objects/objects';
import { TLesson } from '../../../../models/courses/lesson';
import { getImagePath } from '../../../../utils/format/valueFormat';

const { Option } = Select;

interface CharacterProps {
    characters: TCharacter[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

// Fonction pour générer les options en fonction de la taille de la scène et du personnage
const generateOptions = (max: number, step: number) => {
    const options = [];
    for (let i = 0; i <= max; i += step) {
        options.push({ value: i, label: i.toString() });
    }
    return options;
};

const Character: React.FC<CharacterProps> = ({ characters, editingLesson, setEditingLesson }) => {
    const character = editingLesson?.characters?.[0];

    const handleChange = (Id: number) => {
        const selected = characters.find(e => e.id === Id);
        if (editingLesson && selected) {
            setEditingLesson({
                ...editingLesson,
                characters: [
                    {
                        ...(editingLesson.characters?.[0] || { id: 0, lesson_id: 0, x: 0, y: 0, width: 50, height: 50, scale: 1, displacementUnit: 50, canBePlayedWith: false, }),
                        character_id: selected.id,
                        character: selected,
                    }
                ]
            });
        }
    };


    const handleSelectChange = (name: string, value: number) => {
        if (editingLesson) {
            setEditingLesson({
                ...editingLesson,
                characters: [
                    {
                        ...(editingLesson.characters?.[0] || {
                            id: 0,
                            character_id: 0,
                            lesson_id: 0,
                            x: 0,
                            y: 0,
                            width: 50,
                            height: 50,
                            scale: 1,
                            displacementUnit: 50,
                            canBePlayedWith: false,
                            character: {} as TCharacter,
                        }),
                        [name]: value,
                    },
                ],
            });
        }
    };
    const handleInputChange = (name: string, value: string | number) => {
        if (editingLesson) {
            setEditingLesson({
                ...editingLesson,
                characters: [
                    {
                        ...(editingLesson.characters?.[0] || {
                            id: 0,
                            character_id: 0,
                            lesson_id: 0,
                            x: 0,
                            y: 0,
                            width: 50,
                            height: 50,
                            scale: 1,
                            displacementUnit: 50,
                            canBePlayedWith: false,
                            character: {} as TCharacter,
                        }),
                        [name]: value,
                    },
                ],
            });
        }
    };

    return (
        <Space direction="vertical">
            <Select
                placeholder={'Character'}
                style={{ width: '100%' }}
                onChange={handleChange}
                value={character?.character?.id ?? null}
            >
                {characters?.map(character => (
                    <Option key={character.id} value={character.id}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {character.images?.[0]?.img && (
                                <img
                                    src={getImagePath(character.images[0].img)}
                                    alt={character.name}
                                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                />
                            )}
                            <span>{character.name}</span>
                        </div>
                    </Option>
                ))}
            </Select>
            <Input
                name='width'
                addonBefore="width"
                value={editingLesson?.characters?.[0].width}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='height'
                addonBefore="height"
                value={editingLesson?.characters?.[0].height}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Select
                placeholder="Position X"
                style={{ width: '100%' }}
                value={character?.x}
                onChange={(value) => handleSelectChange('x', value)}
            >
                {generateOptions((editingLesson?.scene?.width ?? 0) - (character?.width || 50), character?.width || 50)
                    .map(option => (
                        <Option key={option.value} value={option.value}>
                            X: {option.label}
                        </Option>
                    ))}
            </Select>
            <Select
                placeholder="Position Y"
                style={{ width: '100%' }}
                value={character?.y}
                onChange={(value) => handleSelectChange('y', value)}
            >
                {generateOptions((editingLesson?.scene?.height ?? 0) - (character?.height || 50), character?.height || 50).map(option => (
                    <Option key={option.value} value={option.value}>
                        Y: {option.label}
                    </Option>
                ))}
            </Select>
            <Input
                name='scale'
                addonBefore="scale"
                value={editingLesson?.characters?.[0].scale}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 0;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
            <Input
                name='displacementUnit'
                addonBefore="displacementUnit"
                value={editingLesson?.characters?.[0].displacementUnit}
                onChange={(e) => {
                    if (editingLesson) {
                        const newvalue = parseFloat(e.target.value) || 50;
                        handleInputChange(e.target.name, newvalue)
                    }
                }}
            />
        </Space>
    );
};

export default Character;
