import { TLine } from "../../blocks/core/type/line";
import { TLessonLine } from "../../models/courses/lesson";

export const TLessonLineTOTLine = (
    lessonLine: TLessonLine | undefined
): TLine => {
    if (!lessonLine) {
        return {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            type: "solid",
            color: "graylight",
        }
    }
    return {
        x1: lessonLine.x1,
        y1: lessonLine.y1,
        x2: lessonLine.x2,
        y2: lessonLine.y2,
        type: "solid",
        color: "graylight",
    };
}

export const TLessonLinesTOTLines = (
    lessonLines: TLessonLine[] | undefined
): TLine[] => {
    if (!lessonLines) {
        return []
    }
    return lessonLines.map((line) =>
        TLessonLineTOTLine(line)
    );
}