import { BackgroundScene } from "../type/scene";
import zelija from "../../../assets/images/background/zelija.png"

export const VOID_SQUARE: BackgroundScene = {
    img: "",
    repeat: false,
}

export const ZELIJA: BackgroundScene = {
    img: zelija,
    repeat: true,
}