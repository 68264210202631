// src/blocklyConfig.ts   
import { BlocklyBlock } from '../type/blocks';
import * as Blockly from 'blockly';
import { BlocklyFields } from './BlocklyFields';


export const CheckNextCondition = (allBlocks: Blockly.BlockSvg[], processedBlockIds: string[]): boolean => {
    let nextIsNull = 0;

    for (const block of allBlocks) {
        // Ignorer les blocs déjà traités
        if (processedBlockIds.includes(block.id)) continue;

        processedBlockIds.push(block.id);

        if (block.type !== BlocklyFields.REPEAT) {
            if (block.getNextBlock() === null) {
                nextIsNull++;
                if (nextIsNull > 1) return false;
            }
        } else {
            let firstChildBlock = block.getInputTargetBlock('DO');
            let childrenBlocks: Blockly.BlockSvg[] = [];

            while (firstChildBlock) {
                childrenBlocks.push(firstChildBlock as Blockly.BlockSvg);
                firstChildBlock = firstChildBlock.getNextBlock();
            }
            if (!CheckNextCondition(childrenBlocks, processedBlockIds)) return false;
        }
    }

    return true;
};



// Fonction pour construire le XML des blocs initiaux à partir d'un objet JSON, 
// en gérant les connexions et les propriétés optionnelles deletable et movable
export function buildBlocksXmlFromJSON(blocks: any[]): string {

    let xml = '<xml xmlns="https://developers.google.com/blockly/xml">\n';

    function buildBlockXml(blockData: any): string {
        let blockXml = `  <block type="${blockData.type}" x="${blockData.x}" y="${blockData.y}"`;

        // Ajout conditionnel des attributs movable et deletable
        if ('movable' in blockData) {
            blockXml += ` movable="${blockData.movable}"`;
        }
        if ('deletable' in blockData) {
            blockXml += ` deletable="${blockData.deletable}"`;
        }

        blockXml += `>\n`;

        // Ajout des champs personnalisés (comme TIMES)
        if (blockData.fields) {
            for (const [fieldName, fieldValue] of Object.entries(blockData.fields)) {
                blockXml += `    <field name="${fieldName}">${fieldValue}</field>\n`;
            }
        }

        // Gestion des blocs imbriqués (connexions) pour le champ 'DO'
        if (blockData.inputs && blockData.inputs.DO) {
            blockXml += `    <statement name="DO">\n`;
            blockXml += buildBlockXml(blockData.inputs.DO.block);
            blockXml += `    </statement>\n`;
        }

        // Gestion des connexions 'next' (blocs qui suivent)
        if (blockData.next) {
            blockXml += `    <next>\n`;
            blockXml += buildBlockXml(blockData.next.block);
            blockXml += `    </next>\n`;
        }

        blockXml += '  </block>\n';
        return blockXml;
    }

    for (const block of blocks) {
        xml += buildBlockXml(block);
    }

    xml += '</xml>';
    return xml;
};



export const createBlocklyBlocks = (blocks: { type: string; maxUsage?: number }[]): BlocklyBlock[] => {
    return blocks.map(block => ({
        kind: "block",  // Tous les blocs ont "kind" égal à "block"
        type: block.type,
        ...(block.maxUsage && { maxUsage: block.maxUsage }),  // Ajouter maxUsage seulement si défini
    }));
};




