// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../BlocklyFields';
import i18next from 'i18next';

export function initMoveUpBlock() {

    Blockly.Blocks[BlocklyFields.MOVE_UP] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.MOVE_UP,
                "message0": '%1 ' + i18next.t('blockly.move.up.message', 'Move up'),
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/up.png", // Lien vers votre icône (chemin relatif ou absolu)
                        "width": 16,   // Largeur de l'image
                        "height": 16,  // Hauteur de l'image

                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#9966FF',
                "tooltip": i18next.t('blockly.move.up.tooltip', ''),
                "helpUrl": ""
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.MOVE_UP] = function (block, generator) {
        return 'await walkCharacter(270);\n';
    }
}
