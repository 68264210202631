

export const ENDPOINTS_USERAPP = {
    GET_ALL: '/userapps',
    GET_BY_ID: (id: number) => `/userapps/${id}`,
    POST: '/userapps',
    PUT: (id: number) => `/userapps/${id}`,
    DELETE: (id: number) => `/userapps/${id}`,
    POST_GENERATE_LOGIN: `/userapps/generatelogin`,

    GET_ALL_AFFECTED_DETAILS: '/users_details',
    GET_USER_DETAILS: (id: number) => `/users_details/${id}`,
    GET_ALL_BY_TYPE: (typeRelationId: number, typeUserParentId: number) => `/users_details/search/${typeRelationId}/${typeUserParentId}`,

    POST_SAVE_DETAILS: '/users_details/save',
};

export const ENDPOINTS_PASSWORDS = {
    GET_BY_IDUSERAPP: (id: number) => `/passwords/userapp/${id}`,
    POST: '/passwords',
    PUT: (id: number) => `/passwords/${id}`,
    DELETE: (id: number) => `/passwords/${id}`,
};

export const ENDPOINTS_PROGRESS = {
    GET_BY_IDCOURSE: (course_id: number) => `/lesson-progress/course/${course_id}`, // user ID is in token data
    GET_BY_IDLESSON: (lesson_id: number) => `/lesson-progress/lesson/${lesson_id}`,
    POST_SAVE: (lesson_id: number) => `/lesson-progress/${lesson_id}`,
    
    GET_PROGRESS_BY_IDCOURSE: (course_id: number) => `/lesson-progress/progress/course/${course_id}`, // user ID is in token data
    GET_PROGRESS_BY_IDSUBJECT: (subject_id: number) => `/lesson-progress/progress/subject/${subject_id}`, // user ID is in token data
};


export const ENDPOINTS_ROLES = {
    GET_ALL: `/roles`,

    GEL_ALL_LINKED_ROLES: `/role_usertypes`,
    POST_ROLE_TO_TYPE_USER: `/role_usertypes`,
    DELETE_ROLE_TO_TYPE_USER: (id: number) => `/role_usertypes/${id}`,
};
