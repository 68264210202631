import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import MovableImage from '../../../../components/imageManipulation/MovableImage';
import part1 from '../../../../assets/images/puzzle/H/1.png';
import part2 from '../../../../assets/images/puzzle/H/2.png';
import part3 from '../../../../assets/images/puzzle/H/3.png';
import { useTranslation } from 'react-i18next';

interface DnDLevelTwelveProps {
    checkResult: (isValid: boolean) => void; // Prop pour informer le parent que l'exercice est complété
}

const DnDLevelTwelve: React.FC<DnDLevelTwelveProps> = ({ checkResult }) => {
    const { t } = useTranslation();

    // Suivi des cibles spécifiques
    const [targetStatus, setTargetStatus] = useState<{ [key: number]: boolean }>({
        1: false, // Image 1 dans la cible 1
        2: false,  // Image 2 dans la cible 2
        3: false
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [isMoved, setIsMoved] = useState(false);

    const handleMove = () => {
        setIsMoved(true);
    };

    // Création des références pour chaque cible
    const targetRef1 = useRef<HTMLDivElement>(null); // Référence pour cible 1
    const targetRef2 = useRef<HTMLDivElement>(null); // Référence pour cible 2
    const targetRef3 = useRef<HTMLDivElement>(null); // Référence pour cible 2

    // Gestion des changements d'état des cibles
    const handleTargetChange = (imageId: number, isOnTarget: boolean) => {
        setTargetStatus(prevStatus => ({
            ...prevStatus,
            [imageId]: isOnTarget
        }));
    };

    // Validation lorsque toutes les images sont dans leurs bonnes cibles
    useEffect(() => {
        const allImagesInPlace = Object.values(targetStatus).every(status => status === true);
        checkResult(allImagesInPlace);

        setIsSuccess(allImagesInPlace);

    }, [targetStatus, checkResult]);

    useEffect(() => {
        if (isMoved) {
            if (isSuccess) {
                message.success(t("message.bravo"));
            }
        }
        // eslint-disable-next-line
    }, [isSuccess]);
    return (

        <Row justify="center">
            <Col span={12}>

                {/* Zone Cible 1 */}
                <div
                    ref={targetRef1}
                    style={{
                        position: 'absolute',
                        top: '102px',
                        right: '90px',
                        width: '200px',
                        height: '80px',
                        backgroundImage: `url("${part1}")`,
                        opacity: 0.4,
                        backgroundSize: `200px 80px`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                >
                </div>

                {/* Zone Cible 2 */}
                <div
                    ref={targetRef2}
                    style={{
                        position: 'absolute',
                        top: '167px',
                        right: '90px',
                        width: '200px',
                        height: '80px',
                        backgroundImage: `url("${part2}")`,
                        opacity: 0.4,
                        backgroundSize: `200px 80px`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                >
                </div>

                {/* Zone Cible 3 */}
                <div
                    ref={targetRef3}
                    style={{
                        position: 'absolute',
                        top: '235px',
                        right: '90px',
                        width: '200px',
                        height: '80px',
                        backgroundImage: `url("${part3}")`,
                        opacity: 0.4,
                        backgroundSize: `200px 80px`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                >
                </div>

                {/* Image 1 */}
                <MovableImage
                    onMove={handleMove}
                    targetRef={targetRef3}
                    onTarget={(isOnTarget) => handleTargetChange(1, isOnTarget)}
                    src={part3}
                    initialX={30}
                    initialY={100}
                    width='200px'
                    height='80px'
                    magnet={true}
                />

                {/* Image 2 */}
                <MovableImage
                    onMove={handleMove}
                    targetRef={targetRef1}
                    onTarget={(isOnTarget) => handleTargetChange(2, isOnTarget)}
                    src={part1}
                    initialX={100}
                    initialY={200}
                    width='200px'
                    height='80px'
                    magnet={true}
                />

                {/* Image 2 */}
                <MovableImage
                    onMove={handleMove}
                    targetRef={targetRef2}
                    onTarget={(isOnTarget) => handleTargetChange(3, isOnTarget)}
                    src={part2}
                    initialX={60}
                    initialY={300}
                    width='200px'
                    height='80px'
                    magnet={true}
                />

            </Col>
        </Row>
    );
};

export default DnDLevelTwelve;
