import React from 'react';
import { message } from 'antd';
import BlocklyMouv from '../../../../blocks/v1/BlocklyMouv';
import { useTranslation } from 'react-i18next';
import { BlocklyFields } from '../../../../blocks/v1/config/fields';
import { FAIZAN } from '../../../../blocks/v1/constant/character';
import { TARGET_BATTERY } from '../../../../blocks/v1/constant/target';
import { ZELIJA } from '../../../../blocks/v1/constant/scene';

interface LoopsLaurelLevelTwoProps {
    checkResult: (isValid: boolean) => void; // Prop pour envoyer le résultat de l'exercice au parent
}

const LoopsLaurelLevelTwo: React.FC<LoopsLaurelLevelTwoProps> = ({ checkResult }) => {
    const { t } = useTranslation();

    const isValid = (isValid: boolean) => {
        checkResult(isValid)
        if (isValid) {
            message.success(t("message.bravo"));
        }
    }
    return (
        <>
            <BlocklyMouv
                gridSize={{ side: 5 }}
                initialObjectPosition={{ row: 2, col: 0, scale: 1, rotate: 0, object: FAIZAN }}
                targetPositions={[
                    { row: 2, col: 2, object: TARGET_BATTERY },
                    { row: 2, col: 3, object: TARGET_BATTERY },
                    { row: 2, col: 4, object: TARGET_BATTERY },
                ]}
                mustReachAllTargets={true}
                mustRespectOrder={false}
                toolbox={{
                    "contents": [
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_RIGHT
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_DOWN
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_LEFT
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.MOVE_UP
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.GET_TARGET,
                        },
                        {
                            "kind": "block",
                            "type": BlocklyFields.REPEAT,
                        }
                    ]
                }}
                initialBlocksJson={
                    [

                        {
                            type: BlocklyFields.STARTBOX,
                            x: 10, y: 10,
                            deletable: false,
                        }
                    ]
                }
                onTargetReached={isValid}

                autoTargetReaching={false}
                bg={ZELIJA}

            />
        </>
    );
};

export default LoopsLaurelLevelTwo;
