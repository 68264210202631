// AuthRedirect.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import routeConfigs from './routeConfig';
import Login from '../pages/auth/auth/Login'; // Assurez-vous d'importer Login

const AuthRedirect: React.FC = () => {
    const { getToken, isRoleExist } = useAuth();

    const getFirstAccessibleRoute = () => {
        for (const route of routeConfigs) {
            if (route.isProtected && route.requiredRoles) {
                if (route.requiredRoles.some(role => isRoleExist(role))) {
                    return route.path;
                }

            }
        }
        for (const route of routeConfigs) {
            if (!route.isProtected) {
                return route.path;
            }
        }
        return '/access-denied';
    };

    if (getToken()) {
        const firstAccessibleRoute = getFirstAccessibleRoute();
        return <Navigate to={firstAccessibleRoute} />;
    }

    return <Login />;
};

export default AuthRedirect;
