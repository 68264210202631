// AccessDenied.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const AccessDenied: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Result
            status="403"
            title="403"
            subTitle={t('message.access_denied')}
            extra={
                <Button type="primary" onClick={() => navigate('/')}>
                    {t('button.back_home')}
                </Button>
            }
        />
    );
};

export default AccessDenied;
