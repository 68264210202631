export type TObstacle = {
    x: number;        // Position X de l'obstacle
    y: number;        // Position Y de l'obstacle
    width: number;
    height: number;
    image?: string;
    rotate?: number;
}

interface OptionalObstacleParams {
    rotate?: number;  // Rotation optionnelle pour l'obstacle
}

export const createObstacle = (
    x: number,
    y: number,
    width: number,
    height: number,
    image: string,
    options: OptionalObstacleParams = {}  // Paramètres optionnels groupés dans un objet
): TObstacle => {
    return {
        x,
        y,
        width,
        height,
        // Valeurs par défaut ou fournies par l'utilisateur
        image: image,  // Si aucune image n'est fournie, laisser vide
        rotate: options.rotate ?? 0  // Rotation par défaut = 0 si non spécifiée
    };
};