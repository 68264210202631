import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import i18next from 'i18next';
import { BlocklyFields } from '../BlocklyFields';

export function initSelectImageBlock() {

    Blockly.Blocks[BlocklyFields.SELECT_IMAGE] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.SELECT_IMAGE,
                "message0": '%1 ' + i18next.t('blockly.style.selectImage.message', 'Select an image'),
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "IMAGE_SELECTION",
                        "options": [
                            [
                                {
                                    "src": "/images/background/footground.png", // Première image
                                    "width": 32,
                                    "height": 32,
                                    "alt": "Image 1"
                                },
                                "/images/background/footground.png"
                            ],
                            [
                                {
                                    "src": "/images/background/rugbyground.png", // Deuxième image
                                    "width": 32,
                                    "height": 32,
                                    "alt": "Image 2"
                                },
                                "/images/background/rugbyground.png"
                            ],
                            [
                                {
                                    "src": "/images/background/tennisground.png", // Troisième image
                                    "width": 32,
                                    "height": 32,
                                    "alt": "Image 3"
                                },
                                "/images/background/tennisground.png"
                            ],
                            [
                                {
                                    "src": "/images/background/volleyground.png", // Quatrième image
                                    "width": 32,
                                    "height": 32,
                                    "alt": "Image 4"
                                },
                                "/images/background/volleyground.png"
                            ],
                        ],
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#4A90E2',
                "tooltip": i18next.t('blockly.style.selectImage.tooltip', 'Select an image from the dropdown'),
                "helpUrl": ""
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.SELECT_IMAGE] = function (block) {
        // Obtenir le src de l'image sélectionnée dans le champ dropdown
        const imageSrc = block.getFieldValue('IMAGE_SELECTION');

        // Générer le code JavaScript correspondant
        return `setImage('${imageSrc}');\n`;
    }
}
