import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useCourseApi } from '../../../api/courses/courses';
import { TSubject, TCourse } from '../../../models/courses/course';
import { Button, Input, message, Space, Spin, Switch, Table } from 'antd';
import { SaveOutlined, EyeOutlined, EditOutlined, UploadOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { getImagePath } from '../../../utils/format/valueFormat';
import FloatButtons from '../../../components/button/FloatButtons';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';
import { CODIFICATION_ROLES } from '../../../constant/codification';
import { useAuth } from '../../../context/AuthContext';

const CourseList: React.FC = () => {
    const { t } = useTranslation();
    const { isRoleExist } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const { getCoursesBySubjectIdAdmin, updateCourse, createCourse, deleteCourse, uploadCourseImage } = useCourseApi();
    const [courses, setCourses] = useState<TCourse[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const [loadingRow, setLoadingRow] = useState<number | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

    // We assume that the course object is passed via location.state from another component
    const subject: TSubject = location.state?.subject;

    useEffect(() => {
        if (!subject) {
            message.error('No subject selected');
            return;
        }

        setIsLoading(true);
        getCoursesBySubjectIdAdmin(subject.id)
            .then((resp) => {
                setCourses(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [subject]);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingCourse, setEditingCourse] = useState<TCourse | null>(null);

    const edit = (course: TCourse) => {
        setEditingKey(course.id);
        setEditingCourse({ ...course });
    };

    const handleAddCourse = () => {
        const idRow = addingId - 1;
        const newCourse: TCourse = {
            id: idRow,
            title: '',
            subject_id: subject.id,// Attach the subject ID
            description: '',
            image: '',
            min_age: null,
            max_age: null,
            actif: false,
            course_order: 0,
            progression_state: null,
            chapters: [],
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingCourse(newCourse);
        setCourses((prev) => [newCourse, ...(prev || [])]);
    };

    const save = (id: number) => {
        if (editingCourse) {
            setLoadingRow(id);
            (editingCourse.id > 0 ? updateCourse(editingCourse.id, editingCourse) : createCourse(editingCourse))
                .then((resp) => {
                    const courseResult: TCourse = resp.data;
                    const updatedCourses = courses.map((course) =>
                        course.id === id ? courseResult : course
                    );
                    setCourses(updatedCourses);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                    setLoadingRow(null);
                });
        }

        setEditingKey(null);
    };
    const cancel = () => {
        setEditingKey(null);
        setEditingCourse(null);
    }
    const showDetails = (course: TCourse) => {
        navigate('/setup/chapter', { state: { course } });
    };

    const uploadImage = (course: TCourse) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                setLoadingRow(course.id);
                const formData = new FormData();
                formData.append('image', file);

                uploadCourseImage(course.id, formData)
                    .then((resp) => {
                        const courseResult: TCourse = resp.data;
                        const updatedCourses = courses.map((crs) =>
                            crs.id === course.id ? courseResult : crs
                        );
                        setCourses(updatedCourses);
                        message.success(t('message.savedSuccessfully'));
                    })
                    .catch((err) => {
                        message.error(err.response.data ?? err.message);
                        console.error(err);
                    })
                    .finally(() => {
                        setLoadingRow(null);
                    });
            }
        };

        input.click();
    };

    // Delete functionality
    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteCourse(deleteId)
            .then(() => {
                setCourses(courses.filter((course) => course.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };

    const columns: ColumnsType<TCourse> = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 90,
            render: (text: string, record: TCourse) =>
                record.image ? (
                    <img
                        src={getImagePath(record.image, true)}
                        alt="course-thumbnail"
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                ) : (
                    'N/A'
                ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: TCourse) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCourse?.title}
                        onChange={(e) => {
                            if (editingCourse) {
                                setEditingCourse({ ...editingCourse, title: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string, record: TCourse) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCourse?.description}
                        onChange={(e) => {
                            if (editingCourse) {
                                setEditingCourse({ ...editingCourse, description: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Ordre',
            dataIndex: 'course_order',
            key: 'course_order',
            width: 90,
            render: (text: number, record: TCourse) =>
                editingKey === record.id ? (
                    <Input
                        type="number"
                        value={editingCourse?.course_order}
                        onChange={(e) => {
                            if (editingCourse) {
                                setEditingCourse({ ...editingCourse, course_order: parseInt(e.target.value) });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Min Age',
            dataIndex: 'min_age',
            key: 'min_age',
            width: 90,
            render: (text: number | null, record: TCourse) =>
                editingKey === record.id ? (
                    <Input
                        type="number"
                        value={editingCourse?.min_age ?? ''}
                        onChange={(e) => {
                            if (editingCourse) {
                                setEditingCourse({
                                    ...editingCourse,
                                    min_age: e.target.value ? parseInt(e.target.value) : null,
                                });
                            }
                        }}
                    />
                ) : (
                    text ?? 'N/A'
                ),
        },
        {
            title: 'Max Age',
            dataIndex: 'max_age',
            key: 'max_age',
            width: 90,
            render: (text: number | null, record: TCourse) =>
                editingKey === record.id ? (
                    <Input
                        type="number"
                        value={editingCourse?.max_age ?? ''}
                        onChange={(e) => {
                            if (editingCourse) {
                                setEditingCourse({
                                    ...editingCourse,
                                    max_age: e.target.value ? parseInt(e.target.value) : null,
                                });
                            }
                        }}
                    />
                ) : (
                    text ?? 'N/A'
                ),
        },
        {
            title: 'Active',
            dataIndex: 'actif',
            key: 'actif',
            width: 90,
            render: (actif: boolean, record: TCourse) =>
                editingKey === record.id ? (
                    <Switch
                        checked={editingCourse?.actif}
                        onChange={(checked) => {
                            if (editingCourse) {
                                setEditingCourse({ ...editingCourse, actif: checked });
                            }
                        }}
                    />
                ) : (
                    <Switch checked={actif} disabled />
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 240,
            render: (_: any, record: TCourse) => (
                <Space size="middle">
                    {loadingRow === record.id ? ( // Affiche le spinner si la ligne est en chargement
                        <Spin />
                    ) : (
                        editingKey === record.id ? (

                            <>
                                <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                                <Button icon={<StopOutlined />} onClick={cancel} />
                            </>
                        ) : (
                            <>
                                <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                                <Button icon={<UploadOutlined />} onClick={() => uploadImage(record)} />
                                <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />

                                {
                                    isRoleExist(CODIFICATION_ROLES.DELETE_COURSE) && (
                                        <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => confirmDelete(record.id)}
                                            loading={deleteId === record.id && deleteLoading}
                                        />
                                    )
                                }
                            </>
                        )
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={t('text.availableCourse')} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddCourse}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TCourse>
                columns={columns}
                dataSource={courses}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />

            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this course? This action is irreversible."
            />

            <FloatButtons />
        </AppLayout>
    );
};

export default CourseList;
