// useApiFunctions.js
import { TSubject, TChapter, TCourse, TEpic } from '../../models/courses/course';
import { TLesson } from '../../models/courses/lesson';
import { useApiClient } from '../apiClient';
import { ENDPOINTS_SUBJECTS, ENDPOINTS_CHAPTERS, ENDPOINTS_COURSES, ENDPOINTS_EPICS, ENDPOINTS_LESSONS } from './endpoints';

export const useCourseApi = () => {
    const apiClient = useApiClient();

    //Subjects
    const getSubjects = () => apiClient.get<TSubject[]>(ENDPOINTS_SUBJECTS.GET_ALL);
    const getSubjectsActif = () => apiClient.get<TSubject[]>(ENDPOINTS_SUBJECTS.GET_ALL_ACTIF);
    const getSubjectsById = (subjectId: number) => apiClient.get<TSubject>(ENDPOINTS_SUBJECTS.GET_BY_ID(subjectId));
    const getSubjectsByIdCourse = (courseId: number) => apiClient.get<TSubject>(ENDPOINTS_SUBJECTS.GET_BY_IDCOURSE(courseId));
    const getSubjectsAndProgress = () => apiClient.get<TSubject[]>(ENDPOINTS_SUBJECTS.GET_SUBJECTS_AND_PROGRESS);
    const createSubject = (subject: TSubject) => apiClient.post<TSubject>(ENDPOINTS_SUBJECTS.POST_SUBJECT, subject);
    const updateSubject = (subjectId: number, subject: TSubject) => apiClient.put<TSubject>(ENDPOINTS_SUBJECTS.PUT_SUBJECT(subjectId), subject);
    const deleteSubject = (subjectId: number) => apiClient.delete(ENDPOINTS_SUBJECTS.DELETE_SUBJECT(subjectId));
    const uploadSubjectImage = (subjectId: number, formData: FormData) => {
        return apiClient.post<TSubject>(ENDPOINTS_SUBJECTS.UPLOAD_IMAGE(subjectId), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };

    // Courses
    const getCourses = () => apiClient.get<TCourse[]>(ENDPOINTS_COURSES.GET_ALL);
    const getCoursesAdmin = () => apiClient.get<TCourse[]>(ENDPOINTS_COURSES.GET_ALL_ADMIN);
    const getCoursesDetailsById = (courseId: number) => apiClient.get<TCourse>(ENDPOINTS_COURSES.GET_DETAILS_BY_ID(courseId));
    const getCoursesDetailsByIdLesson = (lessonId: number) => apiClient.get<TCourse>(ENDPOINTS_COURSES.GET_DETAILS_BY_IDLESSON(lessonId));
    const createCourse = (course: TCourse) => apiClient.post<TCourse>(ENDPOINTS_COURSES.POST_COURSE, course);
    const updateCourse = (courseId: number, course: TCourse) => apiClient.put<TCourse>(ENDPOINTS_COURSES.PUT_COURSE(courseId), course);
    const deleteCourse = (courseId: number) => apiClient.delete(ENDPOINTS_COURSES.DELETE_COURSE(courseId));
    const getCoursesBySubjectId = (subjectId: number) => apiClient.get<TCourse[]>(ENDPOINTS_COURSES.GET_COURSES_BY_SUBJECT_ID(subjectId));
    const getCoursesBySubjectIdAndProgress = (subjectId: number) => apiClient.get<TCourse[]>(ENDPOINTS_COURSES.GET_COURSES_BY_SUBJECT_ID_AND_PROGRESS(subjectId));
    const getCoursesBySubjectIdAdmin = (subjectId: number) => apiClient.get<TCourse[]>(ENDPOINTS_COURSES.GET_COURSES_BY_SUBJECT_ID_ADMIN(subjectId));
    const uploadCourseImage = (courseId: number, formData: FormData) => {
        return apiClient.post<TCourse>(ENDPOINTS_COURSES.UPLOAD_IMAGE(courseId), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };

    // Chapters
    const getAllChapters = () => apiClient.get<TChapter[]>(ENDPOINTS_CHAPTERS.GET_ALL);
    const getChapterById = (chapterId: number) => apiClient.get<TChapter>(ENDPOINTS_CHAPTERS.GET_BY_ID(chapterId));
    const createChapter = (data: TChapter) => apiClient.post(ENDPOINTS_CHAPTERS.POST_CHAPTER, data);
    const updateChapter = (chapterId: number, data: TChapter) => apiClient.put(ENDPOINTS_CHAPTERS.PUT_CHAPTER(chapterId), data);
    const deleteChapter = (chapterId: number) => apiClient.delete(ENDPOINTS_CHAPTERS.DELETE_CHAPTER(chapterId));
    const getChaptersByCourseId = (courseId: number) => apiClient.get<TChapter[]>(ENDPOINTS_CHAPTERS.GET_CHAPTERS_BY_COURSE_ID(courseId));

    // Epics
    const getAllEpics = () => apiClient.get<TEpic[]>(ENDPOINTS_EPICS.GET_ALL);
    const getEpicById = (epicId: number) => apiClient.get<TEpic>(ENDPOINTS_EPICS.GET_BY_ID(epicId));
    const createEpic = (data: TEpic) => apiClient.post(ENDPOINTS_EPICS.POST_EPIC, data);
    const updateEpic = (epicId: number, data: TEpic) => apiClient.put(ENDPOINTS_EPICS.PUT_EPIC(epicId), data);
    const deleteEpic = (epicId: number) => apiClient.delete(ENDPOINTS_EPICS.DELETE_EPIC(epicId));
    const getEpicsByChapterId = (chapterId: number) => apiClient.get<TEpic[]>(ENDPOINTS_EPICS.GET_EPICS_BY_CHAPTER_ID(chapterId));

    // Lessons
    const getAllLessons = () => apiClient.get<TLesson[]>(ENDPOINTS_LESSONS.GET_ALL);
    const getLessonById = (lessonId: number) => apiClient.get<TLesson>(ENDPOINTS_LESSONS.GET_BY_ID(lessonId));
    const getLessonDetailsById = (lessonId: number) => apiClient.get<TLesson>(ENDPOINTS_LESSONS.GET_DETAILS_BY_ID(lessonId));
    const saveLessonDetails = (data: TLesson) => apiClient.post<TLesson>(ENDPOINTS_LESSONS.POST_SAVE_LESSON_DETAILS, data);
    const createLesson = (data: TLesson) => apiClient.post(ENDPOINTS_LESSONS.POST_LESSON, data);
    const updateLesson = (lessonId: number, data: TLesson) => apiClient.put(ENDPOINTS_LESSONS.PUT_LESSON(lessonId), data);
    const duplicateLesson = (lessonId: number) => apiClient.post<TLesson>(ENDPOINTS_LESSONS.POST_DUPLICATE(lessonId));
    const deleteLesson = (lessonId: number) => apiClient.delete(ENDPOINTS_LESSONS.DELETE_LESSON(lessonId));
    const getLessonsByEpicId = (epicId: number) => apiClient.get(ENDPOINTS_LESSONS.GET_LESSONS_BY_EPIC_ID(epicId));

    return {

        getSubjects,
        getSubjectsActif,
        getSubjectsById,
        getSubjectsByIdCourse,
        createSubject,
        updateSubject,
        deleteSubject,
        uploadSubjectImage,
        getSubjectsAndProgress,

        getCourses,
        getCoursesAdmin,
        createCourse,
        updateCourse,
        deleteCourse,
        getCoursesDetailsById,
        getCoursesDetailsByIdLesson,
        getCoursesBySubjectId,
        getCoursesBySubjectIdAndProgress,
        getCoursesBySubjectIdAdmin,
        uploadCourseImage,

        getAllChapters,
        getChapterById,
        createChapter,
        updateChapter,
        deleteChapter,
        getChaptersByCourseId,

        getAllEpics,
        getEpicById,
        createEpic,
        updateEpic,
        deleteEpic,
        getEpicsByChapterId,

        getAllLessons,
        getLessonById,
        getLessonDetailsById,
        saveLessonDetails,
        createLesson,
        updateLesson,
        duplicateLesson,
        deleteLesson,
        getLessonsByEpicId,
    };
};
