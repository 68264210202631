// src/blocklyConfig.ts 
import 'blockly/blocks';
import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from './fields';
import { t } from 'i18next';



Blockly.Blocks[BlocklyFields.STARTBOX] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.STARTBOX, // Identifiant unique du bloc
            "message0": t('blockly.starting_box.message', 'Case 1'), // Texte affiché sur le bloc
            "previousStatement": undefined, // Ne peut pas se connecter à un bloc précédent
            "nextStatement": null, // Peut se connecter à un bloc suivant
            "colour": '#FFCC00', // Couleur distinctive (vert par exemple)
            "tooltip": t('blockly.starting_box.tooltip', 'Point départ'), // Info-bulle
            "helpUrl": "" // Lien vers une aide (optionnel)
        });
    },
    generator: function (block: Blockly.Block) {
        return ''; // Ne génère aucun code JavaScript
    }
};
javascriptGenerator.forBlock[BlocklyFields.STARTBOX] = function (block, generator) {
    return '\n';
}


// Définition du bloc personnalisé BlocklyFields.MOVE_RIGHT et de son générateur
Blockly.Blocks[BlocklyFields.MOVE_RIGHT] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.MOVE_RIGHT,
            "message0": '%1 ' + t('blockly.move.right.message', 'Move right'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/right.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#4C97FF',
            "tooltip": t('blockly.move.right.tooltip', ''),
            "helpUrl": ""
        });

    },
    generator: function (block: Blockly.Block) {
        return 'moveRight();\n';
    }
};
javascriptGenerator.forBlock[BlocklyFields.MOVE_RIGHT] = function (block, generator) {
    return 'moveRight();\n';
}


// move_left
Blockly.Blocks[BlocklyFields.MOVE_LEFT] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.MOVE_LEFT,
            "message0": '%1 ' + t('blockly.move.left.message', 'Move left'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/left.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#FF6680', // Vous pouvez choisir une autre couleur si vous le souhaitez
            "tooltip": t('blockly.move.right.tooltip', ''),
            "helpUrl": ""
        });
    },
    generator: function (block: Blockly.Block) {
        return 'moveLeft();\n'; // Ou ajustez la distance si nécessaire
    }
};

javascriptGenerator.forBlock[BlocklyFields.MOVE_LEFT] = function (block, generator) {
    return 'moveLeft();\n';
}

// move_up
Blockly.Blocks[BlocklyFields.MOVE_UP] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.MOVE_UP,
            "message0": '%1 ' + t('blockly.move.up.message', 'Move up'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/up.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#9966FF',
            "tooltip": t('blockly.move.up.tooltip', ''),
            "helpUrl": ""
        });
    },
    generator: function (block: Blockly.Block) {
        return 'moveUp();\n';
    }
};

javascriptGenerator.forBlock[BlocklyFields.MOVE_UP] = function (block, generator) {
    return 'moveUp();\n';
}

// move_down
Blockly.Blocks[BlocklyFields.MOVE_DOWN] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.MOVE_DOWN,
            "message0": '%1 ' + t('blockly.move.down.message', 'Move down'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/down.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#FFAB19', // Rose, par exemple
            "tooltip": t('blockly.move.down.tooltip', ''),
            "helpUrl": ""
        });
    },
    generator: function (block: Blockly.Block) {
        return 'moveDown();\n';
    }
};

javascriptGenerator.forBlock[BlocklyFields.MOVE_DOWN] = function (block, generator) {
    return 'moveDown();\n';
}

// Définition du bloc personnalisé JUMP
Blockly.Blocks[BlocklyFields.JUMP_RIGHT] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.JUMP_RIGHT,
            "message0": '%1 ' + t('blockly.jump.right.message', 'Jump right'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/right.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#3A7DD8',
            "tooltip": t('blockly.jump.right.tooltip', ''),
            "helpUrl": ""
        });
    },
    generator: function (block: Blockly.Block) {
        return 'jumpRight();\n';
    }
};
javascriptGenerator.forBlock[BlocklyFields.JUMP_RIGHT] = function (block, generator) {
    return 'jumpRight();\n';
}

Blockly.Blocks[BlocklyFields.JUMP_LEFT] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.JUMP_LEFT,
            "message0": '%1 ' + t('blockly.jump.left.message', 'Jump left'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/left.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#E55570',
            "tooltip": t('blockly.jump.left.tooltip', ''),
            "helpUrl": ""
        });
    },
    generator: function (block: Blockly.Block) {
        return 'jumpLeft();\n';
    }
};
javascriptGenerator.forBlock[BlocklyFields.JUMP_LEFT] = function (block, generator) {
    return 'jumpLeft();\n';
}

Blockly.Blocks[BlocklyFields.JUMP_UP] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.JUMP_UP,
            "message0": '%1 ' + t('blockly.jump.up.message', 'Jump Up'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/up.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#7A55CC',
            "tooltip": t('blockly.jump.up.tooltip', ''),
            "helpUrl": ""
        });

    },
    generator: function (block: Blockly.Block) {
        return 'jumpUp();\n';
    }
};
javascriptGenerator.forBlock[BlocklyFields.JUMP_UP] = function (block, generator) {
    return 'jumpUp();\n';
}

Blockly.Blocks[BlocklyFields.JUMP_DOWN] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.JUMP_DOWN,
            "message0": '%1 ' + t('blockly.jump.down.message', 'Jump down'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/down.png", // Lien vers votre icône (chemin relatif ou absolu)
                    "width": 16,   // Largeur de l'image
                    "height": 16,  // Hauteur de l'image

                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#E69A14',
            "tooltip": t('blockly.jump.down.tooltip', ''),
            "helpUrl": ""
        });

    },
    generator: function (block: Blockly.Block) {
        return 'jumpDown();\n';
    }
};
javascriptGenerator.forBlock[BlocklyFields.JUMP_DOWN] = function (block, generator) {
    return 'jumpDown();\n';
}



Blockly.Blocks[BlocklyFields.REPEAT] = {
    init: function () {
        this.jsonInit({
            "type": BlocklyFields.REPEAT,
            "message0": t('blockly.repeat.message', 'repeat'),
            "args0": [
                {
                    "type": "field_image",
                    "src": "/images/blocks/repeat.png",  // Chemin relatif vers l'icône de répétition
                    "width": 16,
                    "height": 16,
                    "alt": "*",  // Texte alternatif pour l'icône
                },
                {
                    "type": "field_dropdown",
                    "name": "TIMES",
                    "options": [
                        ['1', '1'],
                        ['2', '2'],
                        ['3', '3'],
                        ['4', '4'],
                        ['5', '5'],
                        ['6', '6'],
                        ['7', '7'],
                        ['8', '8'],
                    ]
                },
                {
                    "type": "input_statement",
                    "name": "DO"  // Bloc de déclaration pour les blocs à répéter
                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#5CA65C', // Vert, par exemple
            "tooltip": t('blockly.repeat.tooltip', 'repeat'),
            "helpUrl": ""
        });
    }
};

javascriptGenerator.forBlock[BlocklyFields.REPEAT] = function (block, generator) {
    var branch = generator.statementToCode(block, 'DO');

    var repeats = block.getFieldValue('TIMES');
    return 'for (var count = 0; count < ' + repeats + '; count++) {\n' +
        branch + '}\n';
};

// Définition du bloc get
Blockly.Blocks[BlocklyFields.GET_TARGET] = {
    init: function () {
        this.jsonInit({
            "type": "get_target",
            "message0": t('blockly.get.tooltip', 'get'),
            "previousStatement": null,
            "nextStatement": null,
            "colour": '#FF6680',
            "tooltip": t('blockly.get.tooltip', 'get'),
            "helpUrl": ""
        });
    }
};

javascriptGenerator.forBlock[BlocklyFields.GET_TARGET] = function (block) {
    return `handleTarget();\n`;
};

// Fonction pour construire le XML des blocs initiaux à partir d'un objet JSON, 
// en gérant les connexions et les propriétés optionnelles deletable et movable
export function buildBlocksXmlFromJSON(blocks: any[]): string {
    let xml = '<xml xmlns="https://developers.google.com/blockly/xml">\n';

    function buildBlockXml(blockData: any): string {
        let blockXml = `  <block type="${blockData.type}" x="${blockData.x}" y="${blockData.y}"`;

        // Ajout conditionnel des attributs movable et deletable
        if ('movable' in blockData) {
            blockXml += ` movable="${blockData.movable}"`;
        }
        if ('deletable' in blockData) {
            blockXml += ` deletable="${blockData.deletable}"`;
        }

        blockXml += `>\n`;

        // Ajout des champs personnalisés (comme TIMES)
        if (blockData.fields) {
            for (const [fieldName, fieldValue] of Object.entries(blockData.fields)) {
                blockXml += `    <field name="${fieldName}">${fieldValue}</field>\n`;
            }
        }

        // Gestion des blocs imbriqués (connexions) pour le champ 'DO'
        if (blockData.inputs && blockData.inputs.DO) {
            blockXml += `    <statement name="DO">\n`;
            blockXml += buildBlockXml(blockData.inputs.DO.block);
            blockXml += `    </statement>\n`;
        }

        // Gestion des connexions 'next' (blocs qui suivent)
        if (blockData.next) {
            blockXml += `    <next>\n`;
            blockXml += buildBlockXml(blockData.next.block);
            blockXml += `    </next>\n`;
        }

        blockXml += '  </block>\n';
        return blockXml;
    }

    for (const block of blocks) {
        xml += buildBlockXml(block);
    }

    xml += '</xml>';
    return xml;
}
