import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import config from '../config/config.json'; // Import du fichier JSON
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const useApiClient = () => {
    const handleError = useHandleError();
    const { getToken } = useAuth();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Utilisation des valeurs de configuration directement depuis config.json
    const apiClient = axios.create({
        baseURL: config.api.baseURL + "api/", // Utilisation de l'URL de l'API depuis config.json
        timeout: config.api.timeout, // Utilisation du timeout depuis config.json
    });

    // Intercepteur de requêtes pour ajouter le token d'autorisation
    apiClient.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
            const token = getToken(); // Récupération du token à partir du contexte Auth
            if (token) {
                config.headers.Authorization = `Bearer ${token}`; // Ajout du token à l'en-tête Authorization
            }
            config.headers['X-TimeZone'] = timezone;
            return config;
        },
        (error: any) => {
            return Promise.reject(error);
        }
    );

    // Intercepteur de réponses pour gérer les types de réponses
    apiClient.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error: any) => {
            handleError(error);
            return Promise.reject(error);
        }
    );

    return apiClient;
};

const useHandleError = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    return (error: AxiosError | any): void => {
        if (error.response) {
            if (error.response.status === 401) {
                logout();
                // Redirection vers la page de login en cas d'erreur 401 Unauthorized
                navigate('/');
            }
        } else {
            // Gérer d'autres erreurs ici
            console.error('Erreur réseau ou autre:', error);
        }
    };
};
