import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { useCourseApi } from '../../../api/courses/courses';
import { TSubject } from '../../../models/courses/course';
import { Button, Input, message, Space, Spin, Switch, Table } from 'antd';
import { SaveOutlined, EyeOutlined, EditOutlined, UploadOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { getImagePath } from '../../../utils/format/valueFormat';
import FloatButtons from '../../../components/button/FloatButtons';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';
import { useAuth } from '../../../context/AuthContext';
import { CODIFICATION_ROLES } from '../../../constant/codification';

const SubjectList: React.FC = () => {
    const { t } = useTranslation();
    const { isRoleExist } = useAuth();
    const navigate = useNavigate();
    const { getSubjects, updateSubject, createSubject, deleteSubject, uploadSubjectImage } = useCourseApi();
    const [subjects, setSubjects] = useState<TSubject[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const [loadingRow, setLoadingRow] = useState<number | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        getSubjects()
            .then((resp) => {
                setSubjects(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingSubject, setEditingSubject] = useState<TSubject | null>(null);

    const edit = (subject: TSubject) => {
        setEditingKey(subject.id);
        setEditingSubject({ ...subject });
    };

    const handleAddSubject = () => {
        const idRow = addingId - 1;
        const newSubject: TSubject = {
            id: idRow,
            title: '',
            description: '',
            image: '',
            actif: false,
            courses: [],
            progression_state: null,

        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingSubject(newSubject);
        setSubjects((prev) => [newSubject, ...prev]);
    };

    const save = (id: number) => {
        if (editingSubject) {
            setLoadingRow(id);
            (editingSubject.id > 0 ? updateSubject(editingSubject.id, editingSubject) : createSubject(editingSubject))
                .then((resp) => {
                    const subjectResult: TSubject = resp.data;
                    const updatedSubjects = subjects.map((subject) =>
                        subject.id === id ? subjectResult : subject
                    );
                    setSubjects(updatedSubjects);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                    setLoadingRow(null);
                });
        }

        setEditingKey(null);
    };
    const cancel = () => {
        setEditingKey(null);
        setEditingSubject(null);
    }
    const showDetails = (subject: TSubject) => {
        navigate('/setup/course', { state: { subject } });
    };

    const uploadImage = (subject: TSubject) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                setLoadingRow(subject.id);
                const formData = new FormData();
                formData.append('image', file);

                uploadSubjectImage(subject.id, formData)
                    .then((resp) => {
                        const subjectResult: TSubject = resp.data;
                        const updatedSubjects = subjects.map((crs) =>
                            crs.id === subject.id ? subjectResult : crs
                        );
                        setSubjects(updatedSubjects);
                        message.success(t('message.savedSuccessfully'));
                    })
                    .catch((err) => {
                        message.error(err.response.data ?? err.message);
                        console.error(err);
                    })
                    .finally(() => {
                        setLoadingRow(null);
                    });
            }
        };

        input.click();
    };

    // Delete functionality
    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteSubject(deleteId)
            .then(() => {
                setSubjects(subjects.filter((subject) => subject.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };

    const columns: ColumnsType<TSubject> = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 150,
            render: (text: string, record: TSubject) =>
                record.image ? (
                    <img
                        src={getImagePath(record.image, true)}
                        alt="subject-thumbnail"
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                ) : (
                    'N/A'
                ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 180,
            render: (text: string, record: TSubject) =>
                editingKey === record.id ? (
                    <Input
                        value={editingSubject?.title}
                        onChange={(e) => {
                            if (editingSubject) {
                                setEditingSubject({ ...editingSubject, title: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string, record: TSubject) =>
                editingKey === record.id ? (
                    <Input
                        value={editingSubject?.description}
                        onChange={(e) => {
                            if (editingSubject) {
                                setEditingSubject({ ...editingSubject, description: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Active',
            dataIndex: 'actif',
            key: 'actif',
            width: 180,
            render: (actif: boolean, record: TSubject) =>
                editingKey === record.id ? (
                    <Switch
                        checked={editingSubject?.actif}
                        onChange={(checked) => {
                            if (editingSubject) {
                                setEditingSubject({ ...editingSubject, actif: checked });
                            }
                        }}
                    />
                ) : (
                    <Switch checked={actif} disabled />
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 240,
            render: (_: any, record: TSubject) => (
                <Space size="middle">
                    {loadingRow === record.id ? ( // Affiche le spinner si la ligne est en chargement
                        <Spin />
                    ) : (
                        editingKey === record.id ? (

                            <>
                                <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                                <Button icon={<StopOutlined />} onClick={cancel} />
                            </>
                        ) : (
                            <>
                                <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                                <Button icon={<UploadOutlined />} onClick={() => uploadImage(record)} />
                                <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />
                                {
                                    isRoleExist(CODIFICATION_ROLES.DELETE_COURSE) && (
                                        <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => confirmDelete(record.id)}
                                            loading={deleteId === record.id && deleteLoading}
                                        />
                                    )
                                }

                            </>
                        )
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={t('text.availableSubject')} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddSubject}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TSubject>
                columns={columns}
                dataSource={subjects}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />

            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this subject? This action is irreversible."
            />

            <FloatButtons />
        </AppLayout>
    );
};

export default SubjectList;
