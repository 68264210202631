import { Obstacle } from "../../type/scene";

import t1 from "../../../../assets/images/obstacle/tajine_2x2/1.png"
import t2 from "../../../../assets/images/obstacle/tajine_2x2/2.png"
import t3 from "../../../../assets/images/obstacle/tajine_2x2/3.png"
import t4 from "../../../../assets/images/obstacle/tajine_2x2/4.png"

export const TAJINE_2x2 = (x: number, y: number): Obstacle => ({
    elements: [
        {
            img: t1,
            x: x,
            y: y,
        },
        {
            img: t2,
            x: x,
            y: y + 1,
        },
        {
            img: t3,
            x: x + 1,
            y: y,
        },
        {
            img: t4,
            x: x + 1,
            y: y + 1,
        }
    ]
})