export type TTargetState = 'idle' | 'reached';

export type TTarget = {
    x: number;        // Position X de la cible
    y: number;        // Position Y de la cible
    width: number;    // Largeur de la cible
    height: number;   // Hauteur de la cible
    state: TTargetState; // État de la cible (idle ou atteinte)
    order: number;
    quantity: number;
    showQuantity: boolean;
    images: {          // Images associées aux différents états
        idle: string;    // Image à afficher quand la cible est "idle"
        reached: string; // Image à afficher quand la cible est atteinte
    };
    visible?: boolean;
    behavior: {        // Comportement associé aux états
        reached: {
            disapear: boolean;  // Indique si la cible doit disparaître quand elle est atteinte
            action?: () => void;  // Optionnel : action à exécuter quand la cible est atteinte (pour ajout futur)
        };
    };
    rotate: number;
}

interface OptionalParams {
    order?: number;
    quantity?: number;
    reachedImage?: string;
    disapear?: boolean;
    rotate?: number;
    action?: () => void;
}

export const createTarget = (
    x: number,
    y: number,
    width: number,
    height: number,
    idleImage: string,
    options: OptionalParams = {},
    visible?: boolean,
): TTarget => {
    return {
        x,
        y,
        width,
        height,
        state: 'idle',  // État initial de la cible
        rotate: options.rotate ?? 0,
        // Valeurs par défaut avec possibilité de les remplacer par celles dans `options`
        order: options.order ?? 0,
        quantity: options.quantity ?? 1,
        showQuantity: false,
        images: {
            idle: idleImage,
            reached: options.reachedImage || '',  // Image pour l'état "reached"
        },
        visible: visible ?? true,
        behavior: {
            reached: {
                disapear: options.disapear ?? false,  // Par défaut, ne disparaît pas
                action: options.action,               // Action optionnelle
            },
        },
    };
};