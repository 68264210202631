// src/components/user/PersonalInfoForm.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, Input, DatePicker } from 'antd';

const PersonalInfoForm: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Card title={t('text.personal_info')} style={{ marginBottom: 16 }}>
            <Form.Item
                label={t('text.firstname')}
                name={['personal_info', 'firstname']}
                rules={[{ required: true, min: 2, message: t('message.value_required') + "-" + t('message.min_len', { len: 2 }) }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('text.lastname')}
                name={['personal_info', 'lastname']}
                rules={[{ required: true, min: 2, message: t('message.value_required') + "-" + t('message.min_len', { len: 2 }) }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('text.birthdate')}
                name={['personal_info', 'birthdate']}
                rules={[{ required: true, message: t('message.value_required') }]}
            >
                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
            </Form.Item>
        </Card>
    );
};

export default PersonalInfoForm;
