import React from 'react';
import { Spin } from 'antd';
import './LoadingBox.css';  // Importer le fichier de styles CSS

const LoadingBox: React.FC = () => {
    return (
        <div className="loading-container">
            <Spin size="large" /> {/* Taille "large" pour l'indicateur de chargement */}
        </div>
    );
};

export default LoadingBox;
