// pages/Course.tsx
import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/layout/AppLayout';
import { message } from 'antd';
import CourseCard from '../../components/cards/CourseCard';
import { useTranslation } from 'react-i18next';
import { TCourse } from '../../models/courses/course';
import { useCourseApi } from '../../api/courses/courses';
import FloatButtons from '../../components/button/FloatButtons';
import { useParams } from 'react-router-dom';



const Course: React.FC = () => {
    const { t } = useTranslation();
    const { getCoursesBySubjectIdAndProgress } = useCourseApi();
    const [courses, setCourses] = useState<TCourse[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const { subjectId } = useParams();  // Récupérer l'ID du cours depuis l'URL

    useEffect(() => {
        setIsLoading(true);
        getCoursesBySubjectIdAndProgress(Number(subjectId))
            .then((resp) => {

                setCourses(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message)
            })
            .finally(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line
    }, [])

    return (
        <AppLayout title={t('text.availableCourse')} loading={loading}>
            {(courses || []).map((course, index) => (
                <CourseCard course={course} />
            ))}
            <FloatButtons />
        </AppLayout>
    );
};

export default Course;
