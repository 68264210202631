import React from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { Tabs } from 'antd';
import TargetList from './TargetList';
import CharacterList from './CharacterList';
import ObstacleList from './Obstacle';
import SceneList from './Scene';

const BlocklyObjects: React.FC = () => {

    const items = [
        {
            label: `Character`,
            key: "1",
            children: <CharacterList />,
        },
        {
            label: `Target`,
            key: "2",
            children: <TargetList />,
        },
        {
            label: `Obstacle`,
            key: "3",
            children: <ObstacleList />,
        },
        {
            label: `Scene`,
            key: "4",
            children: <SceneList />,
        },
    ]
    return (
        <AppLayout title={`Blockly Objects`} >
            <>
                <Tabs
                    tabPosition="left"
                    items={items}
                />
            </>
        </AppLayout>
    );

};

export default BlocklyObjects;
