import React, { useEffect, useState } from 'react';
import { useReferential } from '../../../context/ReferentialContext';
import { useUserApi } from '../../../api/user/userApp';
import { TRole, TRoleUserType } from '../../../models/user/User';
import { message, Card, Table, Checkbox } from 'antd';
import { CODIFICATION_TYPE } from '../../../constant/codification';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TCodification } from '../../../models/referential/referential';
import { ColumnsType } from 'antd/es/table';
import AppLayout from '../../../components/layout/AppLayout';

type FixedType = 'left' | 'right' | boolean;

const RoleTypeUser: React.FC = () => {
    const { getCodificationsByCodeType } = useReferential();
    const {
        getAllRoles,
        getAllRolesTypeUsersLinks,
        createRoleTypeUserLink,
        deleteRoleTypeUserLink,
    } = useUserApi();

    const [loading, setIsLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<TRole[]>([]);
    const [rolesLinked, setRolesLinked] = useState<TRoleUserType[]>([]);

    const codeTypeUsers: TCodification[] = getCodificationsByCodeType(
        CODIFICATION_TYPE.USER_TYPE
    );

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getAllRoles(), getAllRolesTypeUsersLinks()])
            .then((resp) => {
                const [rolesData, rolesLinkedData] = resp;
                setRoles(rolesData.data || []);
                setRolesLinked(rolesLinkedData.data || []);
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const handleCheckboxChange = (
        e: CheckboxChangeEvent,
        roleId: number,
        usertypeId: number
    ) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            // Créer le lien
            let roleUserType: TRoleUserType = {
                id: 0,
                role_id: roleId,
                usertype_id: usertypeId
            }
            createRoleTypeUserLink(roleUserType)
                .then((resp) => {
                    // Ajouter le lien à la liste
                    setRolesLinked([...rolesLinked, resp.data]);
                    message.success('Lien créé avec succès');
                })
                .catch((err) => {
                    message.error(err.response?.data ?? err.message);
                });
        } else {
            // Supprimer le lien
            const link = rolesLinked.find(
                (link) => link.role_id === roleId && link.usertype_id === usertypeId
            );
            if (link) {
                deleteRoleTypeUserLink(link.id)
                    .then(() => {
                        // Supprimer le lien de la liste
                        setRolesLinked(rolesLinked.filter((item) => item.id !== link.id));
                        message.success('Lien supprimé avec succès');
                    })
                    .catch((err) => {
                        message.error(err.response?.data ?? err.message);
                    });
            }
        }
    };

    type DataType = {
        key: number;
        id: number;
        name: string;
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'Role',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left' as FixedType, // Assertion de type ici
            width: 200,
        },
        // Générer une colonne pour chaque usertype
        ...codeTypeUsers.map((usertype) => ({
            title: usertype.label,
            dataIndex: usertype.id.toString(),
            key: usertype.id.toString(),
            render: (_: any, record: DataType) => {
                const isChecked = rolesLinked.some(
                    (link) => link.role_id === record.id && link.usertype_id === usertype.id
                );
                return (
                    <Checkbox
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(e, record.id, usertype.id)}
                    />
                );
            },
        })),
    ];


    const dataSource = roles.map((role) => ({
        key: role.id,
        id: role.id,
        name: role.name,
    }));

    return (
        <AppLayout title='Roles' loading={loading}>
            <Card title="Gestion des rôles par type d'utilisateur" loading={loading}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
            </Card>
        </AppLayout>
    );
};

export default RoleTypeUser;
