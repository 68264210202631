// Sidebar.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu, Divider, Typography } from 'antd';
import {
    BlockOutlined,
    BookOutlined,
    SettingOutlined,
    UserOutlined,
    CodeOutlined,
    KeyOutlined,
} from '@ant-design/icons';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import packageJson from '../../../package.json';

const { Sider } = Layout;
const { Text } = Typography;

interface SidebarProps {
    isCollapsed: boolean;
}

interface MenuItem {
    key: string;
    icon: React.ReactNode;
    label: string;
    path: string;
    requiredRoles?: string[];
    subpages?: string[];
}

interface MenuSection {
    title: string;
    items: MenuItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { isRoleExist } = useAuth();


    // Définition des icônes pour les chemins spécifiques
    const getIconForPath = (path: string) => {
        switch (path) {
            case '/program':
                return <BookOutlined />;
            case '/conf/users':
            case '/setup/users':
                return <UserOutlined />;
            case '/setup/subject':
                return <SettingOutlined />;
            case '/setup/blockly-objects':
                return <BlockOutlined />;
            case '/setup/roles':
                return <KeyOutlined />;
            case '/td':
            case '/rd':
                return <CodeOutlined />;
            default:
                return <BookOutlined />;
        }
    };

    // Création des sections du menu
    const menuSections: MenuSection[] = [
        {
            title: t('sidebar.general'),
            items: [
                {
                    key: '/program',
                    icon: getIconForPath('/program'),
                    label: t('sidebar.program'),
                    path: '/program',
                    requiredRoles: ['ACCESS_PROGRAM'],
                    subpages: ['/subject-courses/:subjectId', '/course-details/:courseId', '/course-progression/:lessonId'],
                },
            ],
        },
        {
            title: t('sidebar.settings'),
            items: [
                {
                    key: '/conf/users',
                    icon: getIconForPath('/conf/users'),
                    label: t('sidebar.users'),
                    path: '/conf/users',
                    requiredRoles: ['CONFIGURATION_OWN_USERS'],
                    subpages: ['/conf/users/form'],
                },
            ],
        },
        {
            title: t('sidebar.admin'),
            items: [
                {
                    key: '/setup/subject',
                    icon: getIconForPath('/setup/subject'),
                    label: t('sidebar.subject'),
                    path: '/setup/subject',
                    requiredRoles: ['COURSE_CONFIGURATOR'],
                    subpages: ['/setup/course', '/setup/chapter', '/setup/epic', '/setup/lesson', '/setup/lesson-blockly-game', '/setup/lesson-blockly-draw'],
                },
                {
                    key: '/setup/users',
                    icon: getIconForPath('/setup/users'),
                    label: t('sidebar.users'),
                    path: '/setup/users',
                    requiredRoles: ['ADMIN_USERS'],
                    subpages: ['/setup/passwords'],
                },
                {
                    key: '/setup/blockly-objects',
                    icon: getIconForPath('/setup/blockly-objects'),
                    label: t('sidebar.blocklyobjects'),
                    path: '/setup/blockly-objects',
                    requiredRoles: ['BLOCKLY_CONFIGURATOR'],
                    subpages: [],
                },
                {
                    key: '/setup/roles',
                    icon: getIconForPath('/setup/roles'),
                    label: t('sidebar.roles'),
                    path: '/setup/roles',
                    requiredRoles: ['ROLE_CONFIGURATOR'],
                    subpages: [],
                },
                {
                    key: '/rd',
                    icon: getIconForPath('/rd'),
                    label: t('sidebar.rd'),
                    path: '/rd',
                    requiredRoles: ['DEVELOPER'],
                    subpages: [],
                },
            ],
        },
    ];

    // Fonction pour matcher les chemins dynamiques
    const isPathMatching = (menuPath: string, currentPath: string) => {
        return matchPath({ path: menuPath, end: false }, currentPath) !== null;
    };

    // Récupérer la clé du menu sélectionné
    const getSelectedMenuKey = () => {
        const currentPath = location.pathname;
        for (const section of menuSections) {
            for (const item of section.items) {
                if (
                    isPathMatching(item.path, currentPath) ||
                    (item.subpages && item.subpages.some(subpage => isPathMatching(subpage, currentPath)))
                ) {
                    return item.key;
                }
            }
        }
        return '';
    };

    return (
        <Sider
            width={isCollapsed ? 80 : 250}
            collapsed={isCollapsed}
            style={{
                background: '#fff',
                boxShadow: '2px 0 2px rgba(0, 0, 0, 0.1)',
                zIndex: 2,
                position: 'fixed',
                left: 0,
                top: 0,
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'width 0.3s ease',
            }}
        >
            <div>
                <div style={{ padding: '10px 0', textAlign: 'center' }}>
                    <img
                        src={!isCollapsed ? '/images/logo_400.png' : '/images/logoMin_100.png'}
                        alt="Logo"
                        style={{
                            height: '48px',
                            margin: '0 auto',
                            transition: 'opacity 0.3s ease',
                        }}
                    />
                </div>

                <Divider style={{ margin: '8px 0' }} />

                {/* Boucle sur les sections du menu */}
                {menuSections.map((section, index) => {
                    // Filtrer les items du menu par rôle
                    const visibleItems = section.items.filter(item =>
                        item.requiredRoles ? item.requiredRoles.some(role => isRoleExist(role)) : true
                    );

                    return visibleItems.length > 0 ? (
                        <div key={index}>
                            {!isCollapsed && (
                                <Text
                                    style={{
                                        fontSize: '12px',
                                        color: '#888',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.1em',
                                        margin: '16px 16px 8px',
                                        display: 'block',
                                    }}
                                >
                                    {section.title}
                                </Text>
                            )}

                            <Menu
                                mode="vertical"
                                selectedKeys={[getSelectedMenuKey()]}
                                items={visibleItems.map(item => ({
                                    key: item.key,
                                    icon: item.icon,
                                    label: isCollapsed ? null : item.label,
                                    onClick: () => navigate(item.path),
                                }))}
                                style={{ borderRight: 0 }}
                            />

                            {index < menuSections.length - 1 && <Divider style={{ margin: '8px 0' }} />}
                        </div>
                    ) : null;
                })}
            </div>

            <div
                style={{
                    position: 'absolute',
                    bottom: '16px',
                    width: '100%',
                    textAlign: 'center',
                    color: '#888',
                }}
            >
                <span style={{ fontSize: isCollapsed ? '10px' : '12px' }}>
                    Version {packageJson.version}
                </span>
            </div>
        </Sider>
    );
};

export default Sidebar;
