import React, { useState, useRef } from 'react';

interface MovableImageProps {
    src: string; // Chemin de l'image
    initialX: number; // Position initiale X
    initialY: number; // Position initiale Y
    width?: string;
    height?: string;
    onMove: () => void;
    onTarget?: (itIs: boolean) => void;
    targetRef?: React.RefObject<HTMLDivElement>; // Zone cible optionnelle
    magnet?: boolean; // Magnétisme activé ou non
}

const MovableImage: React.FC<MovableImageProps> = ({ src, initialX, initialY, onMove, targetRef, onTarget, magnet = false, width = "150px", height = "auto" }) => {
    const [position, setPosition] = useState({ x: initialX, y: initialY });
    const [isMoving, setIsMoving] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const imageRef = useRef<HTMLImageElement>(null);

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();

        const mouseX = e.clientX;
        const mouseY = e.clientY;

        const imageX = position.x;
        const imageY = position.y;
        const offsetX = mouseX - imageX;
        const offsetY = mouseY - imageY;

        setOffset({ x: offsetX, y: offsetY });
        setIsMoving(true);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isMoving) {
            e.preventDefault();
            const mouseX = e.clientX;
            const mouseY = e.clientY;

            setPosition({
                x: mouseX - offset.x,
                y: mouseY - offset.y,
            });

            if (targetRef?.current) {
                const targetRect = targetRef.current.getBoundingClientRect();
                const imageRect = imageRef.current?.getBoundingClientRect();

                if (
                    imageRect &&
                    imageRect.x >= targetRect.x &&
                    imageRect.y >= targetRect.y &&
                    imageRect.x + imageRect.width <= targetRect.x + targetRect.width &&
                    imageRect.y + imageRect.height <= targetRect.y + targetRect.height
                ) {
                    onTarget && onTarget(true);
                } else {
                    onTarget && onTarget(false);
                }
            }
        }
    };

    const handleMouseUp = () => {
        setIsMoving(false);

        if (magnet && targetRef?.current) {
            const targetRect = targetRef.current.getBoundingClientRect();
            const imageRect = imageRef.current?.getBoundingClientRect();

            // On obtient la position de l'élément parent pour ajuster les coordonnées correctement
            const parentRect = targetRef.current.offsetParent?.getBoundingClientRect();

            // Calcul de la position correcte en tenant compte du parent
            const offsetX = parentRect ? targetRect.left - parentRect.left : targetRect.left;
            const offsetY = parentRect ? targetRect.bottom - parentRect.top : targetRect.bottom;

            // Vérifie si l'image est proche du coin inférieur gauche de la cible
            const proximityThreshold = 15;

            if (
                imageRect &&
                Math.abs(imageRect.left - targetRect.left) <= proximityThreshold &&
                Math.abs(imageRect.bottom - targetRect.bottom) <= proximityThreshold
            ) {

                // Colle l'image dans le coin inférieur gauche de la zone cible
                setPosition({
                    x: offsetX,  // Colle à l'extrême gauche de la cible
                    y: offsetY - imageRect.height,  // Colle en bas de la cible
                });
                onTarget && onTarget(true);
            }
        }

        onMove();
    };


    return (
        <img
            ref={imageRef}
            src={src}
            alt="Movable"
            style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
                cursor: 'grab',
                width: width,
                height: height,
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            draggable="false" // Désactiver le drag natif du navigateur
        />
    );
};

export default MovableImage;
