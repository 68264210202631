import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CourseLayout from '../../components/layout/CourseLayout';
import { exerciseComponents } from '../../courses/exerciseComponents';
import { Button, Card, Col, message, Row } from 'antd';
import LoadingBox from '../../components/loading/LoadingBox';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { TCourse } from '../../models/courses/course';
import { TLesson } from '../../models/courses/lesson';
import { useCourseApi } from '../../api/courses/courses';
import { findEpicInCourse } from '../../models/courses/courseFunc';
import LessonBlockly from './lessonType/gameBlockly/LessonBlockly';
import { useUserApi } from '../../api/user/userApp';
import { TLessonProgress } from '../../models/user/UserProgression';

const CourseProgress: React.FC = () => {
    const navigate = useNavigate();
    const { lessonId } = useParams();  // Récupérer les paramètres d'URL

    const [canProceed, setCanProceed] = useState(false);  // Pour activer/désactiver le bouton "Suivant"  
    const { getLessonDetailsById } = useCourseApi();
    const { saveProgressionUser } = useUserApi();
    const [lesson, setLesson] = useState<TLesson | null>(null);
    const [isLoading, setIsLoading] = useState(true);  // État de chargement
    const [currentLessonId, setCurrentLessonId] = useState<number>(Number(lessonId));

    const [course, setCourse] = useState<TCourse | null>(null);
    const { getCoursesDetailsByIdLesson } = useCourseApi();
    const { getProgressionUserByCourseId } = useUserApi();
    const [progression, setProgression] = useState<TLessonProgress[]>([]);
    const [isAccessible, setIsAccessible] = useState<boolean>(true);
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isSaving, setIsSaving] = useState(false);


    useEffect(() => {
        // Charger les détails du cours en utilisant le lessonId
        getCoursesDetailsByIdLesson(Number(lessonId))
            .then((respCourse) => {
                const courseData = respCourse.data
                setCourse(courseData);

                // Une fois le cours chargé, utiliser le courseId pour charger la progression
                return getProgressionUserByCourseId(courseData.id);
            })
            .then((respProgression) => {
                setProgression(respProgression.data || []);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message)
                console.error("Error fetching course or progression:", err);
                navigate(-1);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [lessonId]);

    useEffect(() => {
        if (course && progression) {
            // Vérifier si la leçon actuelle est accessible dans l'épic en cours
            const currentEpic = course.chapters
                .filter(chapter => chapter !== null && chapter.epics !== null) // Filtrer les chapitres et épopées nuls
                .flatMap(chapter => chapter.epics) // Aucune vérification supplémentaire ici, car on a déjà filtré
                .find(epic => epic.lessons && epic.lessons.some(lesson => lesson.id === currentLessonId)); // Vérifier les leçons


            if (currentEpic) {
                // Récupérer toutes les leçons de l'épic en cours
                const epicLessons = currentEpic.lessons;
                // Trouver l'index de la leçon actuelle dans les leçons de l'épic
                const currentLessonIndex = epicLessons.findIndex(lesson => lesson.id === currentLessonId);

                // Vérifier si toutes les leçons précédentes de l'épic ont été complétées
                const hasAccess = epicLessons.slice(0, currentLessonIndex).every((lesson) =>
                    progression.some((prog) => prog.lesson_id === lesson.id)
                );
                setIsAccessible(hasAccess);
            } else {
                // Si l'épic contenant la leçon n'est pas trouvé, on considère que le cours n'est pas accessible
                setIsAccessible(false);
            }
            // Mettre à jour l'état indiquant que la vérification est terminée
            setIsCheckingAccess(false);
        }

    }, [course, progression, currentLessonId])


    // Nouveaux états pour gérer les leçons et l'indice actuel
    const [lessons, setLessons] = useState<TLesson[]>([]);
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    useEffect(() => {
        if (currentLessonId > 0) {
            setIsLoading(true);
            getLessonDetailsById(currentLessonId)
                .then((response) => {
                    setLesson(response.data);
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [currentLessonId]);

    useEffect(() => {
        if (lesson && course) {
            const epicLessons = findEpicInCourse(course, lesson.epic_id)?.lessons || [];
            setLessons(epicLessons);

            const index = epicLessons.findIndex(l => l.id === lesson.id);
            setCurrentStepIndex(index);
            setCanProceed(false); // Réinitialise canProceed lors du changement de leçon
        }
    }, [lesson, course]);

    const saveProgression = async () => {
        if (lesson) {
            setIsSaving(true)
            saveProgressionUser(lesson.id)
                .then((rep) => {
                    setProgression((prev) => [
                        ...prev,
                        { id: 0, userapp_id: 0, lesson_id: lesson.id },
                    ]);
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsSaving(false)
                });
        }
    }
    const next = async () => {
        await saveProgression();
        if (currentStepIndex + 1 < lessons.length) {
            goToStep(currentStepIndex + 1);
        }
    };


    const previous = () => {
        if (currentStepIndex > 0) {
            goToStep(currentStepIndex - 1);
        }
    };

    const goToStep = (stepIndex: number) => {
        const targetLesson = lessons[stepIndex];
        if (targetLesson) {
            setCurrentLessonId(targetLesson.id)
        }
    };

    const finishCourse = async () => {
        await saveProgression();
        if (course) {
            navigate(`/course-details/${course.id}`);
        } else {
            navigate(`/program`);
        }
    };


    const checkResult = useCallback((isValid: boolean) => {
        setCanProceed(isValid);

    }, []);

    const SelectedExerciseComponent = lesson?.exercise_component ? exerciseComponents[lesson.exercise_component] : null;

    return (
        <CourseLayout
            title={`Progression: ${lesson?.epic?.name || '...'}`}
            lessons={lessons}
            progression={progression}
            currentStep={currentStepIndex}
            previous={currentStepIndex > 0 ? previous : undefined}
            next={canProceed && currentStepIndex + 1 < lessons.length ? next : undefined}
            finishCourse={canProceed && currentStepIndex + 1 === lessons.length ? finishCourse : undefined}
            goToStep={goToStep}
            isLoading={isLoading}
            isSaving={isSaving}
        >
            {isLoading || isCheckingAccess ? (
                <LoadingBox />
            ) : !isAccessible ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h3>{'Cours non disponible'}</h3>
                    <p>Vous devez compléter les leçons précédentes pour accéder à ce cours.</p>
                    <Button type="primary" onClick={() => navigate(`/program`)}>Retour au cours</Button>
                </div>
            ) : (
                <>
                    <Row style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Col>
                            <ArrowLeftOutlined
                                onClick={() => course ? navigate(`/course-details/${course.id}`) : navigate(`/program`)}
                                style={{ cursor: 'pointer', fontSize: '18px' }}
                            />
                        </Col>
                        <Col>
                            <h3 style={{ margin: 0 }}>{`${lesson?.name || 'Chargement...'}`}</h3>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col span={24}>
                            <Card
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        {lesson?.statement || 'Chargement...'}
                                    </div>
                                }
                                bordered={true}
                                style={{
                                    minHeight: 600,
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                    position: 'relative',
                                }}
                            >
                                {SelectedExerciseComponent ? (
                                    <SelectedExerciseComponent checkResult={checkResult} />
                                ) : (
                                    <LessonBlockly key={lesson?.id || 0} lesson={lesson} checkResult={checkResult} />
                                )}
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </CourseLayout>
    );
};

export default CourseProgress;
