// src/reducers/gameReducer.ts

import { GameState } from './GameState';
import { GameAction } from './GameAction';

export function gameReducer(state: GameState, action: GameAction): GameState {
    switch (action.type) {
        case 'MOVE_CHARACTER':
            const newMovementHistory = action.payload.movementLine
                ? [...state.targetLines, action.payload.movementLine]
                : state.targetLines;

            return {
                ...state,
                character: {
                    ...state.character,
                    x: action.payload.x,
                    y: action.payload.y,
                    state: action.payload.state,
                    scale: action.payload.scale ?? state.character.scale,
                },
                targetLines: newMovementHistory,
            };
        case 'RESET_GAME':
            return {
                ...state,
                character: { ...state.initialCharacter },
                scene: { ...state.initialScene },
                targetLines: [],
                executionCancelled: false,
            };
        case 'RESET_LINES':
            return {
                ...state,
                initialTargetLines: action.payload,
            };
        case 'SET_SCENE_BACKGROUND':
            return {
                ...state,
                scene: {
                    ...state.scene,
                    background: action.payload,
                },
            };
        case 'SET_CHARACTER_STATE':
            return {
                ...state,
                character: {
                    ...state.character,
                    state: action.payload,
                },
            };
        case 'SET_EXECUTION_CANCELLED':
            return {
                ...state,
                executionCancelled: action.payload,
            };
        case 'UPDATE_CHARACTER':
            return {
                ...state,
                character: action.payload,
            };
        case 'UPDATE_SCENE':
            return {
                ...state,
                scene: action.payload,
            };
        case 'RESET_MOVEMENT_HISTORY':
            return {
                ...state,
                targetLines: [],
            };
        default:
            return state;
    }
}
