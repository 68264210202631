import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import MovableImage from '../../../../components/imageManipulation/MovableImage';
import cat from '../../../../assets/images/puzzle/cat.jpg';
import dog from '../../../../assets/images/puzzle/dog.jpg';
import { useTranslation } from 'react-i18next';

interface DnDLevelThreeProps {
    checkResult: (isValid: boolean) => void; // Prop pour informer le parent que l'exercice est complété
}

const DnDLevelThree: React.FC<DnDLevelThreeProps> = ({ checkResult }) => {
    const { t } = useTranslation();
    // Suivi des cibles spécifiques
    const [targetStatus, setTargetStatus] = useState<{ [key: number]: boolean }>({
        1: false, // Image 1 dans la cible 1
        2: false  // Image 2 dans la cible 2
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [isMoved, setIsMoved] = useState(false);

    const handleMove = () => {
        setIsMoved(true);
    };

    // Création des références pour chaque cible
    const targetRef1 = useRef<HTMLDivElement>(null); // Référence pour cible 1
    const targetRef2 = useRef<HTMLDivElement>(null); // Référence pour cible 2

    // Gestion des changements d'état des cibles
    const handleTargetChange = (imageId: number, isOnTarget: boolean) => {
        setTargetStatus(prevStatus => ({
            ...prevStatus,
            [imageId]: isOnTarget
        }));
    };

    // Validation lorsque toutes les images sont dans leurs bonnes cibles
    useEffect(() => {
        const allImagesInPlace = Object.values(targetStatus).every(status => status === true);
        checkResult(allImagesInPlace);

        setIsSuccess(allImagesInPlace);

    }, [targetStatus, checkResult]);

    useEffect(() => {
        if (isMoved) {
            if (isSuccess) {
                message.success(t("message.bravo"));
            }
        }
        // eslint-disable-next-line
    }, [isSuccess]);
    return (

        <Row justify="center">
            <Col span={12}>
                {/* Zone Cible 1 */}
                <div
                    ref={targetRef1}
                    style={{
                        position: 'absolute',
                        top: '300px',
                        right: '90px',
                        width: '170px',
                        height: '170px',
                        border: '2px dashed green',
                        backgroundImage: `url("${dog}")`,
                        opacity: 0.4,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                >
                    Cible 1
                </div>

                {/* Zone Cible 2 */}
                <div
                    ref={targetRef2}
                    style={{
                        position: 'absolute',
                        top: '100px',
                        right: '50px',
                        width: '170px',
                        height: '170px',
                        border: '2px dashed blue',
                        backgroundImage: `url("${cat}")`,
                        opacity: 0.4,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                >
                    Cible 2
                </div>

                {/* Image 1 */}
                <MovableImage
                    onMove={handleMove}
                    targetRef={targetRef1}
                    onTarget={(isOnTarget) => handleTargetChange(1, isOnTarget)}
                    src={dog}
                    initialX={100}
                    initialY={100}
                />

                {/* Image 2 */}
                <MovableImage
                    onMove={handleMove}
                    targetRef={targetRef2}
                    onTarget={(isOnTarget) => handleTargetChange(2, isOnTarget)}
                    src={cat}
                    initialX={140}
                    initialY={300}
                />

            </Col>
        </Row>
    );
};

export default DnDLevelThree;
