import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, message, Row, Space } from 'antd';
import BlocklyEditor from './BlocklyEditor';
import GameGrid from './GameGrid';
import { javascriptGenerator } from 'blockly/javascript';
import { executeCodeWithDelay } from './config/blocklyUtils';
import { useBlocklyWorkspace } from './useBlocklyWorkspace';
import { useTranslation } from 'react-i18next';
import * as Blockly from 'blockly';
import { CharacterPosition } from './type/character';
import { TargetPosition } from './type/target';
import { FAIZAN } from './constant/character';
import { TARGET } from './constant/target';
import { BackgroundScene, Obstacle } from './type/scene';

interface BlocklyMouvProps {
  gridSize?: { side: number };
  initialObjectPosition?: CharacterPosition;
  targetPositions?: TargetPosition[];
  toolbox: any;
  initialBlocksJson: any;
  onTargetReached?: (isValid: boolean) => void;
  mustReachAllTargets?: boolean;
  mustRespectOrder?: boolean;
  autoTargetReaching?: boolean;
  bg: BackgroundScene;
  obstacles?: Obstacle[];
}

const BlocklyMouv: React.FC<BlocklyMouvProps> = ({
  gridSize = { side: 5 },
  initialObjectPosition = { row: 0, col: 0, rotate: 0, scale: 1, object: FAIZAN },
  targetPositions = [{ row: 0, col: 7, object: TARGET }], // Par défaut une seule cible
  toolbox,
  initialBlocksJson,
  onTargetReached,
  mustReachAllTargets = true,
  mustRespectOrder = false,
  autoTargetReaching = true,
  bg,
  obstacles = []
}) => {
  const { t } = useTranslation();
  const [objectPosition, setObjectPosition] = useState<CharacterPosition>({ ...initialObjectPosition, img: initialObjectPosition.object.onHold });
  const objectPositionRef = useRef<CharacterPosition>({ ...initialObjectPosition, img: initialObjectPosition.object.onHold });
  const remainingTargetsRef = useRef(targetPositions.map(target => ({
    ...target,               // Copie toutes les propriétés existantes de l'élément target
    img: target.object.onStill // Ajoute la nouvelle propriété img
  })));
  const initialTargets = useRef(targetPositions.map(target => ({
    ...target,               // Copie toutes les propriétés existantes de l'élément target
    img: target.object.onStill // Ajoute la nouvelle propriété img
  })));
  const visitedTargetsRef = useRef<TargetPosition[]>([]); // Ref pour les cibles visitées
  const executionStoppedRef = useRef(false);  // Flag pour stopper l'exécution des mouvements
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg | null>(null);
  const { isRunButtonEnabled } = useBlocklyWorkspace(workspace);
  const [updatedToolbox, setUpdatedToolbox] = useState(toolbox); // Stockage de la toolbox initiale pour restaurer les blocs quand nécessaire 
  const blockUsageCountRef = useRef<{ [blockType: string]: number }>({}); // Stockage pour compter les blocs créés



  useEffect(() => {
    if (!workspace) return;

    // Ajouter l'écouteur d'événements
    workspace.addChangeListener(handleBlockEvent);

    return () => {
      workspace.removeChangeListener(handleBlockEvent);
    };

    // eslint-disable-next-line 
  }, [workspace, updatedToolbox]);

  // Trouver le nombre d'utilisations maximales à partir de la toolbox
  const getBlockMaxUsage = (blockType: string): number => {
    const blockInToolbox = toolbox.contents.find((block: any) => block.type === blockType);
    return blockInToolbox?.maxUsage ?? Infinity; // Par défaut, pas de limite
  };


  // Fonction pour mettre à jour la toolbox de manière groupée après toutes les modifications
  const updateToolboxGrouped = (modifications: { blockType: string, remove: boolean }[]) => {
    if (!workspace) return;

    const newToolbox = JSON.parse(JSON.stringify(updatedToolbox)); // Copier la toolbox actuelle
    let toolboxChanged = false; // Flag pour vérifier si la toolbox a changé

    modifications.forEach(({ blockType, remove }) => {
      const blockIndex = newToolbox.contents.findIndex((item: any) => item.type === blockType);

      if (remove && blockIndex !== -1) {
        newToolbox.contents.splice(blockIndex, 1); // Supprimer le bloc
        toolboxChanged = true;
      } else if (!remove && blockIndex === -1) {
        const originalBlock = toolbox.contents.find((item: any) => item.type === blockType);
        if (originalBlock) {
          newToolbox.contents.push(originalBlock); // Réajouter le bloc
          toolboxChanged = true;
        }
      }
    });

    if (toolboxChanged) {
      setUpdatedToolbox(newToolbox);
      workspace.updateToolbox(newToolbox); // Mettre à jour la toolbox seulement si elle a changé
    }
  };

  // Fonction pour traiter chaque bloc supprimé via son oldJson
  const processDeletedBlock = (blockJson: any) => {
    if (!blockJson) return;

    const modifications: { blockType: string, remove: boolean }[] = [];
    const blockType = blockJson.type;

    if (blockType) {
      const usageNb = (blockUsageCountRef.current[blockType] || 0) - 1;
      blockUsageCountRef.current[blockType] = usageNb;
      const maxUsage = getBlockMaxUsage(blockType);

      // Ajouter l'action de réajouter le bloc si besoin
      if (usageNb < maxUsage) {
        modifications.push({ blockType, remove: false });
      }
    }

    // Si ce bloc a un bloc "suivant", traiter le suivant
    if (blockJson.next) {
      processDeletedBlock(blockJson.next.block);
    }

    // Appliquer toutes les modifications en une seule fois
    updateToolboxGrouped(modifications);
  };

  // Gestion des événements de création/suppression de bloc
  const handleBlockEvent = (event: any) => {
    const modifications: { blockType: string, remove: boolean }[] = [];

    if (event.type === Blockly.Events.BLOCK_CREATE && event.json) {
      const blockType = event.json.type;
      if (blockType) {
        blockUsageCountRef.current[blockType] = (blockUsageCountRef.current[blockType] || 0) + 1;
        const maxUsage = getBlockMaxUsage(blockType);

        // Ajouter l'action de suppression si la limite est atteinte
        if (blockUsageCountRef.current[blockType] >= maxUsage) {
          modifications.push({ blockType, remove: true });
        }
      }
    } else if (event.type === Blockly.Events.BLOCK_DELETE && event.oldJson) {
      processDeletedBlock(event.oldJson);  // Traiter chaque bloc supprimé
    }

    // Appliquer toutes les modifications en une seule fois
    updateToolboxGrouped(modifications);
  };

  // Fonction pour vérifier si la position est un mur 
  const isWall = (row: number, col: number) => {
    for (const obstacle of obstacles) {
      const element = obstacle.elements.find(el => el.x === row && el.y === col);
      if (element) {
        return true;
      }
    }
    return false;
  }

  const resetGame = () => {
    objectPositionRef.current = { ...initialObjectPosition, img: initialObjectPosition.object.onHold };
    setObjectPosition({ ...initialObjectPosition, img: initialObjectPosition.object.onHold });
    remainingTargetsRef.current = [...initialTargets.current];
    visitedTargetsRef.current = [];
  };
  // Fonction d'abandon qui remet tout à zéro et stoppe l'exécution 
  const abortExecution = () => {
    executionStoppedRef.current = true;
    //change image
    objectPositionRef.current = { ...objectPositionRef.current, img: initialObjectPosition.object.onLose }
    setObjectPosition(objectPositionRef.current);

    setTimeout(() => {
      resetGame();
    }, 1000); // 300ms de délai
  };

  const handleTarget = () => {
    if (!autoTargetReaching) {
      checkIfTargetReached()
    }
  }


  // Vérifier si la cible atteinte est correcte (en fonction de l'ordre si `mustRespectOrder`)
  const checkIfTargetReached = () => {
    const currentPos = objectPositionRef.current;
    const targetReachedIndex = remainingTargetsRef.current.findIndex(
      (target) => target.row === currentPos.row && target.col === currentPos.col
    );

    if (targetReachedIndex !== -1) {
      const targetReached = remainingTargetsRef.current[targetReachedIndex];

      // Si l'ordre doit être respecté, vérifier si c'est la bonne cible à atteindre
      if (mustRespectOrder) {
        const nextExpectedTargetIndex = visitedTargetsRef.current.length; // Indice de la prochaine cible attendue
        const nextExpectedTarget = remainingTargetsRef.current.find(
          (target) => target.order === nextExpectedTargetIndex + 1 // La prochaine cible à atteindre
        );

        // Vérification de l'ordre : Si la cible atteinte n'est pas celle attendue, abort
        if (!nextExpectedTarget ||
          (nextExpectedTarget.row !== targetReached.row || nextExpectedTarget.col !== targetReached.col)) {
          message.error(t('message.wrongOrder'));
          abortExecution();
          return;
        }
      }

      // Supprimer la cible atteinte des cibles restantes
      remainingTargetsRef.current = remainingTargetsRef.current.filter(
        (_, index) => index !== targetReachedIndex
      );

      // Ajouter la cible atteinte à la liste des cibles visitées
      visitedTargetsRef.current.push(targetReached);
    }
  };

  // Movement functions
  const move = (direction: 'up' | 'down' | 'left' | 'right') => {
    if (executionStoppedRef.current) return;

    const { row, col, rotate, scale, object } = objectPositionRef.current;
    let newRow = row, newCol = col, newRotate = rotate, newScale = scale, newImage = object.onMove;

    switch (direction) {
      case 'right': newCol++; newRotate = 0; newScale = 1; break;
      case 'left': newCol--; newRotate = 0; newScale = -1; break;
      case 'up': newRow--; break;
      case 'down': newRow++; break;
    }

    if (newRow >= 0 && newRow < gridSize.side && newCol >= 0 && newCol < gridSize.side && !isWall(newRow, newCol)) {
      objectPositionRef.current = { row: newRow, col: newCol, scale: newScale, rotate: newRotate, object: object, img: newImage };
      setObjectPosition(objectPositionRef.current);
      autoTargetReaching && checkIfTargetReached();
    } else {
      message.error(t('message.collisionOrOutOfBounds'));
      abortExecution();
    }
  };

  const moveRight = () => move('right');
  const moveLeft = () => move('left');
  const moveUp = () => move('up');
  const moveDown = () => move('down');

  // Movement functions
  const jump = (direction: 'up' | 'down' | 'left' | 'right') => {
    if (executionStoppedRef.current) return;

    const { row, col, rotate, scale, object } = objectPositionRef.current;
    let newRow = row, newCol = col, newRotate = rotate, newScale = scale, newImage = object.onJump;

    switch (direction) {
      case 'right': newCol++; newRotate = 0; newScale = 1; break;
      case 'left': newCol--; newRotate = 0; newScale = -1; break;
      case 'up': newRow--; break;
      case 'down': newRow++; break;
    }


    if (newRow >= 0 && newRow < gridSize.side && newCol >= 0 && newCol < gridSize.side && !isWall(newRow, newCol)) {
      objectPositionRef.current = { row: newRow, col: newCol, scale: newScale, rotate: newRotate, object: object, img: newImage };
      setObjectPosition(objectPositionRef.current);
    } else {
      message.error(t('message.collisionOrOutOfBounds'));
      abortExecution();
    }

  };

  const jumpRight = () => jump('right');
  const jumpLeft = () => jump('left');
  const jumpUp = () => jump('up');
  const jumpDown = () => jump('down');


  const targetReachedChange = (result: boolean) => {
    if (result) {
      objectPositionRef.current = { ...objectPositionRef.current, img: objectPositionRef.current.object.onWin };
    } else {
      objectPositionRef.current = { ...objectPositionRef.current, img: objectPositionRef.current.object.onLose };
    }

    setObjectPosition(objectPositionRef.current);
    onTargetReached && onTargetReached(result);
    if (!result) {
      message.warning(t('message.bug'));
      resetGame()
    }

  }
  // Fonction pour vérifier si l'objectif est atteint
  const isDone = () => {
    if (executionStoppedRef.current) return;  // Stopper l'exécution si elle a été annulée

    const totalTargets = initialTargets.current.length;
    const visitedTargetsCount = visitedTargetsRef.current.length;



    // Si toutes les cibles doivent être atteintes
    if (mustReachAllTargets) {

      if (visitedTargetsCount === totalTargets) {

        if (mustRespectOrder) {
          const finalTarget = initialTargets.current.reduce((max, obj) => { return (obj.order && max.order ? (obj.order > max.order ? obj : max) : max) }, initialTargets.current[0])

          if (finalTarget.col !== objectPositionRef.current.col || finalTarget.row !== objectPositionRef.current.row) {
            setTimeout(() => targetReachedChange(false), 1000);
            return
          }
        }
        targetReachedChange(true); // Succès si toutes les cibles ont été visitées
      } else {
        targetReachedChange(false); // Échec si toutes les cibles n'ont pas été visitées 
      }
    } else {
      // Si au moins une cible doit être atteinte
      if (visitedTargetsCount > 0) {
        targetReachedChange(true); // Succès si au moins une cible a été visitée
      } else {
        targetReachedChange(false); // Échec si aucune cible n'a été visitée 
      }
    }
  };

  // Gestion de l'exécution du code Blockly
  const handleRun = (): void => {

    if (workspace) {
      try {
        executionStoppedRef.current = false;  // Réinitialiser le flag d'arrêt au début de l'exécution
        visitedTargetsRef.current = [];  // Réinitialiser les cibles visitées au début de l'exécution
        const code = javascriptGenerator.workspaceToCode(workspace);
        const context = {
          moveRight,
          moveLeft,
          moveUp,
          moveDown,
          jumpRight,
          jumpLeft,
          jumpUp,
          jumpDown,
          handleTarget,
          executionStoppedRef,  // Passer le flag d'arrêt au contexte
        };
        executeCodeWithDelay(code, 300, isDone, context);
      } catch (error) {
        console.error("Erreur lors de l'exécution du code Blockly:", error);
      }
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <BlocklyEditor
            toolbox={updatedToolbox}
            initialBlocksJson={initialBlocksJson}
            onWorkspaceChange={(ws) => setWorkspace(ws)}
          />
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Space>
              <Button type="primary" size="large" onClick={handleRun} disabled={!isRunButtonEnabled}>
                {t('button.run')}
              </Button>
            </Space>
          </div>
        </Col>
        <Col span={8}>
          <GameGrid
            gridSize={gridSize}
            objectPosition={objectPosition}
            targetPositions={remainingTargetsRef.current}
            mustRespectOrder={mustRespectOrder}
            bg={bg}
            obstacles={obstacles}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BlocklyMouv;
