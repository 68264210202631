import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import type { ProgressProps } from 'antd';


interface ProgressCircleProps {
  percent: number;
  size?: number;
  strokeColor?: ProgressProps['strokeColor'];
  showPercent?: boolean;
  animate?: boolean;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  percent,
  size = 80,
  strokeColor, // Utilise la couleur par défaut ou le dégradé
  showPercent = true, // Affiche le pourcentage par défaut
  animate = true,
}) => {
  const [animatedPercent, setAnimatedPercent] = useState(0);

  useEffect(() => {
    if (animate) {

      // Animation pour atteindre le pourcentage cible
      const increment = percent > animatedPercent ? 1 : -1;
      const interval = setInterval(() => {
        setAnimatedPercent((prev) => {
          const nextValue = prev + increment;
          if ((increment > 0 && nextValue >= percent) || (increment < 0 && nextValue <= percent)) {
            clearInterval(interval);
            return percent;
          }
          return nextValue;
        });
      }, 10);

      return () => clearInterval(interval);
    } else {
      setAnimatedPercent(percent)
    }
    // eslint-disable-next-line
  }, [percent]);

  return (
    <Progress
      type="circle"
      percent={Math.round(animatedPercent)}
      strokeColor={strokeColor}
      size={size}
      format={showPercent ? undefined : () => ''} // Affiche le pourcentage si showPercent est true
    />
  );
};

export default ProgressCircle;
